import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Box, Title, Paper, Text, Group, Modal, Button, Overlay, Menu, CopyButton, ActionIcon, Tooltip, Container, Divider, Grid, Select, Stack, Textarea } from '@mantine/core';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as ChartTooltip, ResponsiveContainer, Cell } from 'recharts';
import { IconLock, IconInfoCircle, IconShare, IconBrandX, IconBrandInstagram, IconBrandTiktok, IconLink, IconCheck } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import axios from '../axiosConfig';
import { formatDateTime, formatHoroscopeDate } from '../utils/dateUtils';
import collectiveMonthVideo from '../assets/video/collectiveMonth.mp4';
import collectiveWeekVideo from '../assets/video/collectiveWeekly.mp4';
import { Link } from 'react-router-dom';
import '../styles/horoscope.css';

const CollectiveHoroscopes = forwardRef(({ defaultType = 'collective_daily', onLoadingChange, user, subscriptionStatus, onRequestAccess }, ref) => {
  const [horoscopes, setHoroscopes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lastFetchDate, setLastFetchDate] = useState(new Date());
  const currentType = useRef(defaultType);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const barColors = ['#9694ff', '#82ca9d', '#ffd494'];

  // Check if this is premium content
  const isPremiumType = ['warnings'].includes(defaultType);
  const hasPremiumAccess = user && subscriptionStatus !== 'none';
  const needsSubscription = isPremiumType && !hasPremiumAccess;
  
  // Force update state for premium content detection
  useEffect(() => {
    // This ensures the premium check runs whenever the component props change
    const runPremiumCheck = () => {
      console.log('Running premium check:', { 
        defaultType, 
        isPremium: ['warnings'].includes(defaultType),
        user,
        subscriptionStatus,
        needsPremium: ['warnings'].includes(defaultType) && !(user && subscriptionStatus !== 'none')
      });
    };
    
    runPremiumCheck();
  }, [defaultType, user, subscriptionStatus]);
  
  console.log('Premium check:', { defaultType, isPremiumType, hasPremiumAccess, needsSubscription });

  // Update both local and global loading state
  const updateLoadingState = (isLoading) => {
    setLoading(isLoading);
    if (onLoadingChange) {
      onLoadingChange(isLoading);
    }
  };

  // Helper function to generate new horoscope
  const generateNewHoroscope = async (date) => {
    try {
      updateLoadingState(true);
      
      await axios.post(`/api/collective-horoscopes/generate`, {
        type: defaultType,
        date: date.toISOString()
      });
      
      // Add a small delay to ensure the horoscope is saved
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Fetch the newly generated horoscope
      const newResponse = await axios.get(`/api/collective-horoscopes?type=${defaultType}`);
      setHoroscopes(newResponse.data);
    } catch (error) {
      console.error('Error generating horoscope:', error);
      setHoroscopes([]);
    } finally {
      updateLoadingState(false);
    }
  };

  // Keep the fetchLatestHoroscope function for internal use
  const fetchLatestHoroscope = async () => {
    try {
      updateLoadingState(true);
      console.log('Fetching latest horoscope for type:', defaultType);
      
      // Use the new /latest endpoint
      const response = await axios.get(`/api/collective-horoscopes/${defaultType}/latest`);
      console.log('Fetched latest horoscope response:', response.data);
      
      if (response.data) {
        // Convert the single horoscope object to an array to maintain compatibility
        console.log('Setting horoscopes with latest data:', [response.data]);
        setHoroscopes([response.data]);
      } else {
        console.log('No horoscope data available for:', defaultType);
        setHoroscopes([]);
        
        // If no horoscope data, try to generate it
        try {
          const targetDate = new Date();
          console.log('Trying to generate missing horoscope for:', defaultType);
          const generateResponse = await axios.post(`/api/collective-horoscopes/generate`, {
            type: defaultType,
            date: targetDate.toISOString().split('T')[0] // Format as YYYY-MM-DD
          });
          
          if (generateResponse.data) {
            console.log('Successfully generated new horoscope:', generateResponse.data);
            setHoroscopes([generateResponse.data]);
          }
        } catch (genError) {
          console.error('Failed to generate missing horoscope:', genError);
        }
      }
      setLastFetchDate(new Date());
    } catch (error) {
      console.error('Error fetching latest horoscope:', error);
      // Fallback to the original endpoint if the latest endpoint fails
      try {
        const fallbackResponse = await axios.get(`/api/collective-horoscopes?type=${defaultType}`);
        if (fallbackResponse.data && fallbackResponse.data.length > 0) {
          console.log('Fallback: Setting horoscopes with data:', fallbackResponse.data);
          setHoroscopes(fallbackResponse.data);
        } else {
          setHoroscopes([]);
        }
      } catch (fallbackError) {
        console.error('Fallback also failed:', fallbackError);
        setHoroscopes([]);
      }
    } finally {
      updateLoadingState(false);
    }
  };

  // Initial fetch of horoscopes
  useEffect(() => {
    fetchLatestHoroscope();
  }, [defaultType]);

  // Function to get the display title based on type
  const getDisplayTitle = (type) => {
    switch (type) {
      case 'collective_daily':
        return 'Daily Collective Horoscope';
      case 'collective_weekly':
        return 'Weekly Collective Horoscope';
      case 'collective_monthly':
        return 'Monthly Collective Horoscope';
      case 'politics':
        return 'Political Forecast';
      case 'warnings':
        return 'Astrological Warnings';
      case 'opportunities':
        return 'Opportunity Forecast';
      default:
        return 'Collective Horoscope';
    }
  };

  // Check if we need to refresh based on horoscope type
  const needsRefresh = (type, lastFetch) => {
    if (!lastFetch) return true;
    
    const now = new Date();
    const lastFetchDate = new Date(lastFetch);
    
    // Check if the horoscope is from a previous day
    const isOldDate = lastFetchDate.getDate() !== now.getDate() ||
                     lastFetchDate.getMonth() !== now.getMonth() ||
                     lastFetchDate.getFullYear() !== now.getFullYear();
    
    // Always refresh if it's an old date or if more than 10 minutes have passed
    if (isOldDate || (now - lastFetchDate) >= (10 * 60 * 1000)) {
      return true;
    }
    
    // Additional type-specific checks
    switch (type) {
      case 'collective_weekly': {
        const getWeekNumber = (d) => {
          const firstDayOfYear = new Date(d.getFullYear(), 0, 1);
          const pastDaysOfYear = (d - firstDayOfYear) / 86400000;
          return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
        };
        return getWeekNumber(lastFetchDate) !== getWeekNumber(now) ||
               lastFetchDate.getFullYear() !== now.getFullYear();
      }
        
      case 'collective_monthly':
        return lastFetchDate.getMonth() !== now.getMonth() ||
               lastFetchDate.getFullYear() !== now.getFullYear();
        
      default:
        // For all types (including politics, warnings, opportunities)
        // Return true if the date is not today
        return isOldDate;
    }
  };

  // Update currentType when defaultType changes
  useEffect(() => {
    if (currentType.current !== defaultType) {
      currentType.current = defaultType;
      // Fetch existing horoscopes when type changes
      fetchLatestHoroscope();
    }
  }, [defaultType]);

  const formatHoroscopeText = (text) => {
    if (!text) return null;

    try {
      // Split into sections if present
      const sections = text.split(/\[.*?\]/).filter(Boolean);
      const headers = text.match(/\[.*?\]/g) || [];
      
      if (sections.length > 1) {
        let formattedSections = [];
        
        // Format each section with its header
        sections.forEach((section, index) => {
          const header = headers[index];
          const content = section.trim();
          
          if (header) {
            // Remove square brackets and format as Title
            const cleanHeader = header.replace(/[\[\]]/g, '');
            formattedSections.push(
              <Title key={`header-${index}`} order={3} mt={24} mb={16} className="horoscope-section-title">
                {cleanHeader}
              </Title>
            );
          }
          
          // Handle fundamentals sections with special formatting
          if (content.includes('Elements:') || content.includes('Modalities:') || content.includes('Energies:')) {
            const parts = content.split(/(?=Elements:|Modalities:|Energies:)/);
            parts.forEach((part, partIndex) => {
              if (part.trim()) {
                const [subHeader, ...details] = part.split(':');
                formattedSections.push(
                  <div key={`fundamental-${index}-${partIndex}`} className="horoscope-detail-item">
                    <span className="horoscope-detail-label">{subHeader}:</span>
                    <span>{details.join(':')}</span>
                  </div>
                );
              }
            });
          } else {
            // Handle regular content and aspects
            const lines = content.split('\n').filter(line => line.trim());
            lines.forEach((line, lineIndex) => {
              if (line.includes(':')) {
                const [key, ...valueParts] = line.split(':');
                const value = valueParts.join(':').trim();
                formattedSections.push(
                  <div key={`line-${index}-${lineIndex}`} className="horoscope-detail-item">
                    <span className="horoscope-detail-label">{key}:</span>
                    <span>{value}</span>
                  </div>
                );
              } else {
                formattedSections.push(
                  <Text key={`line-${index}-${lineIndex}`} component="p" mb={16} style={{ fontSize: '1.05rem', lineHeight: 1.7 }}>
                    {line}
                  </Text>
                );
              }
            });
          }
        });
        
        return formattedSections;
      }
      
      // If no sections, format as regular text
      return text.split('\n')
        .filter(p => {
          const trimmed = p.trim();
          return trimmed !== '' && !trimmed.match(/\d+%/);
        })
        .map((paragraph, index) => (
          <Text key={`para-${index}`} component="p" mb={16} style={{ fontSize: '1.05rem', lineHeight: 1.7 }}>
            {paragraph}
          </Text>
        ));
    } catch (error) {
      console.error('Error formatting horoscope text:', error);
      return <div style={{ color: 'red' }}>Error formatting horoscope text</div>;
    }
  };

  const extractPercentages = (chatbotResponse) => {
    if (!chatbotResponse) return [];
    
    // Default attributes we're looking for - removed Charisma for collective horoscopes
    const attributes = ['Intuition', 'Energy', 'Luck'];
    
    // Map to handle both English and Swedish terms
    const attributeMap = {
      'Intuition': 'Intuition',
      'Energy': 'Energi',
      'Luck': 'Tur'
    };
    
    // First try to find the numerical values section
    const numericalSection = chatbotResponse.split('Numerical Values:')[1];
    if (numericalSection) {
      return attributes.map(attribute => {
        const pattern = new RegExp(`${attribute}:\\s*(\\d+)%`);
        const match = numericalSection.match(pattern);
        return {
          attribute,
          value: match ? parseInt(match[1], 10) : 0
        };
      });
    }
    
    // Fallback to searching in the entire text
    return attributes.map(attribute => {
      // Try different patterns for both English and Swedish terms
      const patterns = [
        // Match the exact format: "- Attribute: number"
        new RegExp(`-\\s*${attribute}:\\s*(\\d+)`),
        // Also try without the dash in case it's missing
        new RegExp(`${attribute}:\\s*(\\d+)`),
        // Try Swedish translations with same patterns
        new RegExp(`-\\s*${attributeMap[attribute]}:\\s*(\\d+)`),
        new RegExp(`${attributeMap[attribute]}:\\s*(\\d+)`)
      ];
      
      for (const pattern of patterns) {
        const match = chatbotResponse.match(pattern);
      if (match) {
          return {
            attribute,
            value: parseInt(match[1], 10)
          };
        }
      }
      
      return {
        attribute,
        value: 0
      };
    });
  };

  const formatDateRange = (type, date) => {
    // Ensure we have a valid date object
    let dateObj;
    try {
      dateObj = new Date(date);
      if (isNaN(dateObj.getTime())) {
        dateObj = new Date(); // Use current date if invalid
      }
    } catch (e) {
      console.error('Date parsing error:', e);
      dateObj = new Date(); // Default to current date
    }
    
    const formattedDate = dateObj.toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    
    switch (type) {
      case 'collective_weekly': {
        // Get Monday of the week
        const weekStart = new Date(dateObj);
        weekStart.setDate(dateObj.getDate() - dateObj.getDay() + (dateObj.getDay() === 0 ? -6 : 1));
        weekStart.setHours(0, 0, 0, 0);
        
        // Get Sunday of the week
        const weekEnd = new Date(weekStart);
        weekEnd.setDate(weekStart.getDate() + 6);
        
        // Format dates as Month Day - Month Day, Year
        const startMonth = weekStart.toLocaleString('default', { month: 'short' });
        const startDay = weekStart.getDate();
        const endMonth = weekEnd.toLocaleString('default', { month: 'short' });
        const endDay = weekEnd.getDate();
        const year = weekStart.getFullYear();
        
        return {
          title: `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${year}`,
          date: null
        };
      }
      
      case 'collective_monthly': {
        const monthStart = new Date(dateObj.getFullYear(), dateObj.getMonth(), 1);
        const monthName = monthStart.toLocaleString('default', { month: 'long' });
        const year = monthStart.getFullYear();
        
        return {
          title: `${monthName} ${year}`,
          date: null
        };
      }
      
      case 'politics':
      case 'warnings':
      case 'opportunities': {
        return {
          title: `${formattedDate}`,
          date: null
        };
      }
      
      default:
        // For daily and other types
        return {
          title: `${formattedDate}`,
          date: null
        };
    }
  };

  // Reset audio when type changes
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current = null;
      setIsPlaying(false);
    }
  }, [defaultType]);

  const handleVideoClick = () => {
    // Reset audio when opening video
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current = null;
      setIsPlaying(false);
    }
    setVideoModalOpen(true);
  };

  const handleAudioClick = (e) => {
    e.stopPropagation();
    const videoSource = defaultType === 'collective_monthly' ? collectiveMonthVideo : collectiveWeekVideo;
    
    if (!audioRef.current) {
      audioRef.current = new Audio(videoSource);
      audioRef.current.addEventListener('ended', () => {
        setIsPlaying(false);
      });
    }
    
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  // Expose fetchLatestHoroscope to parent components via ref
  useImperativeHandle(ref, () => ({
    fetchHoroscopes: () => {
      console.log('Parent component triggered fetch horoscopes');
      fetchLatestHoroscope();
    },
    updateHoroscope: (newHoroscope) => {
      console.log('Parent component provided new horoscope:', newHoroscope);
      if (newHoroscope) {
        setHoroscopes([newHoroscope]);
      } else {
        // If no horoscope provided, fetch the latest
        fetchLatestHoroscope();
      }
    },
    generateHoroscope: (date) => {
      console.log('Parent component triggered generate horoscope for date:', date);
      generateNewHoroscope(date || new Date());
    },
    changeType: (type) => {
      console.log('Parent component requested type change:', type);
      if (type && type !== currentType.current) {
        currentType.current = type;
        fetchLatestHoroscope();
      }
    }
  }));

  useEffect(() => {
    // Cleanup audio on unmount
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);

  // Render a placeholder when no horoscope is available
  const renderNoHoroscopePlaceholder = () => {
    return (
      <Paper p="lg" radius="md" withBorder style={{ textAlign: 'center', marginTop: '20px' }}>
        <Text size="lg" weight={500} color="dimmed">
          No horoscope available for this period
        </Text>
        <Text size="md" color="dimmed" mt="md">
          We're preparing your insights. Please check back in a moment.
        </Text>
      </Paper>
    );
  };

  // Function to check if a video is available for this specific date
  const isVideoAvailable = (type, date) => {
    const horoscopeDate = new Date(date);
    const currentDate = new Date();
    
    if (type === 'collective_weekly') {
      // Get the week number of horoscope and current date
      const getWeekNumber = (d) => {
        const firstDayOfYear = new Date(d.getFullYear(), 0, 1);
        const pastDaysOfYear = (d - firstDayOfYear) / 86400000;
        return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
      };
      
      // Only show for the current week
      return getWeekNumber(horoscopeDate) === getWeekNumber(currentDate) && 
             horoscopeDate.getFullYear() === currentDate.getFullYear();
    }
    
    if (type === 'collective_monthly') {
      // Only show for the current month
      return horoscopeDate.getMonth() === currentDate.getMonth() && 
             horoscopeDate.getFullYear() === currentDate.getFullYear();
    }
    
    return false;
  };

  // Function to check if audio is available for this specific type
  const isAudioAvailable = (type, date) => {
    // Use the same logic as video availability - only available for current week/month
    return isVideoAvailable(type, date);
  };

  const handleShare = async (horoscope, platform) => {
    const horoscopeUrl = `https://www.fateoracle.com/${defaultType}`;
    const text = encodeURIComponent(`Check out this ${getDisplayTitle(defaultType)} on Fate Oracle`);
    
    let shareUrl;
    switch (platform) {
      case 'x':
        shareUrl = `https://twitter.com/intent/tweet?text=${text}&url=${encodeURIComponent(horoscopeUrl)}`;
        break;
      case 'instagram':
        notifications.show({
          title: 'Instagram Sharing',
          message: 'Copy the link and share it to your Instagram Story!',
          color: 'blue'
        });
        return;
      case 'tiktok':
        shareUrl = `https://www.tiktok.com/share?url=${encodeURIComponent(horoscopeUrl)}&text=${text}`;
        break;
      default:
        return;
    }
    
    if (shareUrl) {
      window.open(shareUrl, '_blank', 'width=600,height=400');
    }
  };

  return (
    <Box p="0" className="horoscope-content">
      {loading ? (
        // When loading, render nothing - the global loading overlay will be shown
        null
      ) : horoscopes.length === 0 ? (
        // When not loading but no horoscopes, show placeholder
        renderNoHoroscopePlaceholder()
      ) : (
        // When not loading and have horoscopes, render them
        <Box position="relative">
          {horoscopes.map((horoscope) => (
            <Paper
              key={horoscope._id || horoscope.id}
              p="xs"
              mb="xs"
              withBorder={false}
              style={{ position: 'relative', width: '95%' }}
              className="horoscope-paper"
            >
              <Group  justify="space-between" position="apart" align="center" mb="md">
                <Title className="horoscope-main-title" order={3}>
                  {(() => {

                    // Special case for weekly horoscopes to avoid Invalid Date
                    if (defaultType === 'collective_weekly') {
                      return 'Weekly Collective Horoscope for Current Week';
                    }
                    
                    // For all other types, use the standard approach
                    let displayDate;
                    try {
                      // Check if date is a string or already a Date object
                      if (horoscope.date) {
                        displayDate = new Date(horoscope.date);
                        // Verify that the date is valid
                        if (isNaN(displayDate.getTime())) {
                          console.error('Invalid date from API:', horoscope.date);
                          // Try with input_date as fallback
                          if (horoscope.input_date) {
                            displayDate = new Date(horoscope.input_date);
                          } else {
                            displayDate = new Date(); // Default to current date
                          }
                        }
                      } else if (horoscope.input_date) {
                        displayDate = new Date(horoscope.input_date);
                      } else {
                        displayDate = new Date();
                      }
                    } catch (err) {
                      console.error('Error parsing date:', err);
                      displayDate = new Date(); // Default to current date
                    }
                    
                    return `${getDisplayTitle(defaultType)} for ${displayDate.toLocaleDateString('en-US', {month: 'long', day: 'numeric', year: 'numeric'})}`;
                  })()}
                </Title>
                
                <Menu shadow="md" width={200}>
                  <Menu.Target>
                    <ActionIcon variant="subtle" mb="12px" size="lg">
                      <IconShare size={20} />
                    </ActionIcon>
                  </Menu.Target>

                  <Menu.Dropdown>
                    <Menu.Label>Share on</Menu.Label>
                    <Menu.Item
                      icon={<IconBrandX size={14} />}
                      onClick={() => handleShare(horoscope, 'x')}
                    >
                      Share on X
                    </Menu.Item>
                    <Menu.Item
                      icon={<IconBrandInstagram size={14} />}
                      onClick={() => handleShare(horoscope, 'instagram')}
                    >
                      Share on Instagram
                    </Menu.Item>
                    <Menu.Item
                      icon={<IconBrandTiktok size={14} />}
                      onClick={() => handleShare(horoscope, 'tiktok')}
                    >
                      Share on TikTok
                    </Menu.Item>
                    <Menu.Divider />
                    <CopyButton value={`https://www.fateoracle.com/${defaultType}`}>
                      {({ copied, copy }) => (
                        <Menu.Item
                          icon={copied ? <IconCheck size={14} /> : <IconLink size={14} />}
                          onClick={copy}
                        >
                          {copied ? 'Copied link!' : 'Copy link'}
                        </Menu.Item>
                      )}
                    </CopyButton>
                  </Menu.Dropdown>
                </Menu>
              </Group>
              
              {/* Add horoscope subtitle - first paragraph as a summary */}
              <Text className="horoscope-subtitle" mt="md" mb="xl" ml="md" mr="md">
                As {new Date().toLocaleString('default', { month: 'long' })} unfolds, 
                the celestial bodies create unique alignments that influence our collective experience. 
                This horoscope provides insights into the cosmic energies affecting us during this period.
              </Text>
              
              {needsSubscription ? (
                // PREMIUM CONTENT - Only show teaser and paywall
                <>
                  <Text mb={30}>
                    Today's astrological warnings highlight important areas requiring caution and awareness. With major planetary alignments at 0°, specific patterns emerge that could impact your daily experience.
                  </Text>
                  
                  <Paper 
                    p="xl" 
                    withBorder 
                    shadow="md" 
                    bg="white" 
                    style={{ 
                      textAlign: 'center', 
                      marginTop: 30, 
                      marginBottom: 30,
                      borderRadius: '12px',
                      border: '1px solid rgba(0, 0, 0, 0.1)'
                    }}
                  >
                    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
                      <IconLock size={50} color="#4c6ef5" />
                      <Title order={2}>Premium Content</Title>
                      <Text size="lg">
                        The detailed warnings and guidance require premium access.
                      </Text>
                      <Button 
                        leftIcon={<IconLock size={16} />}
                        variant="subtle"
                        size="lg"
                        onClick={onRequestAccess}
                        mt={10}
                      >
                        Request Access
                      </Button>
                    </Box>
                  </Paper>
                </>
              ) : (
                // REGULAR CONTENT - Show full content
                <Box pos="relative">
                  <div dangerouslySetInnerHTML={{ __html: formatHoroscopeText(horoscope.text) }} />
                
                  {/* Only show avatar box if video is available for this date */}
                  {isVideoAvailable(defaultType, horoscope.date) && (defaultType === 'collective_monthly' || defaultType === 'collective_weekly') && (
                    <Paper 
                      style={{ 
                        display: 'flex', 
                        justifyContent: 'flex-start', 
                        alignItems: 'flex-start', 
                        marginBottom: 6, 
                        padding: '0 16px 0 0',
                        boxShadow: 'none',
                        border: 'none',
                        backgroundColor: 'transparent'
                      }}
                    >
                      <Box 
                        style={{ 
                          width: '100%',
                          background: 'rgba(0,0,0,0.05)',
                          borderRadius: '12px',
                          padding: '6px 6px 6px 6px',
                          display: 'flex',
                          gap: '5px',
                          boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                          border: '1px solid rgba(0,0,0,0.15)',
                          flexShrink: 0,
                          marginTop: '6px',
                          marginLeft: '6px',
                          marginRight: '120px'
                        }}
                      >
                        <Box
                          onClick={handleVideoClick}
                          style={{ position: 'relative', width: '80px', flexShrink: 0 }}
                        >
                          <video
                            width="100%"
                            style={{
                              borderRadius: '10px',
                              boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                            }}
                          >
                            <source src={defaultType === 'collective_monthly' ? collectiveMonthVideo : collectiveWeekVideo} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                          <Box 
                            style={{ 
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              background: 'rgba(0,0,0,0.5)',
                              color: 'white',
                              padding: '3px 6px',
                              borderRadius: '4px',
                              fontSize: '11px',
                              whiteSpace: 'normal',
                              width: '90%',
                              textAlign: 'center',
                              lineHeight: '1.2'
                            }}
                          >
                            Click to play
                          </Box>
                        </Box>
                        <Box style={{ display: 'flex', flexDirection: 'column', gap: '5px', flex: 1, marginRight: '20px' }}>
                          <Title order={2} style={{ fontSize: '20px', lineHeight: 1.4, margin: '0', fontWeight: 700 }}>
                            {formatDateRange(defaultType, new Date(horoscope.date)).title}
                          </Title>
                          <Text size="sm" color="dimmed" style={{ marginTop: 2, marginBottom: 6, fontSize: '0.85rem', opacity: 0.8 }}>
                            Generated on: {new Date(horoscope.date).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric'
                            })}
                          </Text>
                          {isAudioAvailable(defaultType, horoscope.date) && (
                            <Box
                              style={{
                                cursor: 'pointer',
                                background: 'rgba(0,0,0,0.1)',
                                borderRadius: '10px',
                                padding: '10px',
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                width: '52px',
                                height: '52px',
                                flexShrink: 0
                              }}
                              onClick={handleAudioClick}
                            >
                              {isPlaying ? (
                                <svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                  <rect x="6" y="4" width="4" height="16"/>
                                  <rect x="14" y="4" width="4" height="16"/>
                                </svg>
                              ) : (
                                <svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                  <path d="M11 5L6 9H2v6h4l5 4V5z"/>
                                  <path d="M15.54 8.46a5 5 0 0 1 0 7.07"/>
                                </svg>
                              )}
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Paper>
                  )}

                  <Box mt="lg" style={{ whiteSpace: 'pre-line' }}>
                    {(!isVideoAvailable(defaultType, horoscope.date) || !(defaultType === 'collective_monthly' || defaultType === 'collective_weekly')) && (
                      <Title order={2} mb="md">
                        {formatDateRange(defaultType, new Date(horoscope.date)).title}
                      </Title>
                    )}

                    {horoscope.chatbot_response?.includes('aspects') || horoscope.chatbot_response?.includes('fundamentals') ? (
                      <Text mb="lg" italic className="horoscope-detail-note">
                        The following astrological elements are sorted by significance, with the most influential aspects and fundamentals listed first.
                      </Text>
                    ) : null}

                    <div className="horoscope-main-content">
                      {formatHoroscopeText(horoscope.chatbot_response)}
                    </div>
                  </Box>

                  {/* Modal for video playback */}
                  {isVideoAvailable(defaultType, horoscope.date) && (defaultType === 'collective_monthly' || defaultType === 'collective_weekly') && (
                    <Modal
                      opened={videoModalOpen}
                      onClose={() => setVideoModalOpen(false)}
                      size="lg"
                      centered
                      padding={0}
                      withCloseButton={false}
                    >
                      <video
                        controls
                        width="100%"
                        autoPlay
                        style={{
                          borderRadius: '4px'
                        }}
                      >
                        <source src={defaultType === 'collective_monthly' ? collectiveMonthVideo : collectiveWeekVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </Modal>
                  )}

                  <Box h={300} style={{ maxWidth: '600px', margin: '0 auto' }}>
                    <div style={{ width: '100%', height: '300px' }}>
                      <ResponsiveContainer width="100%" height="100%" minHeight={300}>
                        <BarChart
                          data={extractPercentages(horoscope.chatbot_response)}
                          margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 50
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                          <XAxis
                            dataKey="attribute"
                            angle={0}
                            textAnchor="middle"
                            height={60}
                            interval={0}
                            tick={{ fill: '#666666', fontSize: 12 }}
                          />
                          <YAxis 
                            domain={[0, 100]} 
                            tick={{ fill: '#666666', fontSize: 12 }}
                          />
                          <ChartTooltip 
                            cursor={{ fill: 'transparent' }}
                            contentStyle={{
                              backgroundColor: 'white',
                              border: '1px solid #f0f0f0',
                              borderRadius: '4px',
                              padding: '8px'
                            }}
                          />
                          <Bar 
                            dataKey="value" 
                            radius={[4, 4, 0, 0]}
                          >
                            {extractPercentages(horoscope.chatbot_response).map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={barColors[index]} />
                            ))}
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </Box>
                </Box>
              )}
            </Paper>
          ))}
        </Box>
      )}
    </Box>
  );
});

export default CollectiveHoroscopes; 