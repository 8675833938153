export const formatDateTime = (dateString) => {
  if (!dateString) {
    console.warn('No date provided');
    return 'Date not available';
  }
  
  try {
    // Handle ISO strings, timestamps, and Date objects
    const date = typeof dateString === 'string' ? new Date(dateString) : dateString;
    
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      console.warn('Invalid date:', dateString);
      return 'Invalid date';
    }
    
    return date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    });
  } catch (error) {
    console.error('Error formatting date:', error, dateString);
    return 'Error formatting date';
  }
};

export const formatHoroscopeType = (type) => {
  if (!type) return 'Unknown';
  
  // Handle collective types
  if (type.startsWith('collective_')) {
    const parts = type.replace('collective_', '').split('_');
    const capitalizedParts = parts.map(part => 
      part.charAt(0).toUpperCase() + part.slice(1)
    );
    return `Collective ${capitalizedParts.join(' ')}`;
  }

  // Handle personal types
  const typeMap = {
    'natal': 'Natal Chart',
    'transit': 'Transit Analysis',
    'personal': 'Personal Daily',
    'daily': 'Daily (Swedish)',
    'daily_eng': 'Daily (English)',
    'love': 'Love & Romance',
    'friends': 'Friends & Social',
    'personality': 'Personality',
    'career': 'Career'
  };

  return typeMap[type] || type.split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const formatHoroscopeDate = (dateString) => {
  if (!dateString) {
    console.warn('No date provided');
    return 'Date not available';
  }
  
  try {
    // Handle ISO strings, timestamps, and Date objects
    const date = typeof dateString === 'string' ? new Date(dateString) : dateString;
    
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      console.warn('Invalid date:', dateString);
      return 'Invalid date';
    }
    
    return date.toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  } catch (error) {
    console.error('Error formatting date:', error, dateString);
    return 'Error formatting date';
  }
}; 