import { useState, useEffect, useRef, useCallback } from 'react';
import axios from '../axiosConfig';
import { refreshToken } from '../utils/auth';

// Constants for analytics configuration
const ANALYTICS_CONFIG = {
  SESSION_TIMEOUT: 30 * 60 * 1000, // 30 minutes
  MAX_RETRIES: 3,
  RETRY_DELAY: 1000, // Base delay in ms
  MAX_RETRY_DELAY: 30000, // Maximum delay in ms
  QUEUE_PROCESS_INTERVAL: 5000, // Process queue every 5 seconds
};

// Device detection patterns
const DEVICE_PATTERNS = {
  tablet: [
    /(tablet|ipad|playbook|silk)|(android(?!.*mobile))/i,
    /ipad/i,
    /android 3.0|android 3.1|android 3.2|android 4.0|android 4.1|android 4.2|android 4.3/i
  ],
  mobile: [
    /Mobile|iP(hone|od)|Android.*Mobile|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/i,
    /webOS\/[\d.]+.*Mobile/i,
    /Android.*Mobile|Mobile.*Android/i
  ],
  desktop: [
    /(Macintosh|Windows NT|Linux|X11)/i,
    /CrOS/i,
    /Ubuntu|Debian|Fedora|Red Hat|SUSE|Mint/i
  ]
};

// Add after ANALYTICS_CONFIG
const TOKEN_CONFIG = {
  REFRESH_THRESHOLD: 5 * 60 * 1000, // Refresh token 5 minutes before expiry
  MAX_REFRESH_ATTEMPTS: 3
};

// Utility function for device detection
const detectDeviceType = (userAgent) => {
  if (!userAgent) return 'unknown';
  
  const ua = userAgent.toLowerCase();
  
  // Check for bots first
  if (/bot|crawler|spider|crawling/i.test(ua)) {
    return 'bot';
  }

  // Test each device type with its patterns
  for (const [deviceType, patterns] of Object.entries(DEVICE_PATTERNS)) {
    for (const pattern of patterns) {
      if (pattern.test(ua)) {
        return deviceType;
      }
    }
  }

  // Additional checks for edge cases
  if (ua.includes('mobile') || ua.includes('android')) {
    if (ua.includes('tablet') || 
        (ua.includes('android') && !ua.includes('mobile')) || 
        ua.includes('ipad')) {
      return 'tablet';
    }
    return 'mobile';
  }

  return 'desktop';
};

// Utility function for referrer categorization
const categorizeReferrer = (referrer) => {
  if (!referrer) return 'direct';
  
  try {
    const url = new URL(referrer);
    const hostname = url.hostname.toLowerCase();
    
    // Check for search engines
    if (hostname.includes('google') || 
        hostname.includes('bing') || 
        hostname.includes('yahoo') || 
        hostname.includes('duckduckgo')) {
      return 'search';
    }
    
    // Check for social media
    if (hostname.includes('facebook') || 
        hostname.includes('twitter') || 
        hostname.includes('instagram') || 
        hostname.includes('linkedin') || 
        hostname.includes('tiktok') || 
        hostname.includes('pinterest') ||
        hostname.includes('reddit')) {
      return 'social';
    }
    
    return 'referral';
  } catch (error) {
    console.error('Error parsing referrer:', error);
    return 'direct';
  }
};

// Utility function for exponential backoff
const getRetryDelay = (retryCount) => {
  return Math.min(
    ANALYTICS_CONFIG.RETRY_DELAY * Math.pow(2, retryCount),
    ANALYTICS_CONFIG.MAX_RETRY_DELAY
  );
};

/**
 * Component that tracks page views, session duration, and other analytics
 * This is a "headless" component that doesn't render anything visual
 */
function AnalyticsTracker() {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const sessionStartTime = useRef(Date.now());
  const lastActivityTime = useRef(Date.now());
  const isFirstRender = useRef(true);
  const retryQueue = useRef([]);
  const processingQueue = useRef(false);
  const sessionId = useRef(null);
  
  // Helper function to check if we have a valid auth token
  const hasValidToken = () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) return false;

      // Parse token without verification
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => 
        '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      ).join(''));

      const { exp } = JSON.parse(jsonPayload);
      const expirationTime = exp * 1000; // Convert to milliseconds
      const currentTime = Date.now();

      // Check if token needs refresh
      if (expirationTime - currentTime < TOKEN_CONFIG.REFRESH_THRESHOLD) {
        return 'needs_refresh';
      }

      return true;
    } catch (error) {
      console.error('Error checking token validity:', error);
      return false;
    }
  };

  // Helper function to generate a unique session ID
  const generateSessionId = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  };

  // Function to handle API errors
  const handleApiError = (error, context) => {
    console.error(`Analytics error (${context}):`, error);

    if (error.response) {
      const { status } = error.response;
      
      switch (status) {
        case 401:
          console.warn('Analytics: Unauthorized - token may be invalid');
          return false;
        case 403:
          console.warn('Analytics: Forbidden - user may not have permission');
          return false;
        case 429:
          console.warn('Analytics: Rate limited - will retry');
          return true;
        case 500:
          console.warn('Analytics: Server error - will retry');
          return true;
        default:
          return status >= 500 || !status;
      }
    }
    
    return true; // Retry network errors
  };

  // Enhanced queue processing with exponential backoff
  const processRetryQueue = useCallback(async () => {
    if (processingQueue.current || retryQueue.current.length === 0) return;
    
    processingQueue.current = true;
    
    try {
      // Check token validity before processing
      const tokenStatus = hasValidToken();
      if (tokenStatus === 'needs_refresh') {
        try {
          await refreshToken();
        } catch (error) {
          console.error('Failed to refresh token:', error);
          return;
        }
      } else if (!tokenStatus) {
        console.warn('No valid token available');
        return;
      }

      const event = retryQueue.current[0];
      
      if (event.retries >= ANALYTICS_CONFIG.MAX_RETRIES) {
        console.warn(`Analytics event abandoned after ${ANALYTICS_CONFIG.MAX_RETRIES} retries:`, event);
        retryQueue.current.shift();
        return;
      }

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      };

      switch (event.type) {
        case 'pageview':
          await axios.post('/api/analytics/pageview', event.data, { headers });
          break;
        case 'bounce':
          await axios.post('/api/analytics/bounce', event.data, { headers });
          break;
        case 'event':
          await axios.post('/api/analytics/event', event.data, { headers });
          break;
      }

      retryQueue.current.shift();
      console.log('Successfully processed queued event:', event.type);
      
    } catch (error) {
      const event = retryQueue.current[0];
      event.retries = (event.retries || 0) + 1;
      
      if (event.retries < ANALYTICS_CONFIG.MAX_RETRIES) {
        const delay = getRetryDelay(event.retries);
        console.log(`Retrying event in ${delay}ms (attempt ${event.retries})`);
        setTimeout(processRetryQueue, delay);
      } else {
        retryQueue.current.shift();
        console.error('Failed to process event after max retries:', event);
      }
    } finally {
      processingQueue.current = false;
    }
  }, []);

  // Enhanced session management
  const isNewSession = useCallback(() => {
    try {
      const lastSessionTime = localStorage.getItem('fo_last_activity');
      const currentSessionId = localStorage.getItem('fo_session_id');
      
      if (!lastSessionTime || !currentSessionId) {
        return true;
      }
      
      const timeSinceLastActivity = Date.now() - parseInt(lastSessionTime, 10);
      
      // Check if session has expired
      if (timeSinceLastActivity > ANALYTICS_CONFIG.SESSION_TIMEOUT) {
        return true;
      }
      
      // Update session ID ref if it exists
      sessionId.current = currentSessionId;
      return false;
      
    } catch (error) {
      console.error('Error checking session:', error);
      return true;
    }
  }, []);

  // Initialize or restore session
  const initializeSession = useCallback(() => {
    if (isNewSession()) {
      const newSessionId = generateSessionId();
      sessionId.current = newSessionId;
      localStorage.setItem('fo_session_id', newSessionId);
      localStorage.setItem('fo_page_count', '1');
      sessionStartTime.current = Date.now();
      console.log('New session initialized:', newSessionId);
    }
    
    localStorage.setItem('fo_last_activity', Date.now().toString());
  }, [isNewSession]);

  // Track page views and session duration
  useEffect(() => {
    if (isFirstRender.current || currentPath !== window.location.pathname) {
      const newPath = window.location.pathname;
      setCurrentPath(newPath);
      
      const recordPageView = async () => {
        const tokenStatus = hasValidToken();
        if (tokenStatus === 'needs_refresh') {
          try {
            await refreshToken();
          } catch (error) {
            console.error('Failed to refresh token:', error);
            return;
          }
        } else if (!tokenStatus) {
          console.log('No valid token - skipping analytics');
          return;
        }

        // Get session duration if there was a previous page
        const duration = !isFirstRender.current 
          ? Math.floor((Date.now() - lastActivityTime.current) / 1000)
          : 0;

        const pageViewData = {
          path: newPath,
          referrer: document.referrer,
          title: document.title,
          duration,
          timestamp: new Date().toISOString(),
          sessionId: sessionId.current,
          userAgent: navigator.userAgent,
          screenResolution: `${window.screen.width}x${window.screen.height}`,
          viewportSize: `${window.innerWidth}x${window.innerHeight}`,
          locale: navigator.language,
          deviceType: detectDeviceType(navigator.userAgent),
          trafficSource: categorizeReferrer(document.referrer)
        };

        try {
          await axios.post('/api/analytics/pageview', pageViewData);
          console.log('Page view recorded:', newPath);
        } catch (error) {
          const shouldRetry = handleApiError(error, 'pageview');
          if (shouldRetry) {
            retryQueue.current.push({
              type: 'pageview',
              data: pageViewData,
              retries: 0,
              timestamp: Date.now()
            });
            processRetryQueue();
          }
        }
      };

      initializeSession();
      recordPageView();
      lastActivityTime.current = Date.now();
      isFirstRender.current = false;
    }
  }, [currentPath, initializeSession, processRetryQueue]);

  // Track user activity
  useEffect(() => {
    const updateActivity = () => {
      lastActivityTime.current = Date.now();
      localStorage.setItem('fo_last_activity', lastActivityTime.current.toString());
    };

    const events = ['click', 'scroll', 'keypress', 'mousemove', 'touchstart'];
    events.forEach(event => window.addEventListener(event, updateActivity));
    
    return () => {
      events.forEach(event => window.removeEventListener(event, updateActivity));
    };
  }, []);

  // Process retry queue periodically
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (retryQueue.current.length > 0 && hasValidToken()) {
        processRetryQueue();
      }
    }, ANALYTICS_CONFIG.QUEUE_PROCESS_INTERVAL);

    return () => clearInterval(intervalId);
  }, [processRetryQueue]);

  // Handle page unload
  useEffect(() => {
    const handleUnload = () => {
      const pageCount = parseInt(localStorage.getItem('fo_page_count') || '0', 10);
      const isBounce = pageCount === 1;
      const sessionDuration = Math.floor((Date.now() - sessionStartTime.current) / 1000);

      const bounceData = {
        bounced: isBounce,
        sessionDuration,
        sessionId: sessionId.current,
        path: window.location.pathname
      };

      // Use sendBeacon for more reliable data sending on page unload
      if (navigator.sendBeacon) {
        const blob = new Blob([JSON.stringify(bounceData)], { type: 'application/json' });
        navigator.sendBeacon('/api/analytics/bounce', blob);
      } else {
        // Fallback to synchronous XHR
        try {
          const xhr = new XMLHttpRequest();
          xhr.open('POST', '/api/analytics/bounce', false);
          xhr.setRequestHeader('Content-Type', 'application/json');
          xhr.send(JSON.stringify(bounceData));
        } catch (error) {
          console.error('Failed to send bounce data:', error);
        }
      }
    };

    window.addEventListener('beforeunload', handleUnload);
    return () => window.removeEventListener('beforeunload', handleUnload);
  }, []);

  return null;
}

export default AnalyticsTracker; 