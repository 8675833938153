import React, { useState, useEffect } from 'react';
import { 
  Paper, 
  Title, 
  Text, 
  Box,
  Button,
  Group,
  TextInput,
  Select,
  Textarea,
  Divider,
  FileInput,
  Tabs,
  LoadingOverlay,
  Badge
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import axios from '../axiosConfig';
import { useLocation, useNavigate } from 'react-router-dom';

function ArticleGenerator({ articleId }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  
  // Add debug logging
  useEffect(() => {
    console.log('ArticleGenerator mounted with articleId:', articleId);
  }, []);

  // Article Generator State
  const [articleTitle, setArticleTitle] = useState('');
  const [articleIntro, setArticleIntro] = useState('');
  const [articleType, setArticleType] = useState('horoscope');
  const [articleLength, setArticleLength] = useState('medium');
  const [articleImage, setArticleImage] = useState(null);
  const [articleTags, setArticleTags] = useState('');
  const [generatedContent, setGeneratedContent] = useState('');
  const [isGeneratingArticle, setIsGeneratingArticle] = useState(false);
  const [article, setArticle] = useState(null);

  // Fetch article data if editing
  useEffect(() => {
    const fetchArticle = async () => {
      if (articleId) {
        setLoading(true);
        try {
          console.log('Fetching article data for ID:', articleId);
          const response = await axios.get(`/api/articles/admin/${articleId}`);
          const articleData = response.data;
          console.log('Received article data:', articleData);
          setArticle(articleData);
          setArticleTitle(articleData.title || '');
          setArticleIntro(articleData.summary || '');
          setArticleType(articleData.type || 'horoscope');
          setArticleLength(articleData.length || 'medium');
          setArticleTags(Array.isArray(articleData.tags) ? articleData.tags.join(', ') : articleData.tags || '');
          setGeneratedContent(articleData.content || '');
          console.log('State updated with:', {
            title: articleData.title,
            intro: articleData.summary,
            type: articleData.type,
            tags: articleData.tags,
            content: articleData.content?.substring(0, 100) + '...'
          });
        } catch (error) {
          console.error('Failed to fetch article:', error);
          notifications.show({
            title: 'Error',
            message: 'Failed to fetch article: ' + (error.response?.data?.message || error.message),
            color: 'red'
          });
        } finally {
          setLoading(false);
        }
      }
    };

    fetchArticle();
  }, [articleId]);

  const resetArticleForm = () => {
    setArticleTitle('');
    setArticleIntro('');
    setArticleType('horoscope');
    setArticleLength('medium');
    setArticleImage(null);
    setArticleTags('');
    setGeneratedContent('');
  };

  const handleGenerateArticle = async () => {
    if (!articleTitle || !articleIntro || !articleType || !articleLength) {
      notifications.show({
        title: 'Missing Fields',
        message: 'Please fill in all required fields to generate an article',
        color: 'red'
      });
      return;
    }
    
    try {
      setIsGeneratingArticle(true);
      
      const requestData = {
        title: articleTitle,
        intro: articleIntro,
        type: articleType,
        length: articleLength
      };
      
      if (articleTags) {
        requestData.tags = articleTags;
      }
      
      const response = await axios.post('/api/articles/generate', requestData);
      
      if (response.data) {
        setGeneratedContent(response.data);
        notifications.show({
          title: 'Success',
          message: 'Article generated successfully',
          color: 'green'
        });
      } else {
        throw new Error('No content returned from the server');
      }
    } catch (error) {
      console.error('Failed to generate article:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to generate article: ' + (error.response?.data?.message || error.message),
        color: 'red'
      });
    } finally {
      setIsGeneratingArticle(false);
    }
  };

  const handleRegenerateArticle = () => {
    handleGenerateArticle();
  };

  const handleSaveArticle = async () => {
    try {
      if (!articleTitle || !generatedContent) {
        notifications.show({
          title: 'Error',
          message: 'Article title and content are required',
          color: 'red'
        });
        return;
      }
      
      const formData = new FormData();
      formData.append('title', articleTitle);
      formData.append('content', generatedContent);
      formData.append('type', articleType);
      formData.append('summary', articleIntro);
      
      // Convert tags string to array and filter out empty strings
      const tagsArray = articleTags.split(',').map(tag => tag.trim()).filter(Boolean);
      formData.append('tags', JSON.stringify(tagsArray));
      
      if (articleImage) {
        formData.append('image', articleImage);
      }

      if (articleId) {
        formData.append('id', articleId);
      }
      
      const response = await axios.post('/api/articles/save', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      if (response.data && response.data.id) {
        notifications.show({
          title: 'Success',
          message: 'Article saved as draft',
          color: 'green'
        });
        navigate('/admin');
      }
    } catch (error) {
      console.error('Failed to save article:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to save article: ' + (error.response?.data?.message || error.message),
        color: 'red'
      });
    }
  };

  const handlePublishArticle = async () => {
    try {
      if (!articleTitle || !generatedContent) {
        notifications.show({
          title: 'Error',
          message: 'Article title and content are required',
          color: 'red'
        });
        return;
      }
      
      const formData = new FormData();
      formData.append('title', articleTitle);
      formData.append('content', generatedContent);
      formData.append('type', articleType);
      
      if (articleTags) {
        formData.append('tags', articleTags);
      }
      
      if (articleImage) {
        formData.append('image', articleImage);
      }

      if (articleId) {
        formData.append('id', articleId);
      }
      
      const response = await axios.post('/api/articles/publish', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      if (response.data && response.data.id) {
        notifications.show({
          title: 'Success',
          message: 'Article published successfully',
          color: 'green'
        });
        navigate('/admin');
      }
    } catch (error) {
      console.error('Failed to publish article:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to publish article: ' + (error.response?.data?.message || error.message),
        color: 'red'
      });
    }
  };

  const handleUnpublishArticle = async () => {
    try {
      const response = await axios.post(`/api/articles/unpublish/${articleId}`);
      if (response.data) {
        notifications.show({
          title: 'Success',
          message: 'Article unpublished successfully',
          color: 'green'
        });
        navigate('/admin');
      }
    } catch (error) {
      console.error('Failed to unpublish article:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to unpublish article: ' + (error.response?.data?.message || error.message),
        color: 'red'
      });
    }
  };

  const handleDeleteArticle = async () => {
    try {
      await axios.delete(`/api/articles/${articleId}`);
      notifications.show({
        title: 'Success',
        message: 'Article deleted successfully',
        color: 'green'
      });
      navigate('/admin');
    } catch (error) {
      console.error('Failed to delete article:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to delete article: ' + (error.response?.data?.message || error.message),
        color: 'red'
      });
    }
  };

  // Add a new handler for just saving changes
  const handleSaveChanges = async () => {
    try {
      if (!articleTitle || !generatedContent) {
        notifications.show({
          title: 'Error',
          message: 'Article title and content are required',
          color: 'red'
        });
        return;
      }
      
      const formData = new FormData();
      formData.append('title', articleTitle);
      formData.append('content', generatedContent);
      formData.append('type', articleType);
      formData.append('summary', articleIntro);
      
      // Convert tags string to array and filter out empty strings
      const tagsArray = articleTags.split(',').map(tag => tag.trim()).filter(Boolean);
      formData.append('tags', JSON.stringify(tagsArray));
      
      if (articleImage) {
        formData.append('image', articleImage);
      }

      if (articleId) {
        formData.append('id', articleId);
      }
      
      const response = await axios.post('/api/articles/save', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      if (response.data && response.data.id) {
        notifications.show({
          title: 'Success',
          message: 'Changes saved successfully',
          color: 'green'
        });
        // Refresh the article data
        const refreshResponse = await axios.get(`/api/articles/admin/${articleId}`);
        const articleData = refreshResponse.data;
        setArticle(articleData);
        setArticleTitle(articleData.title || '');
        setArticleIntro(articleData.summary || '');
        setArticleType(articleData.type || 'horoscope');
        setArticleTags(Array.isArray(articleData.tags) ? articleData.tags.join(', ') : articleData.tags || '');
        setGeneratedContent(articleData.content || '');
        // Reset the image state since it's now saved
        setArticleImage(null);
      }
    } catch (error) {
      console.error('Failed to save changes:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to save changes: ' + (error.response?.data?.message || error.message),
        color: 'red'
      });
    }
  };

  return (
    <Paper shadow="xs" p="md" mt="xl" style={{ position: 'relative' }}>
      <LoadingOverlay visible={loading} />
      <Title order={3} mb="md">{articleId ? 'Edit Article' : 'AI Article Generator'}</Title>
      
      <Tabs defaultValue="edit">
        <Tabs.List>
          <Tabs.Tab value="edit">Edit Article</Tabs.Tab>
          <Tabs.Tab value="preview">Preview</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="edit" pt="xs">
          <Box mb="xl">
            <TextInput
              label="Title"
              placeholder="Enter the title for your article"
              value={articleTitle}
              onChange={(e) => setArticleTitle(e.target.value)}
              mb="md"
              required
            />
            
            <Textarea
              label="Prolog"
              description="A brief, engaging introduction that appears under the title - sets up the article's premise"
              placeholder="Write a compelling introduction that hooks readers and previews what's to come"
              minRows={3}
              value={articleIntro}
              onChange={(e) => setArticleIntro(e.target.value)}
              mb="md"
              required
            />
            
            {!articleId && (
              <>
                <Select
                  label="Article Type"
                  placeholder="Select the type of article"
                  data={[
                    { value: 'horoscope', label: 'Horoscope Analysis' },
                    { value: 'planets', label: 'Planetary Influence' },
                    { value: 'astrology', label: 'Astrology Basics' },
                    { value: 'spirituality', label: 'Spirituality' }
                  ]}
                  value={articleType}
                  onChange={setArticleType}
                  mb="md"
                  required
                />
                
                <Select
                  label="Article Length"
                  placeholder="Select desired length"
                  data={[
                    { value: 'short', label: 'Short (300-500 words)' },
                    { value: 'medium', label: 'Medium (500-800 words)' },
                    { value: 'long', label: 'Long (800-1200 words)' }
                  ]}
                  value={articleLength}
                  onChange={setArticleLength}
                  mb="md"
                  required
                />
              </>
            )}
            
            <Box mb="md">
              <Text weight={500} size="sm" mb={5}>Featured Image</Text>
              {article?.image && (
                <Box mb="sm">
                  <Text size="sm" color="dimmed">Current: {article.image}</Text>
                  <img 
                    src={`/uploads/articles/${article.image}`} 
                    alt="Featured" 
                    style={{ maxWidth: '200px', marginTop: '8px' }}
                  />
                </Box>
              )}
              <FileInput
                placeholder="Choose new image"
                accept="image/*"
                value={articleImage}
                onChange={setArticleImage}
                mb="md"
              />
            </Box>

            <TextInput
              label="Tags"
              placeholder="Tag, Tag, Tag"
              value={articleTags}
              onChange={(e) => setArticleTags(e.target.value)}
              mb="md"
            />

            {articleId && (
              <Box mb="md">
                <Text weight={500} size="sm" mb={5}>Status</Text>
                <Badge 
                  color={article?.status === 'published' ? 'green' : 'yellow'}
                  size="lg"
                >
                  {article?.status === 'published' ? 'Published' : 'Draft'}
                </Badge>
              </Box>
            )}

            <Textarea
              label="Content"
              minRows={20}
              autosize
              styles={{
                input: { 
                  fontFamily: 'monospace',
                  fontSize: '14px',
                  lineHeight: '1.5',
                  padding: '16px'
                }
              }}
              value={generatedContent || article?.content || ''}
              onChange={(e) => setGeneratedContent(e.target.value)}
              mb="md"
            />
            
            {!articleId && (
              <Group position="right" mt="xl">
                <Button 
                  color="teal" 
                  onClick={handleGenerateArticle}
                  loading={isGeneratingArticle}
                >
                  Generate Article
                </Button>
              </Group>
            )}
          </Box>
        </Tabs.Panel>

        <Tabs.Panel value="preview" pt="xs">
          <Paper p="md" withBorder className="article-preview">
            <Title order={2} mb="md">{articleTitle}</Title>
            {articleIntro && (
              <Text 
                size="lg" 
                color="dimmed" 
                mb="xl" 
                sx={{ 
                  fontStyle: 'normal',
                  lineHeight: 1.4,
                  maxWidth: '800px'
                }}
              >
                {articleIntro}
              </Text>
            )}
            {articleImage ? (
              <img 
                src={URL.createObjectURL(articleImage)} 
                alt="Featured" 
                style={{ maxWidth: '100%', marginBottom: '16px' }}
              />
            ) : article?.image && (
              <img 
                src={`/uploads/articles/${article.image}`} 
                alt="Featured" 
                style={{ maxWidth: '100%', marginBottom: '16px' }}
              />
            )}
            <div className="article-content">
              {/* Main content */}
              <div
                dangerouslySetInnerHTML={{ 
                  __html: (generatedContent || article?.content || '')
                    // Clean special Unicode characters
                    .replace(/[\u200B-\u200D\uFEFF\u202A-\u202E]/g, '')
                    // Split into paragraphs while preserving empty lines
                    .split(/\n\n+/)
                    .map(paragraph => {
                      if (!paragraph.trim()) return '';
                      
                      // Clean the paragraph text
                      const cleanParagraph = paragraph.trim();
                      
                      // Handle markdown headers (both complete and partial matches)
                      if (cleanParagraph.includes('**')) {
                        return cleanParagraph
                          .split('\n')
                          .map(line => {
                            if (line.includes('**')) {
                              return `<h3>${line.replace(/\*\*/g, '')}</h3>`;
                            }
                            return `<div class="article-paragraph">${line}</div>`;
                          })
                          .join('\n');
                      }
                      // Handle existing HTML
                      else if (cleanParagraph.startsWith('<')) {
                        return cleanParagraph;
                      }
                      // Handle uppercase headers
                      else if (cleanParagraph === cleanParagraph.toUpperCase() && 
                              cleanParagraph.length < 60 && 
                              cleanParagraph.length > 3) {
                        return `<h3>${cleanParagraph}</h3>`;
                      }
                      // Regular paragraphs
                      else {
                        return `<div class="article-paragraph">${cleanParagraph}</div>`;
                      }
                    })
                    .join('\n')
                }} 
              />
            </div>
          </Paper>
        </Tabs.Panel>
      </Tabs>
      
      <Group position="right" mt="xl">
        {!articleId && (
          <Button 
            variant="subtle" 
            onClick={handleRegenerateArticle}
          >
            Regenerate
          </Button>
        )}
        {articleId && (
          <Button 
            color="blue" 
            onClick={handleSaveChanges}
          >
            Save Changes
          </Button>
        )}
        <Button 
          color="green" 
          onClick={handleSaveArticle}
        >
          Save as Draft
        </Button>
        <Button 
          color="teal" 
          onClick={handlePublishArticle}
        >
          Publish
        </Button>
        {articleId && article?.status === 'published' && (
          <Button 
            color="orange" 
            onClick={handleUnpublishArticle}
          >
            Unpublish
          </Button>
        )}
        {articleId && (
          <Button 
            color="red" 
            onClick={handleDeleteArticle}
          >
            Delete
          </Button>
        )}
      </Group>
    </Paper>
  );
}

export default ArticleGenerator; 