import React, { useState, useEffect } from 'react';
import { 
  Paper, 
  Title, 
  Text, 
  Image, 
  Group, 
  Badge, 
  Button, 
  Divider, 
  Box,
  LoadingOverlay
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import axios from '../axiosConfig';
import { IconEdit } from '@tabler/icons-react';

const ArticleDetail = ({ slug: propSlug, onBack, onEdit }) => {
  const { id, slug } = useParams();
  const navigate = useNavigate();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  
  // Use slug from props if provided, otherwise from URL params
  const slugToUse = propSlug || slug;

  // Fetch article data
  useEffect(() => {
    if (slugToUse) {
      fetchArticle();
    }
  }, [slugToUse]);

  // Add a function to fix image URLs
  const fixImageUrls = (content) => {
    if (!content) return '';
    
    const baseURL = process.env.NODE_ENV === 'production' 
      ? 'https://api.fateoracle.com'
      : 'http://localhost:10000';
    
    // Replace relative image paths with absolute URLs
    return content.replace(/<img([^>]*)src="(\/[^"]+)"([^>]*)>/g, (match, before, src, after) => {
      // Only add the baseURL if the src is relative (starts with /)
      if (src.startsWith('/') && !src.startsWith('//') && !src.startsWith('http')) {
        return `<img${before}src="${baseURL}${src}"${after}>`;
      }
      return match;
    });
  };
  
  // Function to get full URL for any image path
  const getFullImageUrl = (imageUrl) => {
    if (!imageUrl) return '';
    
    // If already a full URL, return as is
    if (imageUrl.startsWith('http://') || imageUrl.startsWith('https://')) {
      return imageUrl;
    }
    
    // Add the base URL to the image path
    const baseURL = process.env.NODE_ENV === 'production' 
      ? 'https://api.fateoracle.com'
      : 'http://localhost:10000';
      
    // Ensure image path starts with /
    const imagePath = imageUrl.startsWith('/') ? imageUrl : `/${imageUrl}`;
    
    return `${baseURL}${imagePath}`;
  };
  
  const fetchArticle = async (retryCount = 0, delay = 1000) => {
    setLoading(true);
    let error = null;
    try {
      console.log('Fetching article with slug:', slugToUse);
      
      // Add isAdmin=true parameter if this is an admin user
      const isAdmin = onEdit !== undefined; // If onEdit prop exists, we're in admin mode
      const endpoint = isAdmin ? 
        `/api/articles/${slugToUse}?isAdmin=true` : 
        `/api/articles/${slugToUse}`;
      
      console.log(`Using endpoint: ${endpoint}, isAdmin: ${isAdmin}`);
      
      const response = await axios.get(endpoint);
      console.log('Article data received:', response.data);
      
      if (!response.data) {
        throw new Error('No article data returned from the server');
      }
      
      // Fix image URLs in the content
      const fixedContent = fixImageUrls(response.data.content);
      
      // Also fix the featured image URL if present
      const fixedFeaturedImage = response.data.featured_image 
        ? getFullImageUrl(response.data.featured_image) 
        : null;
      
      setArticle({
        ...response.data,
        content: fixedContent,
        featured_image: fixedFeaturedImage
      });
    } catch (err) {
      console.error('Error fetching article:', err);
      notifications.show({
        title: 'Error',
        message: 'Failed to load article: ' + (err.response?.data?.message || err.message),
        color: 'red'
      });
      // Navigate back to articles list on error
      if (onBack) {
        onBack();
      } else {
        navigate('/articles');
      }
    } finally {
      setLoading(false);
    }
  };

  // Format date
  const formatDate = (dateString) => {
    if (!dateString) return 'Unknown date';
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleBackClick = () => {
    if (onBack) {
      onBack();
    } else {
      navigate('/articles');
    }
  };

  const handleEditClick = () => {
    if (onEdit && article) {
      onEdit(article);
    }
  };

  if (loading) {
    return (
      <div className="article-detail-container">
        <Paper p="md" shadow="xs" style={{ position: 'relative', minHeight: '300px' }}>
          <LoadingOverlay visible={true} />
        </Paper>
      </div>
    );
  }

  if (!article) {
    return (
      <div className="article-detail-container">
        <Paper p="md" shadow="xs">
          <Title order={2}>Article not found</Title>
          <Text my="md">The article you're looking for doesn't exist or has been removed.</Text>
          <Button onClick={() => navigate('/articles')}>Return to Articles</Button>
        </Paper>
      </div>
    );
  }

  return (
    <div className="article-detail-container">
      {article && (
        <Helmet>
          <title>{article.title} - Fate Oracle</title>
          <meta name="description" content={article.summary} />
          <link rel="canonical" href={`https://www.fateoracle.com/articles/${article.id}/${article.slug}`} />
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Article",
              "headline": article.title,
              "description": article.summary,
              "image": article.featured_image,
              "author": {
                "@type": "Person",
                "name": "Fate Oracle"
              },
              "publisher": {
                "@type": "Organization",
                "name": "Fate Oracle",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://www.fateoracle.com/logo.png"
                }
              },
              "datePublished": article.created_at,
              "dateModified": article.updated_at,
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": `https://www.fateoracle.com/articles/${article.id}/${article.slug}`
              }
            })}
          </script>
        </Helmet>
      )}
      <Group justify="space-between" align="center" mb="md">
      
      <Button 
        variant="subtle" 
        onClick={handleBackClick}
        mb="md"
        size="sm"
      >
        ← Back to Articles
      </Button>
      {onEdit && (
          <Button 
            mb="md"
            variant="subtle" 
            onClick={handleEditClick}
          >
            Edit Article
          </Button>
        
      )}
      </Group>
      
      <Title order={1} className="article-title">{article.title}</Title>
      
      {article.summary && (
        <Text size="lg" italic color="dimmed" mb="md">
          {article.summary}
        </Text>
      )}
      
      <div className="article-meta">
        <Text size="sm" color="dimmed">
          Published {formatDate(article.publish_date)}
        </Text>
        {article.author && (
          <Text size="sm" color="dimmed">
            by {article.author.username}
          </Text>
        )}
        {article.tags && article.tags.length > 0 && (
          <Group spacing="xs">
            {article.tags.map((tag, index) => (
              <Badge key={index} color="blue" variant="light" size="sm">{tag}</Badge>
            ))}
          </Group>
        )}
      </div>
      
      {article.featured_image && (
        <Image
          src={article.featured_image}
          alt={article.title}
          radius="md"
          className="article-image"
          style={{ maxWidth: '100%', height: 'auto', objectFit: 'contain' }}
        />
      )}
      
      <div 
        className="article-content"
        dangerouslySetInnerHTML={{ 
          __html: article.content
            // Handle headings (with asterisks)
            .replace(/\*\*(.*?)\*\*/g, '<h3>$1</h3>')
            
            // Handle image tags properly - ensure they're not wrapped in additional <div>
            .replace(/<img([^>]*)>/g, (match) => {
              // Add style to ensure images are fully displayed
              if (match.includes('style=')) {
                // If the img tag already has a style attribute, append our styles
                return match.replace(/style="([^"]*)"/, 'style="$1; max-width: 100%; height: auto; object-fit: contain; display: block; margin: 20px auto;"');
              } else {
                // If no style attribute exists, add one
                return match.replace(/<img/, '<img style="max-width: 100%; height: auto; object-fit: contain; display: block; margin: 20px auto;"');
              }
            })
            
            // Split into paragraphs on double newlines, but be careful with HTML tags
            .split(/\n\n+/)
            
            // Process each paragraph or section
            .map(paragraph => {
              // Skip processing if this is already an HTML element (like <img>)
              if (paragraph.trim().startsWith('<')) {
                return paragraph;
              }
              // If this is a paragraph that might be a heading (all caps, etc.)
              else if (paragraph.trim() === paragraph.trim().toUpperCase() && 
                      paragraph.trim().length < 60 && 
                      paragraph.trim().length > 3) {
                return `<h3>${paragraph.trim()}</h3>`;
              }
              // Otherwise treat as a normal paragraph
              else {
                return `<p>${paragraph.trim()}</p>`;
              }
            })
            .join('')
        }} 
      />
      
     
    </div>
  );
}

export default ArticleDetail; 