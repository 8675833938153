import React, { useState } from 'react';
import {
  Modal,
  Button,
  TextInput,
  Group,
  Box,
  Notification,
  LoadingOverlay,
  Text,
  Stack,
  Title,
} from '@mantine/core';
import { IconCheck, IconX, IconInfoCircle, IconMail } from '@tabler/icons-react';
import axios from '../axiosConfig';

// Environment variables
const API_URL = process.env.REACT_APP_API_URL;

/**
 * Modal component for collecting email addresses for a waitlist
 * @param {Object} props - Component props
 * @param {boolean} props.opened - Whether the modal is open
 * @param {Function} props.onClose - Function to call when the modal closes
 */
function WaitlistModal({ opened, onClose }) {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({
    message: '',
    color: '',
    icon: null,
  });

  /**
   * Handles submission of the waitlist form
   */
  const handleSubmit = async () => {
    if (!email) {
      setNotification({
        message: 'Please enter your email address',
        color: 'red',
        icon: <IconX size={18} />,
      });
      return;
    }

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setNotification({
        message: 'Please enter a valid email address',
        color: 'red',
        icon: <IconX size={18} />,
      });
      return;
    }

    try {
      setLoading(true);
      
      // Send the waitlist data to the backend
      const response = await axios.post(`/api/waitlist`, { email, name });
      
      setNotification({
        message: 'Thank you! You have been added to our waitlist.',
        color: 'teal',
        icon: <IconCheck size={18} />,
      });
      
      // Reset the form
      setEmail('');
      setName('');
      
      // Optionally close the modal after a delay
      setTimeout(() => {
        onClose();
      }, 3000);
      
    } catch (error) {
      setNotification({
        message: `Failed to join waitlist: ${error.response?.data?.message || error.message}`,
        color: 'red',
        icon: <IconX size={18} />,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal opened={opened} onClose={onClose} title="Request Access" centered size="md" zIndex={10000}>
      <Box pos="relative">
        {loading && <LoadingOverlay visible={loading} overlayBlur={2} zIndex={10001} />}
        
        {notification.message && (
          <Notification
            color={notification.color}
            icon={notification.icon}
            onClose={() => setNotification({ message: '', color: '', icon: null })}
            mb="md"
          >
            {notification.message}
          </Notification>
        )}
        
        <Stack spacing="md">
          <Title order={3} align="center">Access Limited</Title>
          
          <Text>
            We're currently in a controlled access phase while we refine our premium services.
            Join our waitlist to be notified when we have an opening for you.
          </Text>
          
          <Box p="md" sx={(theme) => ({
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
            borderRadius: theme.radius.md,
            border: `1px solid ${theme.colors.blue[1]}`,
          })}>
            <Group spacing="xs" mb="xs">
              <IconInfoCircle size={20} color="blue" />
              <Text weight={500} color="blue">Early Access</Text>
            </Group>
            <Text size="sm">
              Waitlist members will receive priority access and exclusive features as we 
              expand our platform. Be among the first to experience our premium astrological insights.
            </Text>
          </Box>
          
          <TextInput
            label="Name"
            placeholder="Your name (optional)"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          
          <TextInput
            label="Email"
            placeholder="your.email@example.com"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            icon={<IconMail size={16} />}
          />
          
          <Button
            fullWidth
            onClick={handleSubmit}
            variant="filled"
            color="blue"
            size="md"
          >
            Join Waitlist
          </Button>
          
          <Text size="xs" color="dimmed" align="center">
            By joining our waitlist, you agree to receive occasional updates about our service.
            We respect your privacy and will never share your information with third parties.
          </Text>
        </Stack>
      </Box>
    </Modal>
  );
}

export default WaitlistModal; 