import React, { useState, useEffect, useRef, useCallback } from 'react';
import { 
  Paper, 
  TextInput, 
  Button, 
  Group, 
  Title, 
  Text, 
  Select,
  Switch,
  Box,
  LoadingOverlay,
  Divider,
  FileInput,
  Modal,
  Stack,
  Image,
  SimpleGrid,
  Textarea
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useParams, useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from '../axiosConfig';

function ArticleEditor({ articleId: propArticleId, onSaved, onCancel }) {
  const { id: paramId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [article, setArticle] = useState({
    title: '',
    content: '',
    summary: '',
    tags: [],
    status: 'draft',
    featured_image: null
  });
  const [imageFile, setImageFile] = useState(null);
  const [previewMode, setPreviewMode] = useState(false);
  const quillRef = useRef(null);

  // Quill editor modules configuration
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'align': [] }],
      ['link', 'image'],
      ['clean']
    ],
    clipboard: {
      matchVisual: false
    }
  };

  // Quill editor formats
  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'color', 'background',
    'list', 'bullet',
    'align',
    'link', 'image'
  ];

  // Use articleId from props if provided, otherwise from URL params
  const id = propArticleId || paramId;

  // Function to get full URL for any image path
  const getFullImageUrl = useCallback((imageUrl) => {
    if (!imageUrl) return '';
    
    if (imageUrl.startsWith('http://') || imageUrl.startsWith('https://')) {
      return imageUrl;
    }
    
    const baseURL = process.env.NODE_ENV === 'production' 
      ? 'https://api.fateoracle.com'
      : 'http://localhost:10000';
      
    const imagePath = imageUrl.startsWith('/') ? imageUrl : `/${imageUrl}`;
    return `${baseURL}${imagePath}`;
  }, []);

  // Fetch article data if editing an existing article
  useEffect(() => {
    const fetchArticle = async () => {
      if (!id) return; // Skip if no ID (new article)
      
      setLoading(true);
      try {
        const response = await axios.get(`/api/articles/admin/${id}`);
        // Ensure all fields are properly set
        setArticle({
          id: response.data.id,
          title: response.data.title || '',
          content: response.data.content || '',
          summary: response.data.summary || '',
          tags: response.data.tags || [],
          status: response.data.status || 'draft',
          featured_image: response.data.featured_image || null,
          slug: response.data.slug || '',
          publish_date: response.data.publish_date || null,
          created_at: response.data.created_at || null,
          updated_at: response.data.updated_at || null
        });
      } catch (error) {
        console.error('Error fetching article:', error);
        notifications.show({
          title: 'Error',
          message: 'Failed to fetch article',
          color: 'red'
        });
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  const handleChange = (name, value) => {
    setArticle({
      ...article,
      [name]: value
    });
  };

  const handleTagsChange = (value) => {
    setArticle({
      ...article,
      tags: value.split(',').map(tag => tag.trim()).filter(Boolean)
    });
  };

  const handleSave = async () => {
    if (!article.title || !article.content) {
      notifications.show({
        title: 'Error',
        message: 'Title and content are required',
        color: 'red'
      });
      return;
    }

    setSaving(true);
    try {
      const articleToSave = { ...article };

      if (!imageFile && article.featured_image) {
        const baseURL = process.env.NODE_ENV === 'production' 
          ? 'https://api.fateoracle.com'
          : 'http://localhost:10000';
          
        let imagePath = article.featured_image;
        if (imagePath.startsWith(baseURL)) {
          imagePath = imagePath.replace(baseURL, '');
        }
        
        if (!imagePath.startsWith('/')) {
          imagePath = '/' + imagePath;
        }
        
        articleToSave.featured_image = imagePath;
      } else if (!imageFile) {
        articleToSave.featured_image = null;
      }
      
      // Use PUT for editing, POST for creating
      const response = id
        ? await axios.put(`/api/articles/${id}`, articleToSave)
        : await axios.post('/api/articles', articleToSave);
      
      const savedArticle = response.data;

      // Handle image upload if needed
      if (imageFile && savedArticle.id) {
        const formData = new FormData();
        formData.append('image', imageFile);
        
        try {
          await axios.post(
            `/api/articles/upload-image/${savedArticle.id}`, 
            formData, 
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
          );
        } catch (imageError) {
          notifications.show({
            title: 'Warning',
            message: 'Article saved but featured image failed to upload',
            color: 'yellow'
          });
        }
      }

      notifications.show({
        title: 'Success',
        message: `Article ${id ? 'updated' : 'created'} successfully`,
        color: 'green'
      });

      if (onSaved) {
        onSaved(savedArticle);
      } else {
        navigate('/article-management');
      }
    } catch (error) {
      console.error('Error saving article:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to save article',
        color: 'red'
      });
    } finally {
      setSaving(false);
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      navigate('/article-management');
    }
  };

  const handleImageUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append('image', file);

      const response = await axios.post(
        '/api/articles/upload-content-image',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      return getFullImageUrl(response.data.imageUrl);
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  };

  const renderPreview = () => {
    if (!article.content) {
      return <Text color="dimmed">Preview will appear here...</Text>;
    }
    
    return (
      <Paper p="md" withBorder mb="md">
        <Title order={1} mb="xs">{article.title || 'Untitled Article'}</Title>
        
        {article.summary && (
          <Text size="lg" italic mb="md" color="dimmed">
            {article.summary}
          </Text>
        )}
          
        {article.featured_image && (
          <Box mb="xl">
            <Image
              src={article.featured_image}
              alt={article.title}
              style={{ maxWidth: '100%', height: 'auto' }}
              mb="md"
            />
          </Box>
        )}
        
        <Divider my="md" />
        
        <Box mt="md" className="article-content">
          <div 
            className="article-body"
            dangerouslySetInnerHTML={{ 
              __html: article.content
            }} 
          />
        </Box>
        
        {article.tags && article.tags.length > 0 && (
          <Box mt="xl">
            <Text size="sm" weight={500}>Tags:</Text>
            <Text size="sm" color="dimmed">
              {article.tags.join(', ')}
            </Text>
          </Box>
        )}
      </Paper>
    );
  };

  const renderEditorForm = () => {
    return (
      <Box style={{ width: '100%' }}>
        <TextInput
          label="Title"
          placeholder="Enter article title"
          required
          value={article.title}
          onChange={(e) => handleChange('title', e.target.value)}
          mb="md"
          style={{ width: '100%' }}
        />
        
        <TextInput
          label="Summary"
          placeholder="Enter a brief summary"
          value={article.summary}
          onChange={(e) => handleChange('summary', e.target.value)}
          mb="md"
          style={{ width: '100%' }}
        />
        
        <Box mb="md" style={{ width: '100%' }}>
          <Text weight={500} size="sm" mb={5}>Featured Image</Text>
          {article.featured_image ? (
            <Box mb={10}>
              <Group position="apart">
                <Text size="sm">Current: {article.featured_image.split('/').pop()}</Text>
                <Button 
                  size="xs" 
                  variant="subtle" 
                  color="red" 
                  onClick={() => {
                    setArticle({...article, featured_image: null});
                    setImageFile(null);
                  }}
                >
                  Remove
                </Button>
              </Group>
              <Image 
                src={article.featured_image} 
                height={100} 
                fit="contain" 
                withPlaceholder 
                mt={5}
              />
            </Box>
          ) : null}
          
          <FileInput
            placeholder="Upload a new image"
            accept="image/*"
            onChange={setImageFile}
            style={{ width: '100%' }}
            clearable
          />
          
          {imageFile && (
            <Text size="xs" mt={5} color="blue">
              New image selected: {imageFile.name}
            </Text>
          )}
        </Box>
        
        <Text size="sm" weight={500} mb={5}>Content</Text>
        <Box mb="md" style={{ width: '100%' }}>
          <Textarea
            placeholder="Write your article content here..."
            required
            minRows={20}
            autosize
            styles={{
              input: { 
                fontFamily: 'monospace',
                fontSize: '14px',
                lineHeight: '1.5',
              }
            }}
            value={article.content}
            onChange={(e) => handleChange('content', e.target.value)}
          />
        </Box>
        
        <TextInput
          label="Tags"
          placeholder="Enter tags separated by commas"
          value={article.tags ? article.tags.join(', ') : ''}
          onChange={(e) => handleTagsChange(e.target.value)}
          mt="md"
          mb="md"
          style={{ width: '100%' }}
        />
        
        <Select
          label="Status"
          data={[
            { value: 'draft', label: 'Draft' },
            { value: 'published', label: 'Published' }
          ]}
          value={article.status}
          onChange={(value) => handleChange('status', value)}
          mb="xl"
          style={{ width: '100%' }}
        />
      </Box>
    );
  };

  return (
    <Paper p={{ base: 'xs', sm: 'md' }} shadow="xs" style={{ position: 'relative' }}>
      <LoadingOverlay visible={loading || saving} />
      
      <div className="content-container">
        <Group position="apart" mb="md" style={{ flexWrap: 'wrap' }}>
          <Title order={2}>{id ? 'Edit Article' : 'Create New Article'}</Title>
          <Group>
            <Switch
              label="Preview"
              checked={previewMode}
              onChange={(e) => setPreviewMode(e.currentTarget.checked)}
            />
          </Group>
        </Group>
        
        {previewMode ? renderPreview() : renderEditorForm()}
        
        <Group position="right" mt="xl" style={{ flexWrap: 'wrap' }}>
          <Button 
            variant="subtle" 
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSave}
            loading={saving}
          >
            {id ? 'Update' : 'Create'} Article
          </Button>
        </Group>
      </div>
    </Paper>
  );
}

export default ArticleEditor; 