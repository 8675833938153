import React, { useState, useEffect } from 'react';
import { 
  Paper, 
  Title, 
  Text, 
  Group, 
  Card, 
  Image, 
  Badge, 
  SimpleGrid,
  Pagination,
  Box,
  Button,
  Center,
  Loader,
  ThemeIcon,
  ActionIcon,
  Menu,
  CopyButton,
  Tooltip
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
import axios from '../axiosConfig';
import { 
  IconArticle, 
  IconShare, 
  IconBrandX,
  IconBrandInstagram,
  IconBrandTiktok,
  IconLink,
  IconBookmark,
  IconHeart,
  IconCheck
} from '@tabler/icons-react';

// Component to render a fallback when images fail to load
const ImageWithFallback = ({ src, alt, height }) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  
  // If no source provided, show default placeholder
  if (!src) {
    return <ArticleImagePlaceholder title={alt} />;
  }
  
  return error ? (
    <ArticleImagePlaceholder title={alt} />
  ) : (
    <div style={{ 
      height: height,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      overflow: 'hidden',
      position: 'relative'
    }}>
      {/* Using direct img element for better compatibility */}
      <img
        src={src}
        alt={alt}
        style={{
          maxHeight: height - 20,
          maxWidth: '100%',
          objectFit: 'contain',
          objectPosition: 'center',
          opacity: loading ? 0.5 : 1,
          transition: 'opacity 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease'
        }}
        onLoad={() => setLoading(false)}
        onError={(e) => {
          console.error(`Failed to load image: ${src}`);
          setError(true);
          setLoading(false);
        }}
      />
      {loading && (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 5
        }}>
          <Text align="center" size="sm" color="dimmed">Loading image...</Text>
        </div>
      )}
      {process.env.NODE_ENV === 'development' && (
        <div style={{ 
          position: 'absolute', 
          bottom: 0, 
          left: 0, 
          right: 0, 
          background: 'rgba(0,0,0,0.5)', 
          color: 'white',
          padding: '2px 5px',
          fontSize: '10px',
          zIndex: 10
        }}>
          {`Image URL: ${src.substring(0, 30)}...`}
        </div>
      )}
    </div>
  );
}

// Custom placeholder component for articles without images
const ArticleImagePlaceholder = ({ title, height = 240 }) => (
  <div style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: height,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    padding: '20px'
  }}>
    <ThemeIcon size={80} radius="xl" color="blue" variant="light" style={{ marginBottom: '15px' }}>
      <IconArticle size={40} />
    </ThemeIcon>
    <Text align="center" size="md" color="dimmed" style={{ maxWidth: '90%', fontWeight: 500 }}>
      {title || 'No image available'}
    </Text>
  </div>
);

function ArticleList({ onViewArticle, onCreateNew, onLoadingChange }) {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentUser, setCurrentUser] = useState(null);
  const itemsPerPage = 6;
  const navigate = useNavigate();
  const [interactions, setInteractions] = useState({
    likes: new Set(),
    saves: new Set(),
    shares: new Set()
  });

  useEffect(() => {
    updateLoadingState(true);
    fetchArticles();
    fetchCurrentUser();
    return () => {
      if (onLoadingChange) {
        onLoadingChange(false);
      }
    };
  }, []);

  useEffect(() => {
    if (currentUser?.id) {
      fetchUserInteractions();
    }
  }, [currentUser?.id]);

  const fetchCurrentUser = async () => {
    try {
      // Try the newer JWT endpoint first
      let response;
      try {
        response = await axios.get('/api/auth/me-jwt');
        console.log('User data retrieved from me-jwt endpoint');
      } catch (error) {
        // In development mode, silently handle auth errors
        if (process.env.NODE_ENV === 'development' && error.response?.status === 401) {
          console.log('Development mode: User not authenticated');
          return;
        }
        console.log('JWT endpoint failed, trying users/me endpoint');
        // Fallback to users/me endpoint
        response = await axios.get('/api/users/me');
        console.log('User data retrieved from users/me endpoint');
      }

      setCurrentUser(response.data);
    } catch (error) {
      // In development mode, silently handle auth errors
      if (process.env.NODE_ENV === 'development' && error.response?.status === 401) {
        console.log('Development mode: User not authenticated');
        return;
      }
      console.error('Error fetching user:', error);
      setCurrentUser(null);
    }
  };

  const fetchUserInteractions = async () => {
    try {
      // Skip fetching interactions if not authenticated in development mode
      if (process.env.NODE_ENV === 'development' && !currentUser?.id) {
        console.log('Development mode: Skipping interactions fetch for unauthenticated user');
        return;
      }
      
      const response = await axios.get('/api/articles/interactions');
      setInteractions({
        likes: new Set(response.data.likes),
        saves: new Set(response.data.saves),
        shares: new Set(response.data.shares)
      });
    } catch (error) {
      // In development mode, silently handle auth errors
      if (process.env.NODE_ENV === 'development' && (error.response?.status === 401 || error.response?.status === 404)) {
        console.log('Development mode: Cannot fetch interactions - user not authenticated or endpoint not available');
        return;
      }
      console.error('Error fetching user interactions:', error);
    }
  };

  const fetchArticles = async (retryCount = 0, delay = 1000) => {
    updateLoadingState(true);
    let error = null;
    try {
      console.log('Fetching articles from API');
      
      // Determine if we should include all articles or just published ones
      const isAdmin = currentUser?.id === "17";
      const includeAll = isAdmin && !!onCreateNew; // onCreateNew prop indicates we're in management mode
      const endpoint = includeAll ? 
        '/api/articles?includeAll=true&isAdmin=true' : 
        '/api/articles';
      
      console.log(`Using API endpoint: ${endpoint}, isAdmin: ${isAdmin}, includeAll: ${includeAll}`);
      
      const response = await axios.get(endpoint);
      console.log('Articles response status:', response.status);
      console.log('Articles count:', response.data?.length || 0);
      
      // Debug information about the response
      console.log('Articles API response headers:', response.headers);
      console.log('Articles API response config:', {
        url: response.config.url,
        method: response.config.method,
        baseURL: response.config.baseURL
      });
      
      if (response.data && response.data.length > 0) {
        console.log('First article:', {
          id: response.data[0].id,
          title: response.data[0].title,
          status: response.data[0].status,
          slug: response.data[0].slug,
          hasImage: !!response.data[0].featured_image,
          imageUrl: response.data[0].featured_image
        });
        
        // Log all image URLs for debugging
        response.data.forEach((article, index) => {
          console.log(`Article ${index} image URL:`, article.featured_image);
        });
        
        setArticles(response.data);
        setTotalPages(Math.ceil((response.data.length) / itemsPerPage) || 1);
      } else {
        console.warn('No articles found in API response. This might be because:');
        console.warn('1. No articles exist in the database');
        console.warn('2. Only draft articles exist and we are filtering for published');
        console.warn('3. Network or API issue');
        
        // If no articles and we haven't retried, try again once after a delay
        if (retryCount === 0) {
          console.log('No articles found, will retry once after delay');
          setTimeout(() => {
            fetchArticles(retryCount + 1, delay);
          }, 2000);
          return;
        }
        
        setArticles([]);
        setTotalPages(1);
      }
    } catch (err) {
      error = err;
      console.error('Error fetching articles:', error);
      
      // Handle rate limiting (429) with exponential backoff
      if (error.response?.status === 429 && retryCount < 3) {
        const retryDelay = delay * 2; // Exponential backoff
        console.log(`Rate limited, retrying in ${retryDelay/1000} seconds...`);
        
        // Wait and retry with increased delay
        setTimeout(() => {
          fetchArticles(retryCount + 1, retryDelay);
        }, retryDelay);
        return;
      }
      
      // For other errors or after max retries, show notification only for non-429 errors
      if (error.response?.status !== 429 || retryCount >= 3) {
        if (error.response?.status === 429) {
          console.warn('Rate limit exceeded, giving up after retries');
        }
        // Don't show error notification for empty articles, just set empty array
        setArticles([]);
        setTotalPages(1);
      }
    } finally {
      if (!(error?.response?.status === 429 && retryCount < 3) || error === null) {
        updateLoadingState(false);
      }
    }
  };

  // Helper function to clean slug and generate article URL
  const getArticleUrl = (article, includeHost = false) => {
    const cleanSlug = article.slug.replace(/-\d+$/, '');
    return includeHost ? 
      `https://www.fateoracle.com/articles/${article.id}/${cleanSlug}` : 
      `/articles/${article.id}/${cleanSlug}`;
  };

  const handleArticleClick = (article, event) => {
    // Prevent any parent handlers from firing
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    
    console.log('Article clicked:', article);
    if (onViewArticle) {
      onViewArticle(article);
    } else {
      navigate(getArticleUrl(article));
    }
  };

  // Get paginated articles
  const getPaginatedArticles = () => {
    const startIndex = (activePage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return articles.slice(startIndex, endIndex);
  };

  // Format date
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  
  // Fix image URLs to use absolute paths with domain
  const getFullImageUrl = (imageUrl) => {
    if (!imageUrl) {
      console.log('No image URL provided, using fallback');
      return '';
    }
    
    try {
      // If already a full URL, return as is
      if (imageUrl.startsWith('http://') || imageUrl.startsWith('https://')) {
        console.log(`Using existing full URL: ${imageUrl}`);
        return imageUrl;
      }
      
      // Always use production URL for images
      const baseURL = 'https://api.fateoracle.com';
        
      // Ensure image path starts with /
      const imagePath = imageUrl.startsWith('/') ? imageUrl : `/${imageUrl}`;
      
      const fullUrl = `${baseURL}${imagePath}`;
      console.log(`Converted image URL: ${imageUrl} → ${fullUrl}`);
      
      return fullUrl;
    } catch (error) {
      console.error('Error processing image URL:', error, imageUrl);
      return ''; // Return empty string on error
    }
  };

  // Handle page change without refetching from API
  const handlePageChange = (newPage) => {
    setActivePage(newPage);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Update loading state both locally and globally if handler is provided
  const updateLoadingState = (isLoading) => {
    setLoading(isLoading);
    if (onLoadingChange) {
      onLoadingChange(isLoading);
    }
  };

  // Function to handle social sharing
  const handleShare = async (article, platform) => {
    const articleUrl = getArticleUrl(article, true);
    const text = encodeURIComponent(`Check out "${article.title}" on Fate Oracle`);
    
    let shareUrl;
    switch (platform) {
      case 'x':
        // Use x.com for sharing
        shareUrl = `https://x.com/intent/post?text=${text}&url=${encodeURIComponent(articleUrl)}`;
        break;
      case 'instagram':
        notifications.show({
          title: 'Instagram Sharing',
          message: 'Copy the link and share it to your Instagram Story!',
          color: 'blue'
        });
        return;
      case 'tiktok':
        shareUrl = `https://www.tiktok.com/share?url=${encodeURIComponent(articleUrl)}&text=${text}`;
        break;
      default:
        return;
    }
    
    if (shareUrl) {
      window.open(shareUrl, '_blank', 'width=600,height=400');
      // Record share interaction
      try {
        await axios.post(`/api/articles/${article.id}/interact/share`);
        setInteractions(prev => ({
          ...prev,
          shares: new Set([...prev.shares, article.id])
        }));
      } catch (error) {
        console.error('Error recording share:', error);
      }
    }
  };

  // Function to handle article saving
  const handleSaveArticle = async (articleId) => {
    if (!currentUser) {
      notifications.show({
        title: 'Login Required',
        message: 'Please log in to save articles',
        color: 'blue'
      });
      return;
    }

    try {
      const response = await axios.post(`/api/articles/${articleId}/interact/save`);
      const { active } = response.data;
      
      setInteractions(prev => ({
        ...prev,
        saves: active 
          ? new Set([...prev.saves, articleId])
          : new Set([...prev.saves].filter(id => id !== articleId))
      }));

      notifications.show({
        title: active ? 'Article Saved' : 'Article Removed',
        message: active ? 'Added to your saved articles' : 'Removed from your saved articles',
        color: 'blue'
      });
    } catch (error) {
      console.error('Error saving article:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to save article',
        color: 'red'
      });
    }
  };

  // Function to handle article liking
  const handleLikeArticle = async (articleId) => {
    if (!currentUser) {
      notifications.show({
        title: 'Login Required',
        message: 'Please log in to like articles',
        color: 'blue'
      });
      return;
    }

    try {
      const response = await axios.post(`/api/articles/${articleId}/interact/like`);
      const { active } = response.data;
      
      setInteractions(prev => ({
        ...prev,
        likes: active 
          ? new Set([...prev.likes, articleId])
          : new Set([...prev.likes].filter(id => id !== articleId))
      }));
    } catch (error) {
      console.error('Error liking article:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to like article',
        color: 'red'
      });
    }
  };

  return (
    <div className="article-list-container">
      {/* Removed the loading overlay to use only the global one */}
      
      {/* <Title order={2} mb="xl" className="section-title" style={{ padding: '16px 24px 0 24px' }}>Articles</Title> */}
      
      {articles.length === 0 && !loading ? (
        <Paper p="md" radius="md" withBorder className="empty-state-container" style={{ margin: '0 24px' }}>
          <Box py="xl" sx={{textAlign: 'center'}}>
            <ThemeIcon size={60} radius="xl" color="blue" variant="light" className="empty-state-icon" mx="auto" mb="md">
              <IconArticle size={30} />
            </ThemeIcon>
            <Text align="center" color="dimmed" size="lg" mb="md">
              No articles available yet.
            </Text>
            <Text size="md" mb="md">
              Articles you publish will appear here. Check back soon for new content!
            </Text>
            {currentUser && currentUser.id === "17" && (
              <>
                <Text mb="md">
                  As an administrator, you can create articles using the button below:
                </Text>
                <Button 
                  onClick={() => onCreateNew ? onCreateNew() : navigate('/article-management')}
                  variant="filled"
                  color="blue"
                  size="md"
                  radius="md"
                >
                  Create New Article
                </Button>
              </>
            )}
          </Box>
        </Paper>
      ) : (
        <>
          {loading && articles.length === 0 ? (
            // <Center style={{ height: '300px' }}>
            //   <Loader size="lg" color="blue" />
            // </Center>
            <></>
          ) : (
            <SimpleGrid
              cols={{ base: 1, sm: 2, lg: 3 }}
              spacing={{ base: 'md', sm: 'md', lg: 'xl' }}
              verticalSpacing={{ base: 'md', sm: 'md', lg: 'xl' }}
              style={{ margin: '0 20px' }}
            >
              {getPaginatedArticles().map((article) => (
                <Card
                  key={article.id}
                  shadow="sm"
                  padding="sm"
                  radius="md"
                  withBorder
                  className="article-card"
                  style={{ 
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'column',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    position: 'relative',
                    cursor: 'pointer'
                  }}
                  onClick={(event) => handleArticleClick(article, event)}
                >
                  <Card.Section style={{
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    overflow: 'hidden',
                    height: '240px',
                    padding: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.03)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative'
                  }}>
                    {article.featured_image ? (
                      <ImageWithFallback 
                        src={getFullImageUrl(article.featured_image)} 
                        alt={article.title} 
                        height={240} 
                      />
                    ) : (
                      <ArticleImagePlaceholder title={article.title} height={240} />
                    )}
                  </Card.Section>

                  <Group justify="space-between" mt="md" mb="xs">
                    <Text fw={500} className="article-title" lineClamp={2} title={article.title} sx={(theme) => ({
                      color: theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.dark[7],
                      fontSize: '1.1rem'
                    })}>
                      {article.title}
                    </Text>
                  </Group>

                  <Text size="sm" c="dimmed" className="article-summary" lineClamp={3} 
                       title={article.summary || 'No summary available'}
                       style={{ flexGrow: 1 }}>
                    {article.summary || 'No summary available'}
                  </Text>

                  <Group mt="md" position="apart" style={{ marginTop: 'auto' }}>
                    <Group spacing={8}>
                      <Text size="xs" c="dimmed" className="article-date">
                        {formatDate(article.published_at || article.created_at)}
                      </Text>
                      
                      {article.tags && article.tags.length > 0 && (
                        <Badge variant="subtle">
                          {article.tags[0]}
                        </Badge>
                      )}
                    </Group>

                    <Group spacing={4}>
                      <Tooltip label={currentUser ? 'Like' : 'Login to like'}>
                        <ActionIcon 
                          variant="subtle" 
                          color={interactions.likes.has(article.id) ? 'red' : 'gray'}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleLikeArticle(article.id);
                          }}
                        >
                          <IconHeart 
                            size={18} 
                            fill={interactions.likes.has(article.id) ? 'currentColor' : 'none'} 
                          />
                          {article.like_count > 0 && (
                            <Text size="xs" ml={4}>{article.like_count}</Text>
                          )}
                        </ActionIcon>
                      </Tooltip>

                      <Tooltip label={currentUser ? 'Save' : 'Login to save'}>
                        <ActionIcon 
                          variant="subtle" 
                          color={interactions.saves.has(article.id) ? 'blue' : 'gray'}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSaveArticle(article.id);
                          }}
                        >
                          <IconBookmark 
                            size={18} 
                            fill={interactions.saves.has(article.id) ? 'currentColor' : 'none'} 
                          />
                          {article.save_count > 0 && (
                            <Text size="xs" ml={4}>{article.save_count}</Text>
                          )}
                        </ActionIcon>
                      </Tooltip>

                      <Menu 
                        position="bottom-end"
                        trigger="click"
                        closeOnItemClick
                        trapFocus
                      >
                        <Menu.Target>
                          <ActionIcon 
                            variant="subtle"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <IconShare size={18} />
                            {article.share_count > 0 && (
                              <Text size="xs" ml={4}>{article.share_count}</Text>
                            )}
                          </ActionIcon>
                        </Menu.Target>

                        <Menu.Dropdown>
                          <Menu.Label>Share on</Menu.Label>
                          <Menu.Item 
                            icon={<IconBrandX size={14} />}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleShare(article, 'x');
                            }}
                          >
                            Share on X
                          </Menu.Item>
                          <Menu.Item 
                            icon={<IconBrandInstagram size={14} />}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleShare(article, 'instagram');
                            }}
                          >
                            Share on Instagram
                          </Menu.Item>
                          <Menu.Item 
                            icon={<IconBrandTiktok size={14} />}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleShare(article, 'tiktok');
                            }}
                          >
                            Share on TikTok
                          </Menu.Item>
                          <Menu.Divider />
                          <CopyButton value={getArticleUrl(article, true)}>
                            {({ copied, copy }) => (
                              <Menu.Item
                                icon={copied ? <IconCheck size={14} /> : <IconLink size={14} />}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  copy();
                                }}
                              >
                                {copied ? 'Copied link!' : 'Copy link'}
                              </Menu.Item>
                            )}
                          </CopyButton>
                        </Menu.Dropdown>
                      </Menu>
                    </Group>
                  </Group>
                </Card>
              ))}
            </SimpleGrid>
          )}
          
          {articles.length > itemsPerPage && (
            <Group position="center" mt="xl" style={{ padding: '0 24px 24px 24px' }}>
              <Pagination 
                total={Math.ceil(articles.length / itemsPerPage)} 
                value={activePage}
                onChange={handlePageChange}
              />
            </Group>
          )}
        </>
      )}
    </div>
  );
}

export default ArticleList; 