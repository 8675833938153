// src/components/AuthenticationModal.js

import React, { useState } from 'react';
import {
  Modal,
  Button,
  TextInput,
  PasswordInput,
  Checkbox,
  Group,
  Box,
  Notification,
  LoadingOverlay,
  Divider,
  Text,
  Anchor,
} from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react'; // Removed IconBrandAuth0 since SSO is commented out
import api from '../axiosConfig';

// Environment variables
const API_URL = process.env.REACT_APP_API_URL;
// const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN; // Commented out since SSO is commented out
// const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID; // Commented out since SSO is commented out
const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://www.fateoracle.com';

function AuthenticationModal({ opened, onClose, onLogin, onSwitchToWaitlist }) {
  const [formData, setFormData] = useState({
    userName: '',
    email: '',
    password: '',
    confirmPassword: '',
    agreeToTerms: false,
  });
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({
    message: '',
    color: '',
    icon: null,
  });

  /**
   * Handles input changes in the form fields.
   * @param {string} field - The field name in the formData state.
   * @returns {function} - An event handler function.
   */
  const handleChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });
  };

  /**
   * Handles user registration.
   * Sends a POST request to the backend API to register the user.
   * Automatically logs in the user upon successful registration.
   */
  const handleRegister = async () => {
    if (formData.password !== formData.confirmPassword) {
      setNotification({
        message: 'Passwords do not match',
        color: 'red',
        icon: <IconX size={18} />,
      });
      return;
    }

    const registrationData = {
      username: formData.userName,
      email: formData.email,
      password: formData.password,
    };

    try {
      setLoading(true);
      const response = await fetch(`${API_URL}/auth/register`, { // Corrected endpoint
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(registrationData),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || `HTTP error! status: ${response.status}`);
      }

      setNotification({
        message: 'Registration successful',
        color: 'teal',
        icon: <IconCheck size={18} />,
      });

      // Automatically log in after successful registration
      await handleLogin();
    } catch (error) {
      setNotification({
        message: `Registration failed: ${error.message}`,
        color: 'red',
        icon: <IconX size={18} />,
      });
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handles user login.
   * Sends a POST request to the backend API to authenticate the user.
   * Calls the onLogin callback upon successful authentication.
   */
  const handleLogin = async () => {
    try {
      setLoading(true);
      console.log('Attempting login...');
      
      // Use the configured axios instance instead of fetch
      const response = await api.post('/api/auth/login', {
        email: formData.email,
        password: formData.password
      });
      
      console.log('Login response:', response.data);
      
      const data = response.data;
      
      if (!data.token) {
        throw new Error('No authentication token received');
      }

      // Store the token
      localStorage.setItem('token', data.token);
      console.log('Token stored in localStorage');

      // Store the user data including role
      const userData = data.user || data;
      try {
        localStorage.setItem('user', JSON.stringify({
          id: userData.id,
          email: userData.email,
          username: userData.username,
          role: userData.role
        }));
        console.log('User data stored in localStorage');
      } catch (e) {
        console.error('Error storing user data in localStorage:', e);
      }

      // Set the Authorization header for future requests
      api.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
      console.log('Auth header set on axios instance:', api.defaults.headers.common['Authorization']);
      
      // Before calling onLogin, log the data we're about to pass
      console.log('About to call onLogin with data:', {
        token: data.token ? 'Token exists' : 'No token',
        user: data.user || data,
        id: (data.user || data).id,
        email: (data.user || data).email,
        role: (data.user || data).role
      });

      setNotification({
        message: 'Login successful',
        color: 'teal',
        icon: <IconCheck size={18} />,
      });

      // Call the onLogin callback with the user data
      onLogin(data);
      
      // After calling onLogin
      console.log('onLogin called, closing modal');
      
      // Close the modal
      onClose();
    } catch (error) {
      console.error('Login error:', error);
      
      // Extract the error message from the response if possible
      const errorMessage = error.response?.data?.message || 
                         error.response?.data?.error || 
                         error.message || 
                         'Unknown error occurred';
      
      setNotification({
        message: `Login failed: ${errorMessage}`,
        color: 'red',
        icon: <IconX size={18} />,
      });
    } finally {
      setLoading(false);
    }
  };

  /**
   * Initiates the Single Sign-On (SSO) login process via Auth0.
   * Redirects the user to the Auth0 authorization endpoint.
   */
  // const handleSSOLogin = () => {
  //   if (!AUTH0_DOMAIN || !AUTH0_CLIENT_ID) {
  //     console.error('Auth0 configuration is missing');
  //     setNotification({
  //       message: 'SSO login is not configured properly',
  //       color: 'red',
  //       icon: <IconX size={18} />
  //     });
  //     return;
  //   }

  //   // Remove any trailing slash from BASE_URL
  //   const baseUrl = BASE_URL.replace(/\/$/, '');
  //   const redirectUri = `${baseUrl}/callback`;
  //   const auth0LoginUrl = `https://${AUTH0_DOMAIN}/authorize?response_type=code&client_id=${AUTH0_CLIENT_ID}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=openid%20profile%20email`;

  //   console.log('Auth0 Login URL:', auth0LoginUrl);

  //   window.location.href = auth0LoginUrl;
  // };


  return (
    <Modal opened={opened} onClose={onClose} title="Authentication" centered size="md" zIndex={10000}>
      <Box pos="relative">
        {loading && <LoadingOverlay visible={loading} overlayBlur={0} zIndex={10001} />}
        {notification.message && (
          <Notification
            color={notification.color}
            icon={notification.icon}
            onClose={() => setNotification({ message: '', color: '', icon: null })}
            mb="md"
          >
            {notification.message}
          </Notification>
        )}

        {/* SSO Button commented out */}
        {/* <Button
          fullWidth
          leftIcon={<IconBrandAuth0 size={18} />}
          onClick={handleSSOLogin}
          mb="md"
          variant="outline"
          color="dark"
        >
          Login with SSO
        </Button> 

        <Divider my="md" label="Or continue with email" labelPosition="center" />*/}

        <TextInput
          label="Username"
          placeholder="Your username"
          required
          value={formData.userName}
          onChange={handleChange('userName')}
        />
        <TextInput
          label="Email"
          placeholder="Your email"
          required
          value={formData.email}
          onChange={handleChange('email')}
          mt="sm"
        />
        <PasswordInput
          label="Password"
          placeholder="Password"
          required
          value={formData.password}
          onChange={handleChange('password')}
          mt="sm"
        />
        {/* <PasswordInput
          label="Confirm Password"
          placeholder="Confirm password"
          required
          value={formData.confirmPassword}
          onChange={handleChange('confirmPassword')}
          mt="sm"
        /> */}
        {/* <Checkbox
          label="I agree to the terms and conditions"
          checked={formData.agreeToTerms}
          onChange={(e) => setFormData({ ...formData, agreeToTerms: e.currentTarget.checked })}
          mt="md"
        /> */}
        {/* <Button
          onClick={handleRegister}
          disabled={!formData.agreeToTerms}
          variant="filled"
          color="blue"
        >
          Register
        </Button> */}
        <Group position="apart" mt="md">
          <Button onClick={handleLogin} variant="subtle" color="blue">
            Login
          </Button>
        </Group>
        
        <Divider my="md" label="or" labelPosition="center" />
        
        <Text align="center" size="sm" color="dimmed">
          Don't have an account yet? <Anchor onClick={onSwitchToWaitlist}>Join our waitlist</Anchor> to request access.
        </Text>
      </Box>
    </Modal>
  );
}

export default AuthenticationModal;
