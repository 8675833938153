import React, { useState, useEffect } from 'react';
import { 
  Table, 
  Group, 
  Button, 
  Badge, 
  Title, 
  Paper, 
  Text,
  Modal,
  TextInput,
  Textarea,
  Select,
  LoadingOverlay,
  Tooltip,
  Stack
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
import { 
  IconEye, 
  IconHeart, 
  IconBookmark, 
  IconShare 
} from '@tabler/icons-react';
import axios from '../axiosConfig';

function ArticleManagement({ onEdit, onCreateNew }) {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [currentArticle, setCurrentArticle] = useState(null);
  const navigate = useNavigate();

  // Fetch all articles (including drafts)
  const fetchArticles = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/articles/admin/all');
      setArticles(response.data);
    } catch (error) {
      console.error('Error fetching articles:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to load articles. Please try again.',
        color: 'red'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArticles();
  }, []);

  const handleCreateArticle = () => {
    if (onCreateNew) {
      onCreateNew();
    } else {
      navigate('/new_article');
    }
  };

  const handleEditArticle = (article) => {
    if (onEdit) {
      onEdit(article);
    } else {
      navigate(`/admin/article-generator/${article.id}`);
    }
  };

  const handleDeleteClick = (article) => {
    setCurrentArticle(article);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(`/api/articles/${currentArticle.id}`);
      notifications.show({
        title: 'Success',
        message: 'Article deleted successfully',
        color: 'green'
      });
      fetchArticles();
    } catch (error) {
      console.error('Error deleting article:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to delete article',
        color: 'red'
      });
    } finally {
      setDeleteModalOpen(false);
    }
  };

  const handleSaveArticle = async () => {
    try {
      if (!currentArticle.title || !currentArticle.content) {
        notifications.show({
          title: 'Error',
          message: 'Title and content are required',
          color: 'red'
        });
        return;
      }

      // Set status to published by default
      const articleToSave = {
        ...currentArticle,
        status: currentArticle.status || 'published'
      };

      await axios.post('/api/articles', articleToSave);
      notifications.show({
        title: 'Success',
        message: 'Article created successfully',
        color: 'green'
      });
      fetchArticles();
      setModalOpen(false);
    } catch (error) {
      console.error('Error creating article:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to create article',
        color: 'red'
      });
    }
  };

  const handlePublishToggle = async (article) => {
    try {
      console.log('Toggling publish status for article:', article.id);
      const newStatus = article.status === 'published' ? 'draft' : 'published';
      console.log('New status will be:', newStatus);
      
      const dataToSend = {
        status: newStatus
      };
      
      console.log('Sending data:', dataToSend);
      const response = await axios.put(`/api/articles/${article.id}`, dataToSend);
      console.log('Update response:', response.data);
      
      notifications.show({
        title: 'Success',
        message: `Article ${newStatus === 'published' ? 'published' : 'unpublished'} successfully`,
        color: 'green'
      });
      
      fetchArticles();
    } catch (error) {
      console.error('Error updating article status:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to update article status: ' + (error.response?.data?.message || error.message),
        color: 'red'
      });
    }
  };

  return (
    <Paper p="md" shadow="xs">
      <LoadingOverlay visible={loading} />
      
      <Group position="apart" mb="md">
        <Title order={2}>Article Management</Title>
        {/* <Button onClick={handleCreateArticle} >Create New Article</Button> */}
      </Group>
      
      {articles.length === 0 && !loading ? (
        <Text color="dimmed" align="center" py="xl">No articles found. Create your first article!</Text>
      ) : (
        <Table>
          <thead>
            <tr>
              <th>Title</th>
              <th>Status</th>
              <th>Created</th>
              <th>Published</th>
              <th style={{ textAlign: 'center' }}>Engagement</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {articles.map(article => (
              <tr key={article.id}>
                <td>{article.title}</td>
                <td>
                  <Badge 
                    color={article.status === 'published' ? 'green' : 'gray'}
                  >
                    {article.status}
                  </Badge>
                </td>
                <td>{new Date(article.created_at).toLocaleDateString()}</td>
                <td>
                  {article.publish_date 
                    ? new Date(article.publish_date).toLocaleDateString() 
                    : '-'}
                </td>
                <td>
                  <Group position="center" spacing="xs">
                    <Tooltip label="Views">
                      <Group spacing={4}>
                        <IconEye size={16} />
                        <Text size="sm">{article.view_count || 0}</Text>
                      </Group>
                    </Tooltip>
                    <Tooltip label="Likes">
                      <Group spacing={4}>
                        <IconHeart size={16} color="red" />
                        <Text size="sm">{article.like_count || 0}</Text>
                      </Group>
                    </Tooltip>
                    <Tooltip label="Saves">
                      <Group spacing={4}>
                        <IconBookmark size={16} color="blue" />
                        <Text size="sm">{article.save_count || 0}</Text>
                      </Group>
                    </Tooltip>
                    <Tooltip label="Shares">
                      <Group spacing={4}>
                        <IconShare size={16} />
                        <Text size="sm">{article.share_count || 0}</Text>
                      </Group>
                    </Tooltip>
                  </Group>
                </td>
                <td>
                  <Group spacing="xs">
                    <Button 
                      variant="subtle" 
                      size="xs"
                      onClick={() => handleEditArticle(article)}
                    >
                      Edit
                    </Button>
                    <Button 
                      variant="subtle" 
                      color={article.status === 'published' ? 'orange' : 'green'} 
                      size="xs"
                      onClick={() => handlePublishToggle(article)}
                    >
                      {article.status === 'published' ? 'Unpublish' : 'Publish'}
                    </Button>
                    <Button 
                      variant="subtle" 
                      color="red" 
                      size="xs"
                      onClick={() => handleDeleteClick(article)}
                    >
                      Delete
                    </Button>
                  </Group>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {/* Create Article Modal */}
      <Modal
        opened={modalOpen}
        onClose={() => setModalOpen(false)}
        title="Create New Article"
        size="lg"
      >
        <TextInput
          label="Title"
          placeholder="Enter article title"
          required
          value={currentArticle?.title || ''}
          onChange={(e) => setCurrentArticle({...currentArticle, title: e.target.value})}
          mb="md"
        />
        <Textarea
          label="Summary"
          placeholder="Enter a brief summary"
          minRows={2}
          value={currentArticle?.summary || ''}
          onChange={(e) => setCurrentArticle({...currentArticle, summary: e.target.value})}
          mb="md"
        />
        <Textarea
          label="Content"
          placeholder="Write your article content here"
          required
          minRows={10}
          value={currentArticle?.content || ''}
          onChange={(e) => setCurrentArticle({...currentArticle, content: e.target.value})}
          mb="md"
        />
        <TextInput
          label="Tags"
          placeholder="Enter tags separated by commas"
          value={currentArticle?.tags?.join(', ') || ''}
          onChange={(e) => setCurrentArticle({
            ...currentArticle, 
            tags: e.target.value.split(',').map(tag => tag.trim()).filter(Boolean)
          })}
          mb="md"
        />
        <Select
          label="Status"
          data={[
            { value: 'draft', label: 'Draft' },
            { value: 'published', label: 'Published' }
          ]}
          value={currentArticle?.status || 'draft'}
          onChange={(value) => setCurrentArticle({...currentArticle, status: value})}
          mb="xl"
        />
        <Group position="right">
          <Button variant="outline" onClick={() => setModalOpen(false)}>Cancel</Button>
          <Button onClick={handleSaveArticle}>Create Article</Button>
        </Group>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        opened={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        title="Confirm Delete"
      >
        <Text mb="xl">
          Are you sure you want to delete the article "{currentArticle?.title}"? This action cannot be undone.
        </Text>
        <Group position="right">
          <Button variant="outline" onClick={() => setDeleteModalOpen(false)}>Cancel</Button>
          <Button color="red" onClick={handleDeleteConfirm}>Delete</Button>
        </Group>
      </Modal>
    </Paper>
  );
}

export default ArticleManagement; 