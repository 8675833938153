// src/App.js
import React, { useState, useEffect, useCallback } from 'react';
import { MantineProvider, LoadingOverlay, Loader } from '@mantine/core';
import { useColorScheme, useLocalStorage } from '@mantine/hooks';
import { Elements } from '@stripe/react-stripe-js';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Notifications } from '@mantine/notifications';
import { HelmetProvider } from 'react-helmet-async';
import { loadStripe } from '@stripe/stripe-js';
import LandingTabs from './components/LandingTabs';
import Auth0Callback from './components/Auth0Callback';
import SubscriptionSuccess from './components/SubscriptionSuccess';
import ShopM from './components/ShopM';
import ShopS from './components/ShopS';
import DailyNotes from './components/DailyNotes';
import axios from './axiosConfig';
import { CountryProvider } from './contexts/CountryContext';
import AnalyticsTracker from './components/AnalyticsTracker';
import './index.css';
import './styles/horoscope.css';
import './styles/admin-dashboard.css';
import stripePromise from './stripe'; // Import the pre-configured Stripe promise
import ZodiacSection from './components/ZodiacSection';
import ArticleGenerator from './components/ArticleGenerator';
import ArticleDetail from './components/ArticleDetail';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [subscriptionStatus, setSubscriptionStatus] = useState('none');
  const [isAsdUser, setIsAsdUser] = useState(false);
  const [redirectPath, setRedirectPath] = useState('/');
  const [isInitialized, setIsInitialized] = useState(false);
  
  // Get the user's preferred color scheme from system
  const systemColorScheme = useColorScheme();
  
  // Use localStorage to persist theme preference
  const [colorScheme, setColorScheme] = useLocalStorage({
    key: 'theme-color-scheme',
    defaultValue: systemColorScheme,
    getInitialValueInEffect: true,
  });

  const toggleColorScheme = (value) => {
    const newColorScheme = value || (colorScheme === 'dark' ? 'light' : 'dark');
    setColorScheme(newColorScheme);
    // Optional: set a data attribute on document.documentElement for CSS selectors
    document.documentElement.setAttribute('data-mantine-color-scheme', newColorScheme);
    console.log(`Theme changed to ${newColorScheme}`);
  };

  // Set initial data attribute when component mounts
  useEffect(() => {
    document.documentElement.setAttribute('data-mantine-color-scheme', colorScheme);
  }, [colorScheme]);

  const fetchUserData = useCallback(async () => {
    try {
      setLoading(true);
      
      const token = localStorage.getItem('token');
      if (!token) {
        console.log('No auth token found in localStorage');
        return;
      }
      
      // Set auth header
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      console.log('Restored auth token from localStorage');
      
      // Get user data with the token
      console.log('Fetching user data with token');
      
      // Try the new JWT endpoint first
      let response;
      try {
        response = await axios.get('/api/auth/me-jwt');
        console.log('User data retrieved from me-jwt endpoint');
      } catch (error) {
        console.log('JWT endpoint failed, trying users/me endpoint');
        // Fallback to users/me endpoint
        response = await axios.get('/api/users/me');
        console.log('User data retrieved from users/me endpoint');
      }
      
      // Update app state with user data
      setUser(response.data);
      setSubscriptionStatus(response.data?.subscription_status || 'none');
      
      // Store user data in localStorage
      try {
        localStorage.setItem('user', JSON.stringify({
          id: response.data.id,
          email: response.data.email,
          username: response.data.username,
          role: response.data.role
        }));
        console.log('User data stored in localStorage during data refresh');
      } catch (e) {
        console.error('Error storing user data in localStorage:', e);
      }
      
      // Check if this is the special 'asd' user
      if (response.data?.id === "17") {
        setIsAsdUser(true);
        console.log('Identified as ASD user');
      }
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        console.log('Auth token invalid or expired, clearing session');
        localStorage.removeItem('token');
        delete axios.defaults.headers.common['Authorization'];
      }
    } finally {
      setLoading(false);
    }
  }, []);

  // UseEffect to fetch user data, only run if we're already authenticated
  // and initialization is complete
  useEffect(() => {
    if (isInitialized && axios.defaults.headers.common['Authorization']) {
      console.log('Running scheduled user data refresh');
      fetchUserData();
    }
  }, [fetchUserData, isInitialized]);

  /**
   * Handles the login response from the AuthenticationModal.
   * Sets the user state, which triggers other components to update.
   */
  const handleLogin = (data) => {
    console.log('===== LOGIN HANDLER CALLED =====');
    console.log('Login successful, processing user data:', data);
    
    // Check for token and set in axios config
    if (data.token) {
      console.log('Setting auth token in localStorage and axios config');
      localStorage.setItem('token', data.token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
      console.log('Auth headers set:', axios.defaults.headers.common['Authorization']);
    } else {
      console.warn('No token received in login data');
      return;
    }

    // Validate the user data
    const userData = data.user || data;
    console.log('User data to be set:', userData);
    
    if (!userData || !userData.id) {
      console.error('Invalid user data received:', userData);
      return;
    }

    // Store user data in localStorage
    try {
      localStorage.setItem('user', JSON.stringify({
        id: userData.id,
        email: userData.email,
        username: userData.username,
        role: userData.role
      }));
      console.log('User data stored in localStorage during login');
    } catch (e) {
      console.error('Error storing user data in localStorage:', e);
    }

    // Set user state
    console.log('BEFORE setUser - current state:', { 
      user, 
      isAsdUser,
      hasToken: !!localStorage.getItem('token')
    });
    
    setUser(userData);
    
    // Set subscription status if available
    if (data.subscriptionStatus) {
      console.log('Setting subscription status:', data.subscriptionStatus);
      setSubscriptionStatus(data.subscriptionStatus);
    }
    
    // Check for ASD access
    if (userData.isAsd || userData.id === "17") {
      console.log('User has ASD access - setting isAsdUser to true');
      setIsAsdUser(true);
    }

    console.log('Login complete, state updated');
    
    // Force a refresh of key UI components
    setTimeout(() => {
      console.log('AFTER delayed check - state after login:', { 
        user: userData, 
        isAsdUser: userData.isAsd || userData.id === "17",
        hasToken: !!localStorage.getItem('token')
      });
      
      // Force a page reload to ensure all components pick up the new auth state
      window.location.reload();
    }, 500);
  };

  const handleLogout = () => {
    console.log('Logging out user...');
    
    // Clear user state
    setUser(null);
    setSubscriptionStatus('none');
    setIsAsdUser(false);
    
    // Clear authentication
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    delete axios.defaults.headers.common['Authorization'];
    
    console.log('User logged out. Auth header and localStorage cleared.');
  };

  /**
   * Attempts to restore a user session from localStorage on app startup.
   * Will fetch user data if a token is found.
   */
  const restoreSession = async () => {
    console.log('Attempting to restore session from localStorage');
    const token = localStorage.getItem('token');
    
    if (!token) {
      console.log('No token found in localStorage, user is not logged in');
      setIsInitialized(true);
      return;
    }
    
    console.log('Token found in localStorage, setting in axios config');
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    console.log('Auth headers set:', axios.defaults.headers.common['Authorization']);
    
    try {
      console.log('Fetching user data with stored token');
      // Try the new JWT endpoint first
      let response;
      try {
        response = await axios.get('/api/auth/me-jwt');
        console.log('User data retrieved from /api/auth/me-jwt:', response.data);
      } catch (jwtError) {
        console.log('JWT endpoint failed, trying users/me endpoint:', jwtError.message);
        // If that fails, try the users/me endpoint
        response = await axios.get('/api/users/me');
        console.log('User data retrieved from /api/users/me:', response.data);
      }
      
      if (!response || !response.data) {
        throw new Error('No user data returned from API');
      }
      
      setUser(response.data);
      setSubscriptionStatus(response.data.subscription_status || 'none');
      
      // Check for ASD access
      if (response.data.isAsd || response.data.id === "17") {
        console.log('User has ASD access');
        setIsAsdUser(true);
      }
      
      console.log('Session restored successfully');
    } catch (error) {
      console.error('Failed to restore session:', error);
      
      // Clear invalid token
      localStorage.removeItem('token');
      delete axios.defaults.headers.common['Authorization'];
      console.log('Invalid token cleared from localStorage and axios');
    } finally {
      setIsInitialized(true);
    }
  };

  // Use effect to restore session on app start
  useEffect(() => {
    console.log('App initializing, checking for existing session');
    
    // Just set initialized to true immediately to prevent loading screen
    setIsInitialized(true);
    
    // Try to restore session in the background
    restoreSession().catch(err => {
      console.error('Error restoring session:', err);
      // Make sure we're not stuck in a loading state
      setIsInitialized(true);
    });
  }, []);

  // Add this after the useState declarations
  // Debug effect to track user state changes
  useEffect(() => {
    console.log('USER STATE CHANGED:', { 
      loggedIn: !!user,
      user,
      isAsdUser,
      hasToken: !!localStorage.getItem('token'),
      authHeader: !!axios.defaults.headers.common['Authorization'],
      tokenInHeader: axios.defaults.headers.common['Authorization']
    });
  }, [user, isAsdUser]);

  // Define custom theme
  const customTheme = {
    primaryColor: 'indigo',
    fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif',
  };

  return (
    <HelmetProvider>
      <div className={`App ${colorScheme}`}>
        <MantineProvider theme={{ colorScheme, ...customTheme }} withGlobalStyles withNormalizeCSS>
          <Notifications />
          <Router>
            <CountryProvider>
              <Elements stripe={stripePromise}>
                <AnalyticsTracker />
                
                <div className="app-container">
                  {/* Don't show loading overlay, it may cause issues */}
                  <Routes>
                    <Route path="/auth/callback" element={<Auth0Callback onLogin={handleLogin} />} />
                    <Route path="/subscription-success" element={<SubscriptionSuccess />} />
                    <Route path="/shop-m" element={<ShopM user={user} />} />
                    <Route path="/shop-s" element={<ShopS user={user} />} />
                    <Route path="/zodiac" element={<ZodiacSection />} />
                    <Route path="/admin/article-generator" element={<ArticleGenerator />} />
                    <Route path="/articles/:id/:slug" element={<ArticleDetail />} />
                    
                    <Route 
                      path="*" 
                      element={
                        <LandingTabs 
                          onLogin={handleLogin}
                          onLogout={handleLogout}
                          user={user}
                          onSubscriptionStatusChange={setSubscriptionStatus}
                          subscriptionStatus={subscriptionStatus}
                          colorScheme={colorScheme}
                          toggleColorScheme={toggleColorScheme}
                          isAsdUser={isAsdUser}
                        />
                      } 
                    />
                  </Routes>
                </div>
              </Elements>
            </CountryProvider>
          </Router>
        </MantineProvider>
      </div>
    </HelmetProvider>
  );
}

export default App;
