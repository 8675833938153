import React, { useState, useEffect } from 'react';
import {
  Container,
  Title,
  Grid,
  Card,
  Text,
  Group,
  Select,
  Tabs,
  Paper,
  Image,
  Badge,
  Button,
  ActionIcon,
  Tooltip,
  Box,
  Loader
} from '@mantine/core';
import {
  IconCalendar,
  IconStar,
  IconHeart,
  IconShare,
  IconBookmark
} from '@tabler/icons-react';
import axios from '../axiosConfig';
import '../styles/zodiac.css';

function ZodiacSection() {
  const [selectedTimeframe, setSelectedTimeframe] = useState('daily');
  const [selectedSign, setSelectedSign] = useState(null);
  const [zodiacSigns, setZodiacSigns] = useState([]);
  const [horoscope, setHoroscope] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingHoroscope, setLoadingHoroscope] = useState(false);

  useEffect(() => {
    const fetchZodiacSigns = async () => {
      try {
        const response = await axios.get('/api/zodiac/signs');
        setZodiacSigns(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching zodiac signs:', error);
        setLoading(false);
      }
    };

    fetchZodiacSigns();
  }, []);

  useEffect(() => {
    const fetchHoroscope = async () => {
      if (!selectedSign) return;

      setLoadingHoroscope(true);
      try {
        const response = await axios.get(`/api/zodiac/horoscope/${selectedSign.name.toLowerCase()}`, {
          params: { timeframe: selectedTimeframe }
        });
        setHoroscope(response.data.horoscope);
      } catch (error) {
        console.error('Error fetching horoscope:', error);
      } finally {
        setLoadingHoroscope(false);
      }
    };

    fetchHoroscope();
  }, [selectedSign, selectedTimeframe]);

  const renderZodiacCard = (sign) => {
    const isSelected = selectedSign?.name === sign.name;
    
    return (
      <Card
        key={sign.name}
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
        className="zodiac-card"
        style={{
          backgroundColor: isSelected ? 'var(--mantine-color-blue-light)' : undefined
        }}
        onClick={() => setSelectedSign(sign)}
      >
        <Card.Section>
          <Box
            className={`zodiac-symbol zodiac-element-${sign.element.toLowerCase()}`}
            style={{
              height: '140px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {sign.symbol}
          </Box>
        </Card.Section>

        <Group position="apart" mt="md" mb="xs">
          <Text weight={500} size="lg">{sign.name}</Text>
          <Badge 
            color={sign.element === 'Fire' ? 'red' : 
                  sign.element === 'Earth' ? 'green' : 
                  sign.element === 'Air' ? 'blue' : 'cyan'} 
            variant="light"
            className={`zodiac-element-${sign.element.toLowerCase()}`}
          >
            {sign.element}
          </Badge>
        </Group>

        <Text size="sm" color="dimmed" mb="md">
          {sign.dates.start.month}/{sign.dates.start.day} - {sign.dates.end.month}/{sign.dates.end.day}
        </Text>

        <Group spacing={5}>
          {sign.traits.map((trait) => (
            <Badge 
              key={trait}
              variant="dot"
              size="sm"
              className="zodiac-trait-badge"
            >
              {trait}
            </Badge>
          ))}
        </Group>
      </Card>
    );
  };

  const renderHoroscope = () => {
    if (!selectedSign) {
      return (
        <Paper p="xl" radius="md" withBorder>
          <Text align="center" size="lg" color="dimmed">
            Select a zodiac sign to view its horoscope
          </Text>
        </Paper>
      );
    }

    return (
      <Paper p="xl" radius="md" withBorder>
        <Group position="apart" mb="xl" className="zodiac-header">
          <div>
            <Title order={2}>{selectedSign.name} Horoscope</Title>
            <Text color="dimmed">
              {selectedSign.dates.start.month}/{selectedSign.dates.start.day} - {selectedSign.dates.end.month}/{selectedSign.dates.end.day}
            </Text>
          </div>
          <Select
            value={selectedTimeframe}
            onChange={setSelectedTimeframe}
            data={[
              { value: 'daily', label: 'Daily' },
              { value: 'weekly', label: 'Weekly' },
              { value: 'monthly', label: 'Monthly' }
            ]}
            className="zodiac-timeframe-select"
          />
        </Group>

        {loadingHoroscope ? (
          <Box py="xl" style={{ display: 'flex', justifyContent: 'center' }}>
            <Loader size="lg" />
          </Box>
        ) : (
          <Tabs defaultValue="general">
            <Tabs.List>
              <Tabs.Tab value="general" icon={<IconStar size={14} />}>General</Tabs.Tab>
              <Tabs.Tab value="love" icon={<IconHeart size={14} />}>Love</Tabs.Tab>
              <Tabs.Tab value="career" icon={<IconCalendar size={14} />}>Career</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="general" pt="xs">
              <Text className="zodiac-horoscope-content">
                {horoscope?.general || 'Loading...'}
              </Text>
            </Tabs.Panel>

            <Tabs.Panel value="love" pt="xs">
              <Text className="zodiac-horoscope-content">
                {horoscope?.love || 'Loading...'}
              </Text>
            </Tabs.Panel>

            <Tabs.Panel value="career" pt="xs">
              <Text className="zodiac-horoscope-content">
                {horoscope?.career || 'Loading...'}
              </Text>
            </Tabs.Panel>
          </Tabs>
        )}

        <Group position="apart" mt="xl">
          <Group spacing="xs">
            <Tooltip label="Like">
              <ActionIcon variant="default" radius="md" size={36}>
                <IconHeart size={18} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Save">
              <ActionIcon variant="default" radius="md" size={36}>
                <IconBookmark size={18} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Share">
              <ActionIcon variant="default" radius="md" size={36}>
                <IconShare size={18} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Group>
          <Button radius="md" size="md">
            Get Detailed Reading
          </Button>
        </Group>
      </Paper>
    );
  };

  if (loading) {
    return (
      <Container size="xl" py="xl">
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <Loader size="xl" />
        </Box>
      </Container>
    );
  }

  return (
    <Container size="xl" py="xl">
      <Title order={1} align="center" mb="xl">
        Zodiac Signs & Daily Horoscopes
      </Title>

      <Grid gutter="md">
        <Grid.Col xs={12} md={8}>
          {renderHoroscope()}
        </Grid.Col>
        <Grid.Col xs={12} md={4}>
          <Paper p="md" withBorder>
            <Title order={3} mb="md">Choose Your Sign</Title>
            <Grid gutter="sm">
              {zodiacSigns.map((sign) => (
                <Grid.Col key={sign.name} span={12}>
                  {renderZodiacCard(sign)}
                </Grid.Col>
              ))}
            </Grid>
          </Paper>
        </Grid.Col>
      </Grid>
    </Container>
  );
}

export default ZodiacSection; 