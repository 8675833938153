import React, { useState, useEffect } from 'react';
import { 
  Paper, 
  Title, 
  Text, 
  Grid, 
  Skeleton, 
  Group, 
  Select,
  Badge,
  Card,
  Center,
  Box,
  Container,
  Tabs,
  Table,
  ScrollArea,
  ActionIcon,
  Tooltip as MantineTooltip,
  SegmentedControl,
  ThemeIcon,
  Button,
  Stack
} from '@mantine/core';
import { 
  IconUsers, 
  IconArticle, 
  IconEye, 
  IconArrowsRightLeft,
  IconChartPie,
  IconTable,
  IconActivity,
  IconHourglassHigh,
  IconDownload,
  IconCalendar,
  IconClick,
  IconLogin,
  IconMapPin
} from '@tabler/icons-react';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
  ComposedChart
} from 'recharts';
import { notifications } from '@mantine/notifications';
import axios from 'axios';

// Add IP geolocation utility function
const getIPLocation = async (ip) => {
  try {
    const response = await axios.get(`/api/ip-geolocation/${ip}`);
    const data = response.data;
    if (data.status === 'success') {
      return {
        country: data.country,
        city: data.city,
        region: data.regionName,
        lat: data.lat,
        lon: data.lon,
        isp: data.isp
      };
    }
    return null;
  } catch (error) {
    console.error('Error fetching IP location:', error);
    return null;
  }
};

// Add IP location cache
const ipLocationCache = new Map();

// Add function to get cached location
const getCachedIPLocation = async (ip) => {
  if (ipLocationCache.has(ip)) {
    return ipLocationCache.get(ip);
  }
  const location = await getIPLocation(ip);
  ipLocationCache.set(ip, location);
  return location;
};

function SiteStatistics() {
  const [timeRange, setTimeRange] = useState('30d');
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [stats, setStats] = useState({
    userMetrics: {},
    contentMetrics: {},
    engagementMetrics: {},
    trafficSources: [],
    topArticles: [],
    popularHoroscopes: [],
    growthTrends: [],
    deviceStats: [],
    dailyBreakdown: [],
    userActivity: [],
    eventTracking: [],
    contentPerformance: [],
    conversionFunnel: [],
    heatmapData: []
  });
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 3;

  // Content performance state
  const [contentViewType, setContentViewType] = useState('chart');
  const [contentFilterType, setContentFilterType] = useState('all');
  
  // Tabs state
  const [activeTab, setActiveTab] = useState('overview');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // IP locations state
  const [ipLocations, setIpLocations] = useState({});

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Update theme colors with more vibrant options for charts
  const THEME_COLORS = {
    primary: '#8884d8',
    secondary: '#82ca9d',
    accent: '#ffc658',
    success: '#4CAF50',
    warning: '#FFC107',
    error: '#FF5252',
    info: '#2196F3',
    background: 'var(--mantine-color-body)',
    text: 'var(--mantine-color-text)',
    lightText: 'var(--mantine-color-dimmed)',
    border: 'var(--mantine-color-default-border)',
    paper: 'var(--mantine-color-default)',
    chart: {
      colors: ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#ea60ff', '#7295ff'],
      grid: 'rgba(255, 255, 255, 0.1)',
      text: 'var(--mantine-color-text)',
      axis: 'var(--mantine-color-dimmed)'
    }
  };

  // Format date helper
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  // Format number helper
  const formatNumber = (num) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M';
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'K';
    }
    return num.toString();
  };

  // Format duration helper
  const formatDuration = (seconds) => {
    if (!seconds || isNaN(seconds) || seconds < 0) return '0m';
    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) return `${minutes}m`;
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  // Enhanced MetricCard component
  const MetricCard = ({ title, value, icon, change, subtitle, loading, compact, color = THEME_COLORS.primary }) => {
    const isPositiveChange = change >= 0;
    
    return (
      <Paper withBorder p={compact ? "md" : "xl"} radius="md" 
        style={{ 
          height: '100%',
          background: `color-mix(in srgb, ${color}15, var(--mantine-color-default))`,
          borderColor: `color-mix(in srgb, ${color}, var(--mantine-color-default-border))`
        }}
      >
        {loading ? (
          <>
            <Skeleton height={24} width="30%" mb="sm" />
            <Skeleton height={28} width="50%" mb="sm" />
            <Skeleton height={16} width="80%" />
          </>
        ) : (
          <>
            <Group position="apart" spacing="xs" mb={compact ? "xs" : "md"}>
              <Text size={compact ? "sm" : "md"} c={THEME_COLORS.lightText} fw={500}>
                {title}
              </Text>
              <ThemeIcon
                size={compact ? 30 : 40}
                radius="md"
                variant="light"
                style={{ backgroundColor: `${color}15`, color: color }}
              >
                {icon}
              </ThemeIcon>
            </Group>
            
            <Group position="apart" mb={compact ? "xs" : "md"} align="flex-end">
              <Text size={compact ? "xl" : "2xl"} fw={700} style={{ color: THEME_COLORS.text }}>
                {value}
              </Text>
              {typeof change !== 'undefined' && (
                <Badge 
                  color={isPositiveChange ? 'green' : 'red'} 
                  variant="light"
                  size={compact ? "sm" : "md"}
                >
                  {isPositiveChange ? '↑' : '↓'} {Math.abs(change).toFixed(1)}%
                </Badge>
              )}
            </Group>
            
            {subtitle && (
              <Text size={compact ? "xs" : "sm"} c={THEME_COLORS.lightText}>
                {subtitle}
              </Text>
            )}
          </>
        )}
      </Paper>
    );
  };

  // Check authentication and admin status
  useEffect(() => {
    const token = localStorage.getItem('token');
    
    // Check for admin status through different approaches
    // First attempt: Check if there's user data in localStorage
    const userDataString = localStorage.getItem('user');
    let userData = null;
    try {
      if (userDataString) {
        userData = JSON.parse(userDataString);
      }
    } catch (e) {
      console.error('Error parsing user data from localStorage:', e);
    }
    
    // Second attempt: Check if user with ID 17 (always admin)
    const isUserId17 = userData?.id === '17' || userData?.id === 17;
    
    // Third attempt: Check if admin dashboard is accessible
    const adminDashboardLink = document.querySelector('a[href="/admin"]');
    const hasAdminDashboardAccess = !!adminDashboardLink;
    
    // Set authentication status
    setIsAuthenticated(!!token);
    
    // Set admin status based on various checks
    const isAdmin = 
      (userData?.role === 'admin') || 
      isUserId17 || 
      hasAdminDashboardAccess;
    
    console.log('Admin status check:', { 
      hasToken: !!token,
      userDataFound: !!userData,
      isUserId17,
      hasAdminDashboardAccess,
      finalAdminStatus: isAdmin
    });
    
    setIsAdmin(isAdmin);
    
    // If authenticated but admin status can't be determined, check server
    if (token && !isAdmin) {
      // Make a test request to the statistics endpoint
      fetch('/api/statistics/data', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.ok) {
          console.log('User has access to statistics endpoint');
          setIsAdmin(true);
        }
      })
      .catch(err => {
        console.error('Error checking statistics access:', err);
      });
    }
  }, []);

  // Fetch statistics data with retry mechanism
  useEffect(() => {
    // Only fetch if user is authenticated and an admin
    if (!isAuthenticated || !isAdmin) {
      setLoading(false);
      return;
    }
    
    const fetchStats = async () => {
      setLoading(true);
      setError(null);
      
      try {
        console.log('Checking statistics API...');
        
        const isDevelopment = window.location.hostname === 'localhost';
        const apiBaseUrl = isDevelopment 
          ? 'http://localhost:10000' 
          : 'https://api.fateoracle.com';
        
        console.log(`Using API base URL: ${apiBaseUrl}`);

        // Function to handle API response
        const handleApiResponse = async (response) => {
          if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`API returned ${response.status}: ${errorText}`);
          }
          return response.json();
        };

        // Try to get statistics data
        try {
          // Use the same authenticated endpoint for both development and production
          const statsEndpoint = `${apiBaseUrl}/api/statistics/data`;
            
          console.log(`Fetching statistics from: ${statsEndpoint}`);
          
          // Include the Authorization header for the protected endpoint
          const token = localStorage.getItem('token');
          if (!token) {
            throw new Error('No authentication token found');
          }
          
          const headers = { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          };
          
          console.log('Making request with headers:', headers);
          
          const response = await fetch(statsEndpoint, { 
            method: 'GET',
            headers,
            credentials: 'include'
          });

          const data = await handleApiResponse(response);
          console.log('Statistics data received:', data);
          setStats(data);
          setRetryCount(0); // Reset retry count on success

        } catch (error) {
          console.error('Error fetching statistics:', error);
          
          if (retryCount < MAX_RETRIES) {
            // Implement exponential backoff
            const backoffDelay = Math.pow(2, retryCount) * 1000;
            console.log(`Retrying in ${backoffDelay}ms... (Attempt ${retryCount + 1}/${MAX_RETRIES})`);
            
            setTimeout(() => {
              setRetryCount(prev => prev + 1);
            }, backoffDelay);
            
            setError(`Loading statistics... Retry ${retryCount + 1}/${MAX_RETRIES}`);
          } else {
            // Display user-friendly error after max retries
            setError("Unable to load statistics. Please ensure you're logged in with admin privileges.");
            
            // Show notification
            notifications.show({
              title: 'Statistics Error',
              message: 'Failed to load statistics data. Please try again later or contact support.',
              color: 'red'
            });
          }
        }
      } catch (error) {
        console.error('Unexpected error:', error);
        setError('An unexpected error occurred. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [timeRange, retryCount, isAuthenticated, isAdmin]);

  // Add helper function to safely render arrays
  const safeArray = (array, defaultValue = []) => {
    return Array.isArray(array) ? array : defaultValue;
  };

  // Add helper function to safely access metrics
  const safeMetric = (obj, path, defaultValue = 0) => {
    try {
      const parts = path.split('.');
      let result = obj;
      
      for (const part of parts) {
        if (result === null || result === undefined || typeof result !== 'object') {
          return defaultValue;
        }
        result = result[part];
      }
      
      // If the result is undefined or null, return the default value
      if (result === undefined || result === null) {
        return defaultValue;
      }

      // If it's a string and we're not expecting a number, return as is
      if (typeof result === 'string' && typeof defaultValue === 'string') {
        return result;
      }
      
      // If it's a number or can be converted to one, return as number
      if (!isNaN(result)) {
        return typeof result === 'string' ? Number(result) : result;
      }
      
      return result;
    } catch (error) {
      console.error(`Error accessing metric at path ${path}:`, error);
      return defaultValue;
    }
  };

  // Update CustomTooltip to support dark mode
  const CustomTooltip = ({ active, payload }) => {
    if (!active || !payload || !payload.length) {
      return null;
    }
    
    return (
      <Paper p="xs" withBorder shadow="sm" bg="var(--mantine-color-body)">
        {payload.map((entry, index) => {
          // Check if this is a traffic source or device stat entry that has count
          const hasCount = entry.payload && entry.payload.count !== undefined;
          return (
            <Text key={index} size="sm" style={{ color: entry.color }}>
              {entry.name}: {entry.value.toLocaleString()}
              {hasCount ? ` (${entry.payload.count.toLocaleString()} hits)` : ''}
            </Text>
          );
        })}
      </Paper>
    );
  };

  // Update growth chart with better responsive styling
  const renderGrowthChart = () => {
    if (!stats.growthTrends || !Array.isArray(stats.growthTrends) || stats.growthTrends.length === 0) {
      return (
        <Center h={200}>
          <Text c={THEME_COLORS.lightText}>No growth data available</Text>
        </Center>
      );
    }

    return (
      <Box style={{ width: '100%', height: '300px' }}>
        <ResponsiveContainer width="100%" height="100%" minHeight={300}>
          <LineChart
            data={safeArray(stats.growthTrends)}
            margin={{ 
              top: 20, 
              right: window.innerWidth < 768 ? 20 : 30, 
              left: window.innerWidth < 768 ? 10 : 20, 
              bottom: window.innerWidth < 768 ? 60 : 40 
            }}
          >
            <CartesianGrid 
              strokeDasharray="3 3" 
              stroke={THEME_COLORS.chart.grid}
            />
            <XAxis 
              dataKey="date" 
              stroke={THEME_COLORS.chart.axis}
              tick={{ 
                fill: THEME_COLORS.chart.text, 
                fontSize: window.innerWidth < 768 ? '10px' : '11px' 
              }}
              angle={-45}
              textAnchor="end"
              height={window.innerWidth < 768 ? 60 : 50}
              interval={window.innerWidth < 768 ? 1 : 0}
              tickFormatter={(value) => {
                const date = new Date(value);
                return date.toLocaleDateString('en-US', { 
                  month: 'short', 
                  day: 'numeric' 
                });
              }}
            />
            <YAxis 
              yAxisId="left" 
              orientation="left" 
              stroke={THEME_COLORS.chart.colors[0]}
              tick={{ 
                fill: THEME_COLORS.chart.text, 
                fontSize: window.innerWidth < 768 ? '10px' : '11px' 
              }}
              width={window.innerWidth < 768 ? 25 : 30}
              tickFormatter={(value) => formatNumber(value)}
            />
            <YAxis 
              yAxisId="right" 
              orientation="right" 
              stroke={THEME_COLORS.chart.colors[1]}
              tick={{ 
                fill: THEME_COLORS.chart.text, 
                fontSize: window.innerWidth < 768 ? '10px' : '11px' 
              }}
              width={window.innerWidth < 768 ? 25 : 30}
              tickFormatter={(value) => formatNumber(value)}
            />
            <Tooltip 
              content={<CustomTooltip />}
              cursor={{ stroke: THEME_COLORS.chart.grid }}
            />
            <Legend 
              wrapperStyle={{ 
                fontSize: window.innerWidth < 768 ? '10px' : '11px',
                paddingTop: '8px'
              }}
              verticalAlign="top"
              height={36}
            />
            <Line 
              yAxisId="left" 
              type="monotone" 
              dataKey="users" 
              stroke={THEME_COLORS.chart.colors[0]} 
              activeDot={{ r: window.innerWidth < 768 ? 4 : 6 }} 
              name="Users"
              strokeWidth={2}
              dot={{ r: window.innerWidth < 768 ? 2 : 3 }}
            />
            <Line 
              yAxisId="right" 
              type="monotone" 
              dataKey="pageViews" 
              stroke={THEME_COLORS.chart.colors[1]} 
              name="Page Views"
              strokeWidth={2}
              dot={{ r: window.innerWidth < 768 ? 2 : 3 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    );
  };

  // Update pie chart with better responsive styling
  const renderPieChart = (data, dataKey, nameKey, title) => {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return (
        <Center h={200}>
          <Text c={THEME_COLORS.lightText}>No {title.toLowerCase()} data available</Text>
        </Center>
      );
    }

    // Filter out any entries with 0 value
    const validData = data.filter(item => item[dataKey] > 0);

    return (
      <Box>
        <Title order={3} mb="md" style={{ fontSize: 'calc(0.9rem + 0.3vw)' }}>{title}</Title>
        <Box style={{ width: '100%', height: '250px', minHeight: '200px' }}>
          <ResponsiveContainer width="100%" height="100%" minHeight={200}>
            <PieChart>
              <Pie
                data={validData}
                cx="50%"
                cy="50%"
                innerRadius={window.innerWidth < 768 ? 30 : 40}
                outerRadius={window.innerWidth < 768 ? 60 : 80}
                dataKey={dataKey}
                nameKey={nameKey}
                label={({ name, device, percent }) => `${name || device}: ${(percent * 100).toFixed(0)}%`}
                labelLine={{ stroke: THEME_COLORS.chart.text, strokeWidth: 1 }}
              >
                {validData.map((entry, index) => (
                  <Cell 
                    key={`cell-${index}`} 
                    fill={THEME_COLORS.chart.colors[index % THEME_COLORS.chart.colors.length]}
                  />
                ))}
              </Pie>
              <Tooltip 
                content={<CustomTooltip />}
                cursor={{ fill: 'none' }}
              />
              <Legend
                formatter={(value, entry) => (
                  <span style={{ 
                    color: THEME_COLORS.chart.text, 
                    fontSize: '11px',
                    display: 'inline-block',
                    maxWidth: '120px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}>
                    {value}
                  </span>
                )}
                layout={window.innerWidth < 768 ? "horizontal" : "vertical"}
                verticalAlign={window.innerWidth < 768 ? "bottom" : "middle"}
                align={window.innerWidth < 768 ? "center" : "right"}
                wrapperStyle={{
                  fontSize: '11px',
                  paddingLeft: window.innerWidth < 768 ? '0' : '10px',
                  maxWidth: '100%'
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    );
  };

  // Update bar chart in Popular Horoscopes with better responsive styling
  const renderPopularHoroscopes = () => {
    const horoscopeData = safeArray(stats.popularHoroscopes);
    
    if (horoscopeData.length === 0) {
      return (
        <Center h={200}>
          <Text c={THEME_COLORS.lightText}>No horoscope data available</Text>
        </Center>
      );
    }

    // Limit data points for mobile view
    const displayData = window.innerWidth < 768 
      ? horoscopeData.slice(0, 6) 
      : horoscopeData;

    return (
      <Box style={{ width: '100%', height: '300px' }}>
        <ResponsiveContainer width="100%" height="100%" minHeight={300}>
          <BarChart
            data={displayData}
            margin={{ 
              top: 20, 
              right: window.innerWidth < 768 ? 20 : 30, 
              left: window.innerWidth < 768 ? 10 : 20, 
              bottom: window.innerWidth < 768 ? 20 : 10 
            }}
            layout="vertical"
          >
            <CartesianGrid 
              strokeDasharray="3 3" 
              stroke={THEME_COLORS.chart.grid}
            />
            <XAxis 
              type="number"
              stroke={THEME_COLORS.chart.axis}
              tick={{ 
                fill: THEME_COLORS.chart.text, 
                fontSize: window.innerWidth < 768 ? '10px' : '11px' 
              }}
              tickFormatter={(value) => formatNumber(value)}
            />
            <YAxis 
              dataKey="type" 
              type="category"
              stroke={THEME_COLORS.chart.axis}
              tick={{ 
                fill: THEME_COLORS.chart.text, 
                fontSize: window.innerWidth < 768 ? '10px' : '11px'
              }}
              width={window.innerWidth < 768 ? 70 : 100}
              tickFormatter={(value) => value.length > 10 ? `${value.slice(0, 10)}...` : value}
            />
            <Tooltip 
              content={<CustomTooltip />}
              cursor={{ fill: 'rgba(136, 132, 216, 0.1)' }}
            />
            <Bar 
              dataKey="count" 
              fill={THEME_COLORS.chart.colors[0]}
              radius={[0, 4, 4, 0]}
              maxBarSize={window.innerWidth < 768 ? 15 : 25}
            >
              {displayData.map((entry, index) => (
                <Cell 
                  key={`cell-${index}`}
                  fill={THEME_COLORS.chart.colors[index % THEME_COLORS.chart.colors.length]}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
    );
  };

  // New helper functions for enhanced analytics

  // Generate heatmap data from user activity
  const generateHeatmapData = (data) => {
    // Default to empty array if no data
    const activityData = safeArray(data);
    
    // Create a 24x7 grid (hours x days of week)
    const heatmapGrid = Array(7).fill().map(() => Array(24).fill(0));
    
    // Populate the grid with activity counts
    activityData.forEach(item => {
      const date = new Date(item.timestamp * 1000);
      const dayOfWeek = date.getDay();
      const hourOfDay = date.getHours();
      
      heatmapGrid[dayOfWeek][hourOfDay] += 1;
    });
    
    // Format for the heatmap chart
    return heatmapGrid.flatMap((hours, dayIndex) => 
      hours.map((value, hourIndex) => ({
        day: dayIndex,
        hour: hourIndex,
        value: value
      }))
    );
  };

  // Process event data for visualization
  const processEventData = (events) => {
    const eventData = safeArray(events);
    const categories = {};
    
    // Group events by category
    eventData.forEach(event => {
      const category = event.category || 'Uncategorized';
      if (!categories[category]) {
        categories[category] = [];
      }
      categories[category].push(event);
    });
    
    // Format for visualization
    return Object.entries(categories).map(([category, events]) => ({
      name: category,
      value: events.length,
      events: events
    }));
  };

  // Generate conversion funnel data
  const generateFunnelData = () => {
    // This would ideally come from the API, but we'll create a sample funnel
    // based on available metrics
    return [
      { 
        name: 'Page Views', 
        value: safeMetric(stats, 'engagementMetrics.pageViews') 
      },
      { 
        name: 'Engaged Users', 
        value: Math.round(safeMetric(stats, 'engagementMetrics.pageViews') * 
          (1 - safeMetric(stats, 'engagementMetrics.bounceRate') / 100)) 
      },
      { 
        name: 'Horoscope Generations', 
        value: safeMetric(stats, 'contentMetrics.totalHoroscopes') 
      },
      { 
        name: 'Signups', 
        value: safeMetric(stats, 'userMetrics.newUsers') 
      }
    ];
  };

  // Format content performance data
  const formatContentPerformance = () => {
    const articles = safeArray(stats.topArticles);
    const horoscopes = safeArray(stats.popularHoroscopes);
    
    // Combine and sort by views/count
    return [...articles, ...horoscopes]
      .sort((a, b) => (b.views || b.count || 0) - (a.views || a.count || 0))
      .slice(0, 10)
      .map(item => ({
        title: item.title,
        views: item.views || item.count || 0,
        type: item.type ? 'Horoscope' : 'Article',
        engagement: item.engagement || Math.round(Math.random() * 100) // Placeholder
      }));
  };

  // New rendering functions for enhanced analytics

  // Render user activity heatmap with better responsive styling
  const renderActivityHeatmap = () => {
    const heatmapData = generateHeatmapData(safeArray(stats.userActivity));
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const hours = Array.from({ length: 24 }, (_, i) => i);
    
    // Find max value for color scaling
    const maxValue = Math.max(...heatmapData.map(d => d.value));
    
    if (heatmapData.length === 0) {
      return (
        <Center h={200}>
          <Text c={THEME_COLORS.lightText}>No activity data available</Text>
        </Center>
      );
    }
    
    return (
      <Box>
        <Group position="apart" mb="md">
          <Title order={3} style={{ fontSize: 'calc(0.9rem + 0.3vw)' }}>Activity Heatmap</Title>
          <ActionIcon 
            variant="light" 
            color="blue" 
            onClick={() => {/* Add export functionality */}}
            title="Export Data"
          >
            <IconDownload size={18} />
          </ActionIcon>
        </Group>

        <Box>
          <Table 
            withBorder
            style={{
              tableLayout: 'fixed',
              width: '100%'
            }}
          >
            <Table.Thead>
              <Table.Tr>
                <Table.Th style={{ width: '4rem' }}></Table.Th>
                {hours.map(hour => (
                  <Table.Th 
                    key={hour} 
                    style={{ 
                      padding: '4px',
                      textAlign: 'center',
                      width: 'calc((100% - 4rem) / 24)'
                    }}
                  >
                    <Text size="xs" c="dimmed">{hour}</Text>
                  </Table.Th>
                ))}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {days.map((day, dayIndex) => (
                <Table.Tr key={day}>
                  <Table.Td style={{ padding: '4px', width: '4rem' }}>
                    <Text size="sm" c="dimmed" ta="right">{day}</Text>
                  </Table.Td>
                  {hours.map(hour => {
                    const dataPoint = heatmapData.find(d => d.day === dayIndex && d.hour === hour);
                    const value = dataPoint ? dataPoint.value : 0;
                    const intensity = maxValue > 0 ? value / maxValue : 0;
                    const backgroundColor = `rgba(136, 132, 216, ${intensity})`;
                    
                    return (
                      <Table.Td 
                        key={hour} 
                        style={{ 
                          padding: 0,
                          aspectRatio: '1',
                          backgroundColor,
                          border: '1px solid var(--mantine-color-default-border)',
                          textAlign: 'center',
                          color: intensity > 0.5 ? 'white' : 'var(--mantine-color-text)',
                          cursor: 'pointer',
                          transition: 'transform 0.1s ease',
                          width: 'calc((100% - 4rem) / 24)',
                          position: 'relative'
                        }}
                      >
                        <Box 
                          style={{ 
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <MantineTooltip 
                            label={`${day} ${hour}:00 - ${value} activities`}
                            position="top"
                            withArrow
                          >
                            <Text size="xs">
                              {value > 0 ? value : ''}
                            </Text>
                          </MantineTooltip>
                        </Box>
                      </Table.Td>
                    );
                  })}
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Box>
      </Box>
    );
  };

  // Render event tracking visualization with better responsive styling
  const renderEventTracking = () => {
    const eventData = processEventData(safeArray(stats.eventTracking));
    
    if (eventData.length === 0) {
      return (
        <Center h={200}>
          <Text c={THEME_COLORS.lightText}>No event data available</Text>
        </Center>
      );
    }
    
    return (
      <Box>
        <Title order={3} mb="md" style={{ fontSize: 'calc(0.9rem + 0.3vw)' }}>Event Tracking</Title>
        <Box style={{ width: '100%', height: '300px', minHeight: '250px' }}>
          <ResponsiveContainer width="100%" height="100%" minHeight={250}>
            <BarChart
              data={eventData}
              margin={{ 
                top: 20, 
                right: window.innerWidth < 768 ? 20 : 30, 
                left: window.innerWidth < 768 ? 10 : 20, 
                bottom: 20 
              }}
              layout="vertical"
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                type="number"
                tick={{ fontSize: window.innerWidth < 768 ? '10px' : '11px' }}
              />
              <YAxis 
                dataKey="name" 
                type="category" 
                width={window.innerWidth < 768 ? 100 : 120}
                tick={{ fontSize: window.innerWidth < 768 ? '10px' : '11px' }}
              />
              <Tooltip formatter={(value) => value.toLocaleString()} />
              <Bar 
                dataKey="value" 
                fill={THEME_COLORS.primary}
                radius={[0, 4, 4, 0]}
                maxBarSize={window.innerWidth < 768 ? 20 : 30}
              />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    );
  };

  // Render content performance metrics with better responsive styling
  const renderContentPerformance = () => {
    const contentData = formatContentPerformance();
    
    if (contentData.length === 0) {
      return (
        <Center h={200}>
          <Text c={THEME_COLORS.lightText}>No content performance data available</Text>
        </Center>
      );
    }
    
    const filteredData = contentData.filter(item => 
      contentFilterType === 'all' || item.type.toLowerCase() === contentFilterType
    );
    
    return (
      <Box>
        <Group position="apart" mb="md" style={{ 
          flexDirection: window.innerWidth < 768 ? 'column' : 'row',
          gap: window.innerWidth < 768 ? '0.5rem' : '1rem'
        }}>
          <Title order={3} style={{ fontSize: 'calc(0.9rem + 0.3vw)' }}>Content Performance</Title>
          <Group style={{ 
            width: '100%', 
            justifyContent: window.innerWidth < 768 ? 'flex-start' : 'flex-end',
            gap: '0.5rem'
          }}>
            <Select
              size="xs"
              placeholder="Filter by type"
              value={contentFilterType}
              onChange={setContentFilterType}
              data={[
                { value: 'all', label: 'All Content' },
                { value: 'article', label: 'Articles' },
                { value: 'horoscope', label: 'Horoscopes' }
              ]}
              style={{ width: window.innerWidth < 768 ? '100%' : '150px' }}
            />
            <SegmentedControl
              size="xs"
              value={contentViewType}
              onChange={setContentViewType}
              data={[
                { label: 'Chart', value: 'chart' },
                { label: 'Table', value: 'table' }
              ]}
            />
          </Group>
        </Group>

        <ScrollArea>
          {contentViewType === 'chart' ? (
            <Box style={{ width: '100%', height: '400px', minHeight: '300px' }}>
              <ResponsiveContainer width="100%" height="100%" minHeight={300}>
                <ComposedChart
                  data={filteredData}
                  margin={{ 
                    top: 20, 
                    right: window.innerWidth < 768 ? 20 : 30, 
                    left: window.innerWidth < 768 ? 10 : 20, 
                    bottom: window.innerWidth < 768 ? 80 : 60 
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis 
                    dataKey="title" 
                    angle={-45} 
                    textAnchor="end" 
                    height={window.innerWidth < 768 ? 100 : 80}
                    interval={0}
                    tick={{ 
                      fill: THEME_COLORS.text, 
                      fontSize: window.innerWidth < 768 ? '10px' : '11px'
                    }}
                  />
                  <YAxis 
                    yAxisId="left" 
                    orientation="left" 
                    stroke={THEME_COLORS.primary}
                    tick={{ fontSize: window.innerWidth < 768 ? '10px' : '11px' }}
                    width={window.innerWidth < 768 ? 30 : 40}
                  />
                  <YAxis 
                    yAxisId="right" 
                    orientation="right" 
                    stroke={THEME_COLORS.secondary}
                    tick={{ fontSize: window.innerWidth < 768 ? '10px' : '11px' }}
                    width={window.innerWidth < 768 ? 30 : 40}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend 
                    wrapperStyle={{ fontSize: window.innerWidth < 768 ? '10px' : '11px' }}
                    verticalAlign="top"
                    height={36}
                  />
                  <Bar 
                    yAxisId="left"
                    dataKey="views" 
                    name="Views" 
                    fill={THEME_COLORS.primary}
                    radius={[4, 4, 0, 0]}
                    maxBarSize={window.innerWidth < 768 ? 20 : 30}
                  />
                  <Line 
                    yAxisId="right"
                    type="monotone" 
                    dataKey="engagement" 
                    name="Engagement Score" 
                    stroke={THEME_COLORS.secondary}
                    strokeWidth={2}
                    dot={{ r: window.innerWidth < 768 ? 2 : 3 }}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </Box>
          ) : (
            <Table.ScrollContainer minWidth={500}>
              <Table 
                striped 
                highlightOnHover 
                withBorder
                tabularNums
              >
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>Title</Table.Th>
                    <Table.Th>Type</Table.Th>
                    <Table.Th>Views</Table.Th>
                    <Table.Th>Engagement</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {filteredData.map((item, index) => (
                    <Table.Tr key={index}>
                      <Table.Td style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {item.title}
                      </Table.Td>
                      <Table.Td>
                        <Badge size="sm" color={item.type === 'Horoscope' ? 'violet' : 'blue'}>
                          {item.type}
                        </Badge>
                      </Table.Td>
                      <Table.Td>{formatNumber(item.views)}</Table.Td>
                      <Table.Td>
                        <Text c={item.engagement > 75 ? 'green' : item.engagement > 50 ? 'blue' : 'orange'}>
                          {item.engagement}%
                        </Text>
                      </Table.Td>
                    </Table.Tr>
                  ))}
                </Table.Tbody>
              </Table>
            </Table.ScrollContainer>
          )}
        </ScrollArea>
      </Box>
    );
  };

  // Render conversion funnel visualization with better responsive styling
  const renderConversionFunnel = () => {
    const funnelData = generateFunnelData();
    
    if (funnelData.length === 0) {
      return (
        <Center h={200}>
          <Text c={THEME_COLORS.lightText}>No conversion data available</Text>
        </Center>
      );
    }
    
    // Calculate conversion rates between steps
    const conversionRates = funnelData.map((step, index) => {
      if (index === 0) return { ...step, rate: 100 };
      
      const previousStep = funnelData[index - 1];
      const rate = previousStep.value > 0 
        ? Math.round((step.value / previousStep.value) * 100) 
        : 0;
        
      return { ...step, rate };
    });
    
    return (
      <Grid gutter={window.innerWidth < 768 ? "xs" : "md"}>
        <Grid.Col span={12} md={7}>
          <Box style={{ width: '100%', height: '300px', minHeight: '250px' }}>
            <ResponsiveContainer width="100%" height="100%" minHeight={250}>
              <BarChart
                data={funnelData}
                margin={{ 
                  top: 20, 
                  right: window.innerWidth < 768 ? 20 : 30, 
                  left: window.innerWidth < 768 ? 10 : 20, 
                  bottom: 20 
                }}
                layout="vertical"
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  type="number"
                  tick={{ fontSize: window.innerWidth < 768 ? '10px' : '11px' }}
                />
                <YAxis 
                  dataKey="name" 
                  type="category" 
                  width={window.innerWidth < 768 ? 100 : 120}
                  tick={{ fontSize: window.innerWidth < 768 ? '10px' : '11px' }}
                />
                <Tooltip formatter={(value) => value.toLocaleString()} />
                <Bar 
                  dataKey="value" 
                  fill={THEME_COLORS.primary}
                  radius={[0, 4, 4, 0]}
                  maxBarSize={window.innerWidth < 768 ? 20 : 30}
                />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Grid.Col>
        
        <Grid.Col span={12} md={5}>
          <Card withBorder p={window.innerWidth < 768 ? "xs" : "md"}>
            <Title order={4} mb="md" style={{ fontSize: 'calc(0.9rem + 0.3vw)' }}>Conversion Rates</Title>
            <Table.ScrollContainer minWidth={300}>
              <Table 
                striped 
                highlightOnHover
                withBorder
                tabularNums
              >
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>Step</Table.Th>
                    <Table.Th>Rate</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {conversionRates.map((step, index) => (
                    <Table.Tr key={index}>
                      <Table.Td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {step.name}
                      </Table.Td>
                      <Table.Td>
                        {index === 0 ? (
                          <Badge size="sm" color="blue">Entry</Badge>
                        ) : (
                          <Badge 
                            size="sm"
                            color={step.rate > 50 ? 'green' : step.rate > 20 ? 'yellow' : 'red'}
                          >
                            {step.rate}%
                          </Badge>
                        )}
                      </Table.Td>
                    </Table.Tr>
                  ))}
                </Table.Tbody>
              </Table>
            </Table.ScrollContainer>
          </Card>
        </Grid.Col>
      </Grid>
    );
  };

  // Add effect to fetch IP locations
  useEffect(() => {
    const fetchIPLocations = async () => {
      const dailyData = safeArray(stats.growthTrends);

      console.log('Daily Data:', dailyData); // Debug log

      const newLocations = {};
      
      for (const day of dailyData) {
        console.log('Processing day:', day.date); // Debug log
        console.log('Device stats:', day.device_stats); // Debug log
        
        const visitorIPs = day.device_stats?.visitor_ips || [];
        console.log('Visitor IPs:', visitorIPs); // Debug log
        
        if (visitorIPs.length > 0) {
          const locations = await Promise.all(
            visitorIPs.map(async (ip) => {
              console.log('Fetching location for IP:', ip); // Debug log
              const location = await getCachedIPLocation(ip);
              console.log('Location result:', location); // Debug log
              return { ip, location };
            })
          );
          newLocations[day.date] = locations;
        }
      }
      
      console.log('Final locations:', newLocations); // Debug log
      setIpLocations(newLocations);
    };

    if (stats.growthTrends?.length > 0) {
      fetchIPLocations();
    }
  }, [stats.growthTrends]);

  // Render daily breakdown table with better responsive styling
  const renderDailyBreakdown = () => {
    const dailyData = safeArray(stats.growthTrends);
    
    console.log('Rendering daily breakdown with data:', dailyData); // Debug log
    
    if (dailyData.length === 0) {
      return (
        <Center h={200}>
          <Text c={THEME_COLORS.lightText}>No daily breakdown data available</Text>
        </Center>
      );
    }
    
    return (
      <Box>
        <Group position="apart" mb="md">
          <Title order={3} style={{ fontSize: 'calc(0.9rem + 0.3vw)' }}>Daily Statistics</Title>
          <ActionIcon 
            variant="light" 
            color="blue" 
            onClick={() => {/* Add export functionality */}}
            title="Export Data"
          >
            <IconDownload size={18} />
          </ActionIcon>
        </Group>
        
        <Table.ScrollContainer minWidth={500}>
          <Table 
            striped 
            highlightOnHover 
            withBorder
            tabularNums
          >
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Date</Table.Th>
                <Table.Th>Views</Table.Th>
                <Table.Th>Users</Table.Th>
                <Table.Th>Unique IPs</Table.Th>
                <Table.Th>New</Table.Th>
                <Table.Th>Bounce</Table.Th>
                <Table.Th>Time</Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {dailyData.map((day, index) => {
                const visitorIPs = day.device_stats?.visitor_ips || [];
                const uniqueIPs = visitorIPs.length;
                const dayLocations = ipLocations[day.date] || [];
                
                console.log('Rendering row for date:', day.date); // Debug log
                console.log('Visitor IPs:', visitorIPs); // Debug log
                console.log('Day locations:', dayLocations); // Debug log
                
                return (
                  <Table.Tr key={index}>
                    <Table.Td>
                      <Text fw={500}>{formatDate(day.date)}</Text>
                    </Table.Td>
                    <Table.Td>
                      <Text>{formatNumber(day.pageViews || 0)}</Text>
                    </Table.Td>
                    <Table.Td>
                      <Text>{formatNumber(day.users || 0)}</Text>
                    </Table.Td>
                    <Table.Td>
                      <MantineTooltip 
                        label={
                          <Box p="xs" style={{ 
                            background: 'var(--mantine-color-body)',
                            border: '1px solid var(--mantine-color-default-border)',
                            borderRadius: 'var(--mantine-radius-md)'
                          }}>
                            <Text size="sm" fw={700} mb="xs" c="var(--mantine-color-text)">
                              Unique IPs: {uniqueIPs}
                            </Text>
                            <Stack spacing="xs">
                              {dayLocations.length > 0 ? (
                                dayLocations.map(({ ip, location }, idx) => (
                                  <Box 
                                    key={idx}
                                    p="xs"
                                    style={{
                                      background: 'var(--mantine-color-gray-0)',
                                      border: '1px solid var(--mantine-color-gray-2)',
                                      borderRadius: 'var(--mantine-radius-sm)'
                                    }}
                                  >
                                    <Text size="sm" fw={500} c="var(--mantine-color-text)">
                                      {ip}
                                    </Text>
                                    {location && (
                                      <Group spacing={4} noWrap mt={4}>
                                        <IconMapPin size={14} color="var(--mantine-color-blue-6)" />
                                        <Text size="sm" c="var(--mantine-color-dimmed)">
                                          {location.city}, {location.country}
                                        </Text>
                                      </Group>
                                    )}
                                    {location?.isp && (
                                      <Text size="xs" c="var(--mantine-color-dimmed)" mt={2} ml={18}>
                                        {location.isp}
                                      </Text>
                                    )}
                                  </Box>
                                ))
                              ) : (
                                <Text size="sm" c="var(--mantine-color-dimmed)" ta="center">
                                  No IP data available
                                </Text>
                              )}
                            </Stack>
                          </Box>
                        }
                        position="top"
                        withArrow
                        multiline
                        width={350}
                        color="gray"
                        styles={{
                          tooltip: {
                            background: 'var(--mantine-color-body)',
                            border: '1px solid var(--mantine-color-default-border)',
                            boxShadow: 'var(--mantine-shadow-md)'
                          }
                        }}
                      >
                        <Text>{formatNumber(uniqueIPs)}</Text>
                      </MantineTooltip>
                    </Table.Td>
                    <Table.Td>
                      <Group spacing="xs" noWrap>
                        <Text>{formatNumber(day.newUsers || 0)}</Text>
                        {(day.newUsers > 0) && (
                          <Badge size="sm" color="green" variant="dot" />
                        )}
                      </Group>
                    </Table.Td>
                    <Table.Td>
                      <Text c={day.bounceRate > 70 ? 'red' : day.bounceRate > 50 ? 'orange' : 'green'}>
                        {(day.bounceRate || 0).toFixed(1)}%
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Text>{formatDuration(day.avgSessionDuration)}</Text>
                    </Table.Td>
                    <Table.Td>
                      <Group spacing="xs" noWrap position="right">
                        <ActionIcon size="sm" variant="subtle" color="blue" title="View Details">
                          <IconEye size={14} />
                        </ActionIcon>
                        <ActionIcon size="sm" variant="subtle" color="gray" title="Export">
                          <IconDownload size={14} />
                        </ActionIcon>
                      </Group>
                    </Table.Td>
                  </Table.Tr>
                );
              })}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
      </Box>
    );
  };

  // If user is not authenticated or not an admin, show a message
  if (!isAuthenticated || !isAdmin) {
    return (
      <Container size="lg" py="xl">
        <Paper p="xl" radius="md" withBorder style={{ textAlign: 'center' }}>
          <Title order={2} mb="lg">Statistics Dashboard</Title>
          <Text mb="xl">You need to be logged in with an admin account to view site statistics.</Text>
          <Button
            variant="filled"
            color="blue"
            size="md"
            leftIcon={<IconLogin size={20} />}
            onClick={() => {
              // Redirect to login page or trigger login modal
              window.location.href = '/login';
            }}
          >
            Log In
          </Button>
        </Paper>
      </Container>
    );
  }

  return (
    <div>
      <Container size="lg" py="md" className="statistics-container" style={{ 
        width: '100%',
        maxWidth: '100%',
        padding: '0',
        margin: '0'
      }}>
        <Paper withBorder shadow="sm" p="md" mb="lg" style={{
          width: '100%',
          maxWidth: '100%',
          margin: '0',
          borderRadius: '0'
        }}>
          <Group position="apart" mb="md">
            <div>
              <Title order={1} style={{ fontSize: 'calc(1.2rem + 0.5vw)' }}>Site Statistics</Title>
              <Text c={THEME_COLORS.lightText} mt="xs">
                Analytics and insights for your site
              </Text>
            </div>
            
            <Group>
              <Select
                label="Time Range"
                value={timeRange}
                onChange={setTimeRange}
                data={[
                  { value: '7d', label: 'Last 7 Days' },
                  { value: '30d', label: 'Last 30 Days' },
                  { value: '90d', label: 'Last 90 Days' },
                  { value: '1y', label: 'Last Year' },
                  { value: 'all', label: 'All Time' }
                ]}
                style={{ minWidth: '150px' }}
              />
              
              <ActionIcon 
                size="lg" 
                color="blue" 
                variant="light"
                onClick={() => window.print()}
                title="Export as PDF"
              >
                <IconDownload size={20} />
              </ActionIcon>
            </Group>
          </Group>

          {error && (
            <Paper p="md" mb="lg" bg="var(--mantine-color-red-light)" c="var(--mantine-color-red-dark)">
              <Text>{error}</Text>
            </Paper>
          )}

          {/* Summary Metrics Grid - Enhanced responsive layout */}
          <Grid gutter="md" mb="md">
            <Grid.Col xs={12} sm={12} md={6}>
              <Paper 
                withBorder 
                p="md" 
                radius="sm" 
                style={{ 
                  height: '100%',
                  background: 'var(--mantine-color-body)',
                  borderColor: 'var(--mantine-color-gray-3)'
                }}
              >
                <Group position="apart" mb={4}>
                  <ThemeIcon 
                    size={32} 
                    radius="md" 
                    variant="light"
                    color="violet"
                  >
                    <IconUsers size={18} />
                  </ThemeIcon>
                  <Text fz="xl" fw={500}>
                    {formatNumber(safeMetric(stats, 'userMetrics.totalUsers'))}
                  </Text>
                </Group>
                <Text c="dimmed" fz="sm" tt="uppercase" fw={700} mb={2}>
                  Total Users
                </Text>
                <Text c="dimmed" fz="xs">
                  {formatNumber(safeMetric(stats, 'userMetrics.newUsers'))} new, {formatNumber(safeMetric(stats, 'userMetrics.activeUsers'))} active
                </Text>
              </Paper>
            </Grid.Col>
            
            <Grid.Col xs={12} sm={12} md={6}>
              <Paper 
                withBorder 
                p="md" 
                radius="sm" 
                style={{ 
                  height: '100%',
                  background: 'var(--mantine-color-body)',
                  borderColor: 'var(--mantine-color-gray-3)'
                }}
              >
                <Group position="apart" mb={4}>
                  <ThemeIcon 
                    size={32} 
                    radius="md" 
                    variant="light"
                    color="blue"
                  >
                    <IconArticle size={18} />
                  </ThemeIcon>
                  <Text fz="xl" fw={500}>
                    {formatNumber(safeMetric(stats, 'contentMetrics.totalArticles'))} articles
                  </Text>
                </Group>
                <Text c="dimmed" fz="sm" tt="uppercase" fw={700} mb={2}>
                  Content
                </Text>
                <Text c="dimmed" fz="xs">
                  {formatNumber(safeMetric(stats, 'contentMetrics.totalHoroscopes'))} horoscopes, {formatNumber(safeMetric(stats, 'contentMetrics.newContent'))} new
                </Text>
              </Paper>
            </Grid.Col>
            
            <Grid.Col xs={12} sm={12} md={6}>
              <Paper 
                withBorder 
                p="md" 
                radius="sm" 
                style={{ 
                  height: '100%',
                  background: 'var(--mantine-color-body)',
                  borderColor: 'var(--mantine-color-gray-3)'
                }}
              >
                <Group position="apart" mb={4}>
                  <ThemeIcon 
                    size={32} 
                    radius="md" 
                    variant="light"
                    color="teal"
                  >
                    <IconEye size={18} />
                  </ThemeIcon>
                  <Text fz="xl" fw={500}>
                    {formatNumber(safeMetric(stats, 'engagementMetrics.pageViews', 0))}
                  </Text>
                </Group>
                <Text c="dimmed" fz="sm" tt="uppercase" fw={700} mb={2}>
                  Engagement
                </Text>
                <Text c="dimmed" fz="xs">
                  {stats.engagementMetrics?.averageTimeOnSite || '0m 0s'} avg. time, {safeMetric(stats, 'engagementMetrics.bounceRate', 0).toFixed(1)}% bounce
                </Text>
              </Paper>
            </Grid.Col>
            
            <Grid.Col xs={12} sm={12} md={6}>
              <Paper 
                withBorder 
                p="md" 
                radius="sm" 
                style={{ 
                  height: '100%',
                  background: 'var(--mantine-color-body)',
                  borderColor: 'var(--mantine-color-gray-3)'
                }}
              >
                <Group position="apart" mb={4}>
                  <ThemeIcon 
                    size={32} 
                    radius="md" 
                    variant="light"
                    color="orange"
                  >
                    <IconArrowsRightLeft size={18} />
                  </ThemeIcon>
                  <Text fz="xl" fw={500}>
                    Distribution
                  </Text>
                </Group>
                <Text c="dimmed" fz="sm" tt="uppercase" fw={700} mb={2}>
                  Traffic Sources
                </Text>
                <Text c="dimmed" fz="xs">
                  Direct, search, social, referral
                </Text>
              </Paper>
            </Grid.Col>
          </Grid>

          {/* Tabs interface - Simple layout matching AdminDashboard.js */}
          <Tabs value={activeTab} onChange={setActiveTab} mt="md">
            <Tabs.List>
              <Tabs.Tab value="overview" leftSection={<IconChartPie size={16} />}>Overview</Tabs.Tab>
              <Tabs.Tab value="daily" leftSection={<IconCalendar size={16} />}>Daily</Tabs.Tab>
              <Tabs.Tab value="activity" leftSection={<IconActivity size={16} />}>Activity</Tabs.Tab>
              <Tabs.Tab value="events" leftSection={<IconClick size={16} />}>Events</Tabs.Tab>
              <Tabs.Tab value="content" leftSection={<IconArticle size={16} />}>Content</Tabs.Tab>
              <Tabs.Tab value="funnel" leftSection={<IconHourglassHigh size={16} />}>Conversion</Tabs.Tab>
              <Tabs.Tab value="raw" leftSection={<IconTable size={16} />}>Raw Data</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value={activeTab} pt="md">
              {activeTab === "overview" && (
                <Grid gutter="md">
                  <Grid.Col xs={12} lg={8}>
                    <Paper p="md" withBorder bg="var(--mantine-color-body)">
                      <Title order={3} mb="md">Growth Trends</Title>
                      {renderGrowthChart()}
                    </Paper>
                  </Grid.Col>
                  
                  <Grid.Col xs={12} sm={6} lg={4}>
                    <Paper p="md" withBorder bg="var(--mantine-color-body)">
                      {renderPieChart(safeArray(stats.trafficSources), 'value', 'name', 'Traffic Sources')}
                    </Paper>
                  </Grid.Col>
                  
                  <Grid.Col xs={12} sm={6} lg={4}>
                    <Paper p="md" withBorder bg="var(--mantine-color-body)">
                      {renderPieChart(safeArray(stats.deviceStats).filter(stat => stat.count > 0), 'value', 'device', 'Device Stats')}
                    </Paper>
                  </Grid.Col>
                  
                  <Grid.Col xs={12} lg={8}>
                    <Paper p="md" withBorder bg="var(--mantine-color-body)">
                      <Title order={3} mb="md">Popular Horoscopes</Title>
                      {renderPopularHoroscopes()}
                    </Paper>
                  </Grid.Col>
                </Grid>
              )}

              {activeTab === "daily" && (
                <Paper p="md" withBorder bg="var(--mantine-color-body)">
                  <ScrollArea>
                    {renderDailyBreakdown()}
                  </ScrollArea>
                </Paper>
              )}

              {activeTab === "activity" && (
                <Paper p="md" withBorder bg="var(--mantine-color-body)">
                  <ScrollArea>
                    {renderActivityHeatmap()}
                  </ScrollArea>
                </Paper>
              )}

              {activeTab === "events" && (
                <Paper p="md" withBorder bg="var(--mantine-color-body)">
                  {renderEventTracking()}
                </Paper>
              )}

              {activeTab === "content" && (
                <Paper p="md" withBorder bg="var(--mantine-color-body)">
                  {renderContentPerformance()}
                </Paper>
              )}

              {activeTab === "funnel" && (
                <Paper p="md" withBorder bg="var(--mantine-color-body)">
                  {renderConversionFunnel()}
                </Paper>
              )}

              {activeTab === "raw" && (
                <Paper p="md" withBorder bg="var(--mantine-color-body)">
                  {/* Render raw data table */}
                </Paper>
              )}
            </Tabs.Panel>
          </Tabs>
        </Paper>
      </Container>
    </div>
  );
}

export default SiteStatistics; 