import React, { useState, useEffect } from 'react';
import { 
  Tabs, 
  Paper, 
  Title, 
  LoadingOverlay, 
  Box, 
  Text,
  Button,
  Group,
  Table,
  Badge,
  ActionIcon,
  Modal,
  TextInput,
  Select,
  Stack,
  Card,
  Textarea,
  Alert,
  Code,
  Switch
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconEdit, IconTrash, IconUserPlus, IconThumbUp, IconThumbDown, IconRefresh } from '@tabler/icons-react';
import axios from '../axiosConfig';
import ArticleManagement from './ArticleManagement';
import AdminWaitlist from './AdminWaitlist';
import HoroscopeGenerator from './HoroscopeGenerator';
import ArticleGenerator from './ArticleGenerator';
import SiteStatistics from './SiteStatistics';
import { useNavigate } from 'react-router-dom';
import '../styles/admin-dashboard.css';

function AdminDashboard({ user }) {
  console.log('AdminDashboard rendering with user:', user);
  
  const [activeTab, setActiveTab] = useState('waitlist');
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [editingArticleId, setEditingArticleId] = useState(null);
  const [mcpTestResult, setMcpTestResult] = useState(null);
  const [mcpLoading, setMcpLoading] = useState(false);
  const [testCity, setTestCity] = useState('San Francisco');
  const [city, setCity] = useState('Stockholm');
  const [weatherData, setWeatherData] = useState(null);
  const [locationData, setLocationData] = useState(null);
  const [error, setError] = useState(null);
  const [userPreferences, setUserPreferences] = useState('default');
  const [preferencesData, setPreferencesData] = useState(null);
  const [preferencesLoading, setPreferencesLoading] = useState(false);
  const [preferencesError, setPreferencesError] = useState(null);
  const [switchLoading, setSwitchLoading] = useState({
    quiet: false,
    lively: false
  });
  const [locationLoading, setLocationLoading] = useState(false);
  const [locationError, setLocationError] = useState(null);
  const [voteLoading, setVoteLoading] = useState(false);
  const [outingType, setOutingType] = useState('date');
  
  const navigate = useNavigate();
  
  // Fetch users
  const fetchUsers = async () => {
    setLoading(true);
    console.log('Fetching users...');
    try {
      console.log('Making API request to /api/users/admin/all');
      const response = await axios.get('/api/users/admin/all');
      console.log('API response received:', response);
      
      if (response.data && Array.isArray(response.data)) {
        console.log(`Found ${response.data.length} users`);
        setUsers(response.data);
      } else {
        console.error('Unexpected response format:', response.data);
        setUsers([]);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      if (error.response) {
        console.error('Response status:', error.response.status);
        console.error('Response data:', error.response.data);
      }
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch users. ' + (error.response?.data?.message || error.message),
        color: 'red'
      });
      
      // Fallback: At least show the current user
      if (user) {
        console.log('Using current user as fallback:', user);
        setUsers([{
          id: user.id,
          username: user.username,
          email: user.email,
          role: 'admin', // Assume admin since they can access this page
          subscription_status: user.subscription_status || 'none'
        }]);
      } else {
        setUsers([]);
      }
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    if (activeTab === 'users') {
      fetchUsers();
    }
  }, [activeTab]);
  
  // Handle role update
  const handleRoleUpdate = async () => {
    if (!currentUser || !currentUser.role) {
      notifications.show({
        title: 'Error',
        message: 'Please select a role',
        color: 'red'
      });
      return;
    }
    
    try {
      await axios.put(`/api/users/admin/${currentUser.id}/role`, { role: currentUser.role });
      notifications.show({
        title: 'Success',
        message: 'User role updated successfully',
        color: 'green'
      });
      setModalOpen(false);
      fetchUsers(); // Refresh users
    } catch (error) {
      console.error('Error updating user role:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to update user role: ' + (error.response?.data?.message || error.message),
        color: 'red'
      });
    }
  };
  
  // Handle user deletion
  const handleDeleteUser = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user? This action cannot be undone.')) {
      try {
        await axios.delete(`/api/users/admin/${userId}`);
        notifications.show({
          title: 'Success',
          message: 'User deleted successfully',
          color: 'green'
        });
        fetchUsers(); // Refresh users
      } catch (error) {
        console.error('Error deleting user:', error);
        notifications.show({
          title: 'Error',
          message: 'Failed to delete user: ' + (error.response?.data?.message || error.message),
          color: 'red'
        });
      }
    }
  };
  
  // Open edit modal for a user
  const openEditModal = (user) => {
    setCurrentUser(user);
    setModalOpen(true);
  };
  
  // New handlers for article management
  const handleEditArticle = (article) => {
    console.log('Editing article:', article);
    setEditingArticleId(article.id);
    setActiveTab('article-generator');
  };
  
  const handleCreateArticle = () => {
    console.log('Creating new article');
    setEditingArticleId(null);
    setActiveTab('article-generator');
  };
  
  // Render user management tab
  const renderUserManagement = () => {
    return (
      <div className="admin-table-container">
        <Table 
          className="admin-table"
          style={{ 
            width: '100%',
            tableLayout: 'auto',
            borderCollapse: 'collapse'
          }}
          sx={(theme) => ({
            'th, td': {
              padding: '12px 16px',
              borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]}`,
              fontSize: theme.fontSizes.sm,
              textAlign: 'left'
            },
            'th': {
              backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
              fontWeight: 600,
              textAlign: 'left'
            }
          })}>
          <thead>
            <tr>
              <th style={{ width: '5%', textAlign: 'left' }}>ID</th>
              <th style={{ width: '20%', textAlign: 'left' }}>Username</th>
              <th style={{ width: '30%', textAlign: 'left' }}>Email</th>
              <th style={{ width: '15%', textAlign: 'left' }}>Role</th>
              <th style={{ width: '15%', textAlign: 'left' }}>Subscription</th>
              <th style={{ width: '15%', textAlign: 'left' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.username}</td>
                <td>{user.email}</td>
                <td>
                  <Badge 
                    color={user.role === 'admin' ? 'blue' : user.role === 'moderator' ? 'green' : 'gray'}
                  >
                    {user.role}
                  </Badge>
                </td>
                <td>{user.subscription_status || 'none'}</td>
                <td>
                  <Group spacing="xs">
                    <ActionIcon 
                      variant="subtle" 
                      color="blue" 
                      onClick={() => openEditModal(user)}
                      disabled={user.id === '17' || user.id === 17}
                      sx={(theme) => ({
                        '&:hover': {
                          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[1]
                        }
                      })}
                    >
                      <IconEdit size={16} />
                    </ActionIcon>
                    <ActionIcon 
                      variant="subtle" 
                      color="red" 
                      onClick={() => handleDeleteUser(user.id)}
                      disabled={user.id === '17' || user.id === 17}
                      sx={(theme) => ({
                        '&:hover': {
                          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[1]
                        }
                      })}
                    >
                      <IconTrash size={16} />
                    </ActionIcon>
                  </Group>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };
  
  // Add MCP test function
  const handleGetWeather = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }

      console.log('Making weather request to MCP:', {
        city,
        token: token.substring(0, 10) + '...'
      });

      const response = await axios.post('/api/mcp/request', {
        method: 'get_weather',
        params: { city }
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      // Get current hour in 24-hour format (0-23)
      const currentHour = new Date().getHours();
      // Consider daytime between 6 AM and 8 PM
      const isDaytime = currentHour >= 6 && currentHour < 20;

      // Merge the weather data with local time information
      const weatherWithTime = {
        ...response.data.result,
        isDaytime: isDaytime ? 1 : 0,
        localTime: currentHour
      };

      console.log('Weather response with local time:', weatherWithTime);
      setWeatherData(weatherWithTime);
      setError(null);
    } catch (error) {
      console.error('Weather request failed:', {
        error: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      setError(error.response?.data?.error || error.message);
      setWeatherData(null);
    }
    setLoading(false);
  };

  // Add fallback suggestion generation function
  const generateFallbackSuggestion = (city, weather, hour) => {
    const isDaytime = hour >= 6 && hour < 20;
    const isLateNight = hour < 6 || hour >= 22;
    const isGoodWeather = weather?.weatherCode <= 2;
    
    // Create fallback suggestions based on time and weather
    let suggestions = [];
    
    if (city === 'Stockholm') {
      if (isLateNight) {
        // Only 24/7 places for very late night (after 1 AM)
        const is24HourNeeded = hour > 0 && hour < 6;
        suggestions = [
          {
            name: "Pressbyrån (Centralstation)",
            type: "24/7 Convenience store",
            activity: "Grab a coffee or snack",
            reason: "One of the few places open 24/7 in Stockholm, perfect for late night essentials",
            openingHours: "Open 24 hours",
            address: "Centralstationen, 111 20 Stockholm"
          },
          {
            name: "McDonald's Kungsgatan",
            type: "24/7 Fast food restaurant",
            activity: "Get a late-night meal",
            reason: "Open 24/7 and offers a reliable late-night dining option in central Stockholm",
            openingHours: "Open 24 hours",
            address: "Kungsgatan 4, 111 43 Stockholm"
          },
          {
            name: "7-Eleven (Sergels Torg)",
            type: "24/7 Convenience store",
            activity: "Pick up snacks or a coffee",
            reason: "Centrally located and open 24/7, perfect for night owls and early risers",
            openingHours: "Open 24 hours",
            address: "Sergels Torg, 111 57 Stockholm"
          }
        ];
      } else if (isDaytime && isGoodWeather) {
        suggestions = [
          {
            name: "Djurgården",
            type: "Park/Island",
            activity: "Take a relaxing walk",
            reason: "Beautiful island park with museums and green spaces, perfect for nice weather",
            openingHours: "Open all day",
            address: "Djurgården, Stockholm"
          },
          {
            name: "Gamla Stan",
            type: "Historic district",
            activity: "Explore the old town",
            reason: "Charming historic area with cafes and shops, best enjoyed in good weather",
            openingHours: "Always accessible",
            address: "Gamla Stan, Stockholm"
          }
        ];
      } else {
        suggestions = [
          {
            name: "Fotografiska",
            type: "Photography museum",
            activity: "Enjoy photography exhibitions",
            reason: "Excellent indoor activity with a great café and views",
            openingHours: hour < 23 ? "Open until 23:00" : "Closed at this hour",
            address: "Stadsgårdshamnen 22, 116 45 Stockholm"
          },
          {
            name: "O'Learys Tolv",
            type: "Sports bar",
            activity: "Watch sports and enjoy food and drinks",
            reason: "Popular sports bar with a lively atmosphere",
            openingHours: hour < 22 ? "Open until 22:00" : "Closed at this hour",
            address: "Tolv Stockholm, Arenavägen 69, 121 77 Johanneshov"
          }
        ];
      }
    } else {
      // Default suggestions for any city
      suggestions = [
        {
          name: hour > 0 && hour < 6 ? "24/7 Convenience Store" : "Local Café",
          type: hour > 0 && hour < 6 ? "24-hour store" : "Café",
          activity: hour > 0 && hour < 6 ? "Grab essentials or a quick snack" : "Enjoy a coffee",
          reason: hour > 0 && hour < 6
            ? "One of the few places open at this late hour" 
            : (isGoodWeather ? "Nice spot to enjoy the good weather" : "Cozy place to escape the weather"),
          openingHours: hour > 0 && hour < 6 ? "24 hours" : "8 AM - 8 PM",
          address: `Central area in ${city}`
        }
      ];
    }
    
    // Filter places that are definitely closed at current hour
    const filteredSuggestions = suggestions.filter(place => {
      // Get current minutes for time comparison
      const currentMinutes = new Date().getMinutes();
      
      // Always include 24 hour places
      if (place.openingHours.includes("24 hours") || 
          place.openingHours.includes("Always") ||
          place.openingHours.includes("all day")) {
        return true;
      }
      
      // Remove places explicitly marked as closed
      if (place.openingHours.includes("Closed")) {
        return false;
      }
      
      // Parse closing time for places with hours like "Open until XX:XX"
      if (place.openingHours.includes("until")) {
        const closingMatch = place.openingHours.match(/until (\d+):(\d+)/);
        if (closingMatch) {
          const closingHour = parseInt(closingMatch[1]);
          const closingMinute = parseInt(closingMatch[2]);
          
          // Check if current time is past closing
          if (hour > closingHour || (hour === closingHour && currentMinutes > closingMinute)) {
            return false;
          }
        }
      }
      
      return true;
    });
    
    // If no places are open, default to a 24/7 place
    if (filteredSuggestions.length === 0) {
      return {
        name: "24/7 Convenience Store",
        type: "24-hour store",
        activity: "Grab essentials or a quick snack",
        reason: "One of the few places open at this late hour",
        openingHours: "Open 24 hours",
        address: `Central area in ${city}`
      };
    }
    
    // Pick a random suggestion from the filtered list
    return filteredSuggestions[Math.floor(Math.random() * filteredSuggestions.length)];
  };

  // Update the handleSuggestLocation function to handle OpenAI connection retries
  const handleSuggestLocation = async (refreshParam) => {
    if (!weatherData) return;
    
    setLocationLoading(true);
    setLocationError(null);
    setError(null); // Clear both error states
    
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }

      // Get current time information
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinutes = now.getMinutes();
      const dayOfWeek = now.getDay(); // 0 is Sunday, 1 is Monday, etc.

      // Get user preferences to include in request
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      const userId = decodedToken.userId;

      console.log('Making location suggestion request:', {
        city: weatherData.city,
        weather: weatherData,
        time: { hour: currentHour, minutes: currentMinutes, dayOfWeek },
        userId,
        outingType, // Add outing type to the log
        refreshParam // Add refresh parameter to avoid caching
      });

      let attemptCount = 0;
      const maxAttempts = 2;
      let lastError = null;
      
      while (attemptCount < maxAttempts) {
        attemptCount++;
        
        try {
          console.log(`Attempt ${attemptCount}/${maxAttempts} to get location suggestion`);
          
          const requestParams = {
            city: weatherData.city,
            weather: weatherData,
            time: {
              hour: currentHour,
              minutes: currentMinutes,
              dayOfWeek: dayOfWeek
            },
            userId: userId.toString(), // Ensure userId is a string
            outingType: outingType, // Ensure outing type is included
            refreshParam: refreshParam || Math.random().toString() // Add random parameter to ensure a new suggestion
          };

          console.log('Request parameters:', requestParams); // Add detailed logging
          
          const response = await axios.post('/api/mcp/request', {
            method: 'suggest_location',
            params: requestParams
          }, {
            headers: {
              'Authorization': `Bearer ${token}`
            },
            timeout: attemptCount === 1 ? 20000 : 30000 // Longer timeout for second attempt
          });

          console.log('Location suggestion response:', response.data);
          setLocationData(response.data.result);
          setLocationError(null);
          return; // Success - exit the loop
        } catch (error) {
          lastError = error;
          console.error(`Attempt ${attemptCount} failed:`, error.message);
          
          if (attemptCount < maxAttempts) {
            // Wait before retry
            const retryDelay = 2000; // 2 seconds
            console.log(`Retrying in ${retryDelay}ms...`);
            await new Promise(resolve => setTimeout(resolve, retryDelay));
          }
        }
      }
      
      // If we got here, all attempts failed
      console.error('All attempts to get location suggestion failed:', lastError);
      
      // Use fallback without showing error
      const fallbackSuggestion = generateFallbackSuggestion(
        weatherData.city, 
        weatherData, 
        currentHour
      );
      
      console.log('Generated fallback suggestion:', fallbackSuggestion);
      
      // Add fallback indicator and mock votes
      fallbackSuggestion.isFallback = true;
      fallbackSuggestion.upvotes = 0;
      fallbackSuggestion.downvotes = 0;
      
      setLocationData(fallbackSuggestion);
      // Don't set any error message
      setLocationError(null);
    } catch (error) {
      console.error('Location suggestion completely failed:', error);
      const errorMessage = error.response?.data?.error || error.message || 'An unknown error occurred';
      console.error('Error details:', {
        message: errorMessage,
        status: error.response?.status,
        data: error.response?.data
      });
      setLocationError(errorMessage);
      setError(errorMessage); // Set both error states
      setLocationData(null);
    } finally {
      setLocationLoading(false);
    }
  };

  // Add function to fetch user preferences
  const fetchUserPreferences = async () => {
    setPreferencesLoading(true);
    setPreferencesError(null);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }

      // Get the user ID from the decoded token
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      const userId = decodedToken.userId;

      console.log('Fetching preferences with token:', {
        tokenLength: token.length,
        userId: userId
      });

      const response = await axios.post('/api/mcp/request', {
        method: 'get_user_preferences',
        params: { userId: userId }  // Use the actual user ID from the token
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      setPreferencesData(response.data.result.preferences);
    } catch (error) {
      console.error('Error fetching user preferences:', error);
      setPreferencesError(error.response?.data?.error || error.message);
    } finally {
      setPreferencesLoading(false);
    }
  };

  // Add function to update user preferences
  const updateUserPreferences = async (newPreferences) => {
    setPreferencesLoading(true);
    setPreferencesError(null);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }

      // Get the user ID from the decoded token
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      const userId = decodedToken.userId;

      const response = await axios.post('/api/mcp/request', {
        method: 'update_user_preferences',
        params: {
          userId: userId,  // Use the actual user ID from the token
          preferences: newPreferences
        }
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.data.result.success) {
        setPreferencesData(newPreferences);
        notifications.show({
          title: 'Success',
          message: 'User preferences updated successfully',
          color: 'green'
        });
      }
    } catch (error) {
      console.error('Error updating user preferences:', error);
      setPreferencesError(error.response?.data?.error || error.message);
      notifications.show({
        title: 'Error',
        message: 'Failed to update user preferences',
        color: 'red'
      });
    } finally {
      setPreferencesLoading(false);
    }
  };

  // Add useEffect to fetch preferences when component mounts
  useEffect(() => {
    fetchUserPreferences();
  }, []);

  // Add preferences management section to the MCP test tab
  const renderPreferencesSection = () => {
    return (
      <Box>
        <Stack spacing="md">
          {preferencesLoading ? (
            <LoadingOverlay visible={true} />
          ) : preferencesError ? (
            <Alert color="red" title="Error">
              {preferencesError}
            </Alert>
          ) : preferencesData ? (
            <>
              <Box>
                <Text weight={500} mb={8}>Location Preferences:</Text>
                <Stack spacing="sm">
                  <Group spacing="xl">
                    <Switch
                      label="Prefers Quiet Places"
                      checked={preferencesData.locationPreferences?.prefersQuiet || false}
                      onChange={(event) => handlePreferenceUpdate('quiet', event.currentTarget.checked)}
                      variant="subtle"
                      disabled={switchLoading.quiet || switchLoading.lively}
                    />
                    <Switch
                      label="Prefers Lively Places"
                      checked={preferencesData.locationPreferences?.prefersLively || false}
                      onChange={(event) => handlePreferenceUpdate('lively', event.currentTarget.checked)}
                      variant="subtle"
                      disabled={switchLoading.quiet || switchLoading.lively}
                    />
                  </Group>
                  <Select
                    label="Preferred Cuisine"
                    value={preferencesData.locationPreferences?.preferredCuisine || 'any'}
                    onChange={(value) => updateUserPreferences({
                      ...preferencesData,
                      locationPreferences: {
                        ...preferencesData.locationPreferences,
                        preferredCuisine: value
                      }
                    })}
                    data={[
                      { value: 'japanese', label: 'Japanese' },
                      { value: 'italian', label: 'Italian' },
                      { value: 'swedish', label: 'Swedish' },
                      { value: 'any', label: 'Any Cuisine' }
                    ]}
                    styles={{
                      input: {
                        backgroundColor: 'var(--mantine-color-dark-6)',
                        border: 'none'
                      }
                    }}
                    variant="filled"
                  />
                </Stack>
              </Box>
            </>
          ) : null}
        </Stack>
      </Box>
    );
  };

  // Add new handler for preference updates
  const handlePreferenceUpdate = async (type, checked) => {
    setSwitchLoading(prev => ({ ...prev, [type]: true }));
    try {
      const newPreferences = {
        ...preferencesData,
        locationPreferences: {
          ...preferencesData.locationPreferences,
          [type === 'quiet' ? 'prefersQuiet' : 'prefersLively']: checked,
          [type === 'quiet' ? 'prefersLively' : 'prefersQuiet']: checked ? false : preferencesData.locationPreferences?.[type === 'quiet' ? 'prefersLively' : 'prefersQuiet']
        }
      };

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }

      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      const userId = decodedToken.userId;

      const response = await axios.post('/api/mcp/request', {
        method: 'update_user_preferences',
        params: {
          userId: userId,
          preferences: newPreferences
        }
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.data.result.success) {
        setPreferencesData(newPreferences);
      }
    } catch (error) {
      console.error('Error updating preference:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to update preference',
        color: 'red'
      });
    } finally {
      setSwitchLoading(prev => ({ ...prev, [type]: false }));
    }
  };

  // Add MCP test content renderer
  const renderMCPTest = () => {
    return (
      <Stack spacing="md">
        <Title pl={10} mb={20} order={2}>MCP Test</Title>
        
        <Paper p="md" withBorder>
          <Stack spacing="md">
            <TextInput
              label="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              placeholder="Enter city name"
            />

            <Select
              label="Outing Type"
              placeholder="Select outing type"
              value={outingType}
              onChange={(value) => {
                console.log('Outing type changed to:', value);
                setOutingType(value);
              }}
              data={[
                { value: 'date', label: 'Date' },
                { value: 'casual', label: 'Casual' },
                { value: 'business', label: 'Business' },
                { value: 'family', label: 'Family' },
                { value: 'solo', label: 'Solo' },
                { value: 'group', label: 'Group' }
              ]}
              styles={{
                input: {
                  backgroundColor: 'var(--mantine-color-dark-6)',
                  border: 'none'
                }
              }}
              variant="filled"
            />

            {renderPreferencesSection()}
            
            <Group>
              <Button onClick={() => handleGetWeather('refresh_' + Math.random())} loading={loading} variant="subtle">
                Get Weather
              </Button>
              <Button onClick={() => handleSuggestLocation('refresh_' + Math.random())} loading={locationLoading} disabled={!weatherData} variant="subtle">
                Suggest Location
              </Button>
            </Group>

            {locationError && (
              <Alert color="red" title="Error">
                {locationError}
              </Alert>
            )}

            {weatherData && (
              <Paper p="md" withBorder>
                <Title order={3}>Weather Data</Title>
                <Code block>{JSON.stringify(weatherData, null, 2)}</Code>
              </Paper>
            )}

            {locationData && renderLocationSuggestion()}
          </Stack>
        </Paper>
      </Stack>
    );
  };
  
  // Add a function to check if a location is currently open
  const isLocationOpen = (openingHours) => {
    // Get current time
    const now = new Date();
    const currentHour = now.getHours();
    const currentMinutes = now.getMinutes();
    const currentDay = now.getDay(); // 0 = Sunday, 1 = Monday, etc.
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const currentDayName = dayNames[currentDay];
    
    console.log('Checking if location is open:', {
      openingHours,
      currentHour,
      currentMinutes,
      currentDay,
      currentDayName
    });
    
    // Always consider 24-hour places as open
    if (openingHours.includes("24 hours") || 
        openingHours.toLowerCase().includes("open 24") ||
        openingHours.includes("all day")) {
      return true;
    }
    
    // Handle day-specific format like "Sunday 18:00 - 05:00"
    const daySpecificMatch = openingHours.match(/(\w+)\s+(\d+):(\d+)\s*-\s*(\d+):(\d+)/);
    if (daySpecificMatch) {
      const dayName = daySpecificMatch[1];
      const openHour = parseInt(daySpecificMatch[2]);
      const openMinute = parseInt(daySpecificMatch[3]);
      const closeHour = parseInt(daySpecificMatch[4]);
      const closeMinute = parseInt(daySpecificMatch[5]);
      
      console.log('Parsed day-specific hours:', {
        dayName,
        openHour,
        openMinute,
        closeHour,
        closeMinute
      });
      
      // Check if we're on the specified day
      const isMatchingDay = currentDayName === dayName;
      // Or if we're on the next day for after-midnight hours
      const isPreviousDay = currentDayName === dayNames[(dayNames.indexOf(dayName) + 1) % 7];
      
      // Convert times to minutes since midnight
      const currentTimeInMinutes = currentHour * 60 + currentMinutes;
      const openTimeInMinutes = openHour * 60 + openMinute;
      const closeTimeInMinutes = closeHour * 60 + closeMinute;
      
      // If closing time is less than opening time, it means it extends to the next day
      const isOvernightHours = closeHour < openHour;
      
      console.log('Day-specific time check:', {
        isMatchingDay,
        isPreviousDay,
        isOvernightHours,
        currentTimeInMinutes,
        openTimeInMinutes,
        closeTimeInMinutes
      });
      
      if (isOvernightHours) {
        // Case 1: Same day before midnight
        if (isMatchingDay && currentTimeInMinutes >= openTimeInMinutes) {
          return true;
        }
        
        // Case 2: Next day after midnight, before closing
        if (isPreviousDay && currentTimeInMinutes < closeTimeInMinutes) {
          return true;
        }
      } else {
        // Regular hours within the same day
        if (isMatchingDay && currentTimeInMinutes >= openTimeInMinutes && currentTimeInMinutes < closeTimeInMinutes) {
          return true;
        }
      }
      
      return false;
    }
    
    // Check for standard format like "17:00 - 02:00" or "12:00-05:00"
    const timeRangeMatch = openingHours.match(/(\d+):(\d+)\s*-\s*(\d+):(\d+)/);
    if (timeRangeMatch) {
      const openHour = parseInt(timeRangeMatch[1]);
      const openMinute = parseInt(timeRangeMatch[2]);
      const closeHour = parseInt(timeRangeMatch[3]);
      const closeMinute = parseInt(timeRangeMatch[4]);
      
      console.log('Parsed standard hours:', {
        openHour,
        openMinute,
        closeHour,
        closeMinute
      });
      
      // Convert current time to minutes since midnight for easy comparison
      const currentTimeInMinutes = currentHour * 60 + currentMinutes;
      const openTimeInMinutes = openHour * 60 + openMinute;
      const closeTimeInMinutes = closeHour * 60 + closeMinute;
      
      // If closing time is less than opening time, it means it extends to the next day
      const isOvernightHours = closeHour < openHour;
      
      console.log('Standard time check:', {
        isOvernightHours,
        currentTimeInMinutes,
        openTimeInMinutes,
        closeTimeInMinutes
      });
      
      if (isOvernightHours) {
        // Case 1: Current time is after opening hour but before midnight
        if (currentTimeInMinutes >= openTimeInMinutes) {
          return true;
        }
        
        // Case 2: Current time is after midnight but before closing hour
        if (currentTimeInMinutes < closeTimeInMinutes) {
          return true;
        }
      } else {
        // Regular hours within the same day
        if (currentTimeInMinutes >= openTimeInMinutes && currentTimeInMinutes < closeTimeInMinutes) {
          return true;
        }
      }
      
      return false;
    }
    
    // Default to open if we can't parse the hours
    console.log('Could not parse opening hours, defaulting to open');
    return true;
  };

  const renderLocationSuggestion = () => {
    if (!locationData) return null;

    console.log('Rendering location suggestion with data:', {
      hasPhotoUrl: !!locationData.photoUrl,
      photoUrl: locationData.photoUrl,
      name: locationData.name,
      type: locationData.type,
      address: locationData.address,
      openingHours: locationData.openingHours
    });

    return (
      <Paper p="md" withBorder>
        <Title order={3}>Location Suggestion</Title>
        
        <Box mb={16}>
          <Text weight={700} size="lg" mb={4}>Reason:</Text>
          <Text>{locationData.reason}</Text>
        </Box>
        <Stack spacing="sm">
          {locationData.photoUrl && (
            <Box>
              <div style={{ 
                position: 'relative',
                width: '100%',
                paddingBottom: '56.25%', // 16:9 aspect ratio
                backgroundColor: 'var(--mantine-color-dark-6)',
                borderRadius: '8px',
                overflow: 'hidden',
                marginBottom: '16px'
              }}>
                <img 
                  src={locationData.photoUrl} 
                  alt={locationData.name}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '8px'
                  }}
                  onLoad={() => {
                    console.log('Image loaded successfully:', locationData.photoUrl);
                  }}
                  onError={(e) => {
                    console.error('Failed to load image:', {
                      url: locationData.photoUrl,
                      error: e,
                      name: locationData.name
                    });
                    e.target.style.display = 'none';
                    e.target.parentElement.innerHTML = `
                      <div style="
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        color: var(--mantine-color-gray-4);
                        text-align: center;
                        padding: 20px;
                      ">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                          <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                          <circle cx="8.5" cy="8.5" r="1.5"></circle>
                          <polyline points="21 15 16 10 5 21"></polyline>
                        </svg>
                        <p style="margin-top: 10px;">Image not available</p>
                      </div>
                    `;
                  }}
                />
              </div>
            </Box>
          )}
          <Box>
            <Text weight={500}>Name:</Text>
            <Text>{locationData.name}</Text>
          </Box>
          <Box>
            <Text weight={500}>Address:</Text>
            <Text>{locationData.address}</Text>
          </Box>
          {locationData.googlePlaceId && (
            <Box>
              <Text weight={500}>Google Places ID:</Text>
              <Text>{locationData.googlePlaceId}</Text>
            </Box>
          )}
          <Box>
            <Text weight={500}>Type:</Text>
            <Text>{locationData.type}</Text>
          </Box>
          <Box>
            <Text weight={500}>Suggested Activity:</Text>
            <Text>{locationData.activity}</Text>
          </Box>
          <Box>
            <Text weight={500}>Opening Hours:</Text>
            <Text>{locationData.openingHours}</Text>
          </Box>
          
          <Group spacing="sm">
            <Button 
              variant="subtle" 
              color="blue" 
              leftIcon={<IconThumbUp size={16} />}
              onClick={() => handleVote('up')}
              disabled={voteLoading}
            >
              Upvote ({locationData.upvotes || 0})
            </Button>
            <Button 
              variant="subtle" 
              color="red" 
              leftIcon={<IconThumbDown size={16} />}
              onClick={() => handleVote('down')}
              disabled={voteLoading}
            >
              Downvote ({locationData.downvotes || 0})
            </Button>
            <Button 
              variant="subtle"
              color="gray"
              leftIcon={<IconRefresh size={16} />}
              onClick={() => handleSuggestLocation('refresh_' + Math.random())}
              disabled={locationLoading || !weatherData}
            >
              New Suggestion
            </Button>
          </Group>

          {locationData.googleMapsUrl && (
            <Button
              component="a"
              href={locationData.googleMapsUrl}
              target="_blank"
              rel="noopener noreferrer"
              variant="subtle"
              color="blue"
              fullWidth
              mt="sm"
            >
              View on Google Maps
            </Button>
          )}
        </Stack>
      </Paper>
    );
  };

  const handleVote = async (voteType) => {
    if (!locationData?.id) {
      notifications.show({
        title: 'Error',
        message: 'Cannot vote on this suggestion as it was not saved',
        color: 'red'
      });
      return;
    }

    setVoteLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }

      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      const userId = decodedToken.userId;

      const response = await axios.post('/api/mcp/request', {
        method: 'vote_suggestion',
        params: {
          suggestionId: locationData.id,
          userId,
          voteType
        }
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.data.result.success) {
        setLocationData({
          ...locationData,
          upvotes: response.data.result.upvotes,
          downvotes: response.data.result.downvotes
        });

        notifications.show({
          title: 'Success',
          message: `Successfully ${voteType}voted the suggestion`,
          color: 'green'
        });
      }
    } catch (error) {
      console.error('Error voting:', error);
      notifications.show({
        title: 'Error',
        message: error.response?.data?.error || 'Failed to process vote',
        color: 'red'
      });
    } finally {
      setVoteLoading(false);
    }
  };
  
  return (
    <Paper 
      p="md" 
      shadow="xs" 
      className="admin-dashboard-container"
      style={{ 
        width: '100%',
        maxWidth: '100%', 
        margin: '0 auto', 
        padding: '0',
        boxSizing: 'border-box',
        position: 'relative',
        zIndex: 1,
      }}>
      <Title order={2} pl={20} mb={20}>Admin Dashboard</Title>
      
      <Tabs value={activeTab} onChange={setActiveTab} mt="md">
        <Tabs.List>
          <Tabs.Tab value="users">User Management</Tabs.Tab>
          <Tabs.Tab value="articles">Article Management</Tabs.Tab>
          <Tabs.Tab value="waitlist">Waitlist Management</Tabs.Tab>
          <Tabs.Tab value="horoscopes">Generated Horoscopes</Tabs.Tab>
          <Tabs.Tab value="article-generator">AI Article Generator</Tabs.Tab>
          <Tabs.Tab value="statistics">Statistics</Tabs.Tab>
          <Tabs.Tab value="mcp-test" variant="subtle">MCP Test</Tabs.Tab>
        </Tabs.List>
        
        <Tabs.Panel value="users" pt="md">
          {renderUserManagement()}
        </Tabs.Panel>

        <Tabs.Panel value="articles" pt="md">
          <ArticleManagement 
            onEdit={handleEditArticle}
            onCreateNew={handleCreateArticle}
          />
        </Tabs.Panel>

        <Tabs.Panel value="waitlist" pt="md">
          <AdminWaitlist />
        </Tabs.Panel>

        <Tabs.Panel value="horoscopes" pt="md">
          <HoroscopeGenerator />
        </Tabs.Panel>

        <Tabs.Panel value="article-generator" pt="md">
          <ArticleGenerator articleId={editingArticleId} />
        </Tabs.Panel>

        <Tabs.Panel value="statistics" pt="md">
          <SiteStatistics />
        </Tabs.Panel>

        <Tabs.Panel value="mcp-test" pt="md">
          {renderMCPTest()}
        </Tabs.Panel>
      </Tabs>

      {/* User Edit Modal */}
      <Modal
        opened={modalOpen}
        onClose={() => setModalOpen(false)}
        title="Edit User Role"
        size="sm"
      >
        <Box>
          <Select
            label="Role"
            placeholder="Select role"
            data={[
              { value: 'user', label: 'User' },
              { value: 'moderator', label: 'Moderator' },
              { value: 'admin', label: 'Admin' }
            ]}
            value={currentUser?.role}
            onChange={(value) => setCurrentUser(prev => ({ ...prev, role: value }))}
          />
          <Group position="right" mt="md">
            <Button variant="subtle" onClick={() => setModalOpen(false)}>
              Cancel
            </Button>
            <Button onClick={handleRoleUpdate}>
              Update Role
            </Button>
          </Group>
        </Box>
      </Modal>
    </Paper>
  );
}

export default AdminDashboard; 