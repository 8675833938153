// src/axiosConfig.js
import axios from 'axios';

const baseURL = process.env.NODE_ENV === 'production' 
  ? 'https://api.fateoracle.com'
  : 'http://localhost:10000';

// Initialize axios instance
const instance = axios.create({
  baseURL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  }
});

// Initialize with token if available
const token = localStorage.getItem('token');
if (token) {
  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  console.log('Initial axios config: token found and applied');
} else {
  console.log('Initial axios config: no token available');
}

// Add request interceptor to add authorization on every request
instance.interceptors.request.use(
  (config) => {
    // Check for token again on each request
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      console.log(`Request with auth: ${config.method?.toUpperCase()} ${config.url}`);
    } else {
      console.log(`Request without auth: ${config.method?.toUpperCase()} ${config.url}`);
    }
    
    // Ensure all URLs have /api prefix unless they're absolute URLs or already have /api
    if (config.url && 
        !config.url.startsWith('http') && 
        !config.url.startsWith('/api/') && 
        !config.url.startsWith('api/')) {
      // Remove any leading slash and add /api/ prefix
      const cleanUrl = config.url.startsWith('/') ? config.url.substring(1) : config.url;
      config.url = `/api/${cleanUrl}`;
      console.log(`Added /api prefix to URL: ${config.url}`);
    }
    
    // Log full request for debugging
    console.log('Full request details:', {
      method: config.method?.toUpperCase(),
      url: config.url,
      baseURL: config.baseURL,
      fullUrl: `${config.baseURL}${config.url}`,
      headers: config.headers
    });
    
    return config;
  },
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

// Add response interceptor for better error handling and logging
instance.interceptors.response.use(
  (response) => {
    if (process.env.NODE_ENV === 'development') {
      console.log('Response:', {
        status: response.status,
        url: response.config.url,
        method: response.config.method?.toUpperCase(),
      });
    }
    return response;
  },
  (error) => {
    console.error('Request failed:', {
      url: error.config?.url,
      method: error.config?.method?.toUpperCase(),
      status: error.response?.status,
      message: error.response?.data?.message || error.message
    });
    
    // Handle authentication errors (except for public endpoints)
    if (error.response?.status === 401) {
      // Clear token if it's invalid/expired
      if (!error.config?.url.includes('/api/auth/login')) {
        console.log('Authentication error detected, clearing session');
        localStorage.removeItem('token');
        delete instance.defaults.headers.common['Authorization'];
      }
    }
    
    return Promise.reject(error);
  }
);

// Handle public endpoints silently
instance.interceptors.response.use(
  response => response,
  error => {
    const publicEndpoints = [
      '/api/collective-horoscopes',
      '/api/articles'
      // Add other public endpoints here
    ];
    
    // Don't show errors for public endpoints on auth failure
    if (error.response?.status === 401 && 
        publicEndpoints.some(endpoint => error.config.url.includes(endpoint))) {
      console.log('Silent auth error for public endpoint:', error.config.url);
      return Promise.resolve({ data: [] }); // Return empty data instead of error
    }
    
    // Handle 404 errors for missing /api prefix
    if (error.response?.status === 404) {
      const originalRequest = error.config;
      const originalUrl = originalRequest.url || '';
      
      // Check if the URL doesn't have the /api prefix but is a relative URL
      if (!originalUrl.includes('/api/') && 
          !originalUrl.startsWith('http') && 
          !originalRequest._isRetry) {
        
        console.log('Retrying 404 request with /api prefix:', originalUrl);
        
        // Mark this request as retried to prevent infinite loops
        originalRequest._isRetry = true;
        
        // Add /api prefix
        const cleanUrl = originalUrl.startsWith('/') ? originalUrl.substring(1) : originalUrl;
        originalRequest.url = `/api/${cleanUrl}`;
        
        return instance(originalRequest);
      }
    }
    
    return Promise.reject(error);
  }
);

export default instance;