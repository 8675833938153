import axios from '../axiosConfig';

let refreshPromise = null;

export const refreshToken = async () => {
  try {
    // If there's already a refresh in progress, return that promise
    if (refreshPromise) {
      return refreshPromise;
    }

    // Create new refresh promise
    refreshPromise = (async () => {
      const currentToken = localStorage.getItem('token');
      
      if (!currentToken) {
        throw new Error('No token available to refresh');
      }

      const response = await axios.post('/api/auth/refresh', {
        token: currentToken
      });

      if (response.data && response.data.token) {
        localStorage.setItem('token', response.data.token);
        // Update axios default headers
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
        return response.data.token;
      } else {
        throw new Error('Invalid refresh response');
      }
    })();

    // Wait for the refresh to complete
    const result = await refreshPromise;
    
    // Clear the promise
    refreshPromise = null;
    
    return result;
  } catch (error) {
    // Clear the promise on error
    refreshPromise = null;
    throw error;
  }
};

export const isTokenExpired = (token) => {
  if (!token) return true;
  
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => 
      '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    ).join(''));

    const { exp } = JSON.parse(jsonPayload);
    return Date.now() >= exp * 1000;
  } catch (error) {
    console.error('Error checking token expiration:', error);
    return true;
  }
};

export const getTokenExpirationTime = (token) => {
  if (!token) return 0;
  
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => 
      '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    ).join(''));

    const { exp } = JSON.parse(jsonPayload);
    return exp * 1000;
  } catch (error) {
    console.error('Error getting token expiration:', error);
    return 0;
  }
}; 