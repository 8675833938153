import React, { useState, useEffect } from 'react';
import { 
  Paper, 
  Title, 
  Text, 
  Box,
  Button,
  Group,
  LoadingOverlay,
  Select,
  Indicator,
  Tabs,
  Badge,
  Tooltip
} from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { notifications } from '@mantine/notifications';
import { IconCalendar, IconRefresh } from '@tabler/icons-react';
import axios from '../axiosConfig';
import '../styles/horoscope.css';
import { formatHoroscopeDate } from '../utils/dateUtils';

function HoroscopeGenerator() {
  // Horoscope Calendar and Viewing State
  const [generatedHoroscopes, setGeneratedHoroscopes] = useState({});
  const [activeHoroscopeType, setActiveHoroscopeType] = useState(null);
  const [loadingHoroscopes, setLoadingHoroscopes] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [datePickerOpened, setDatePickerOpened] = useState(false);
  const [typeToGenerate, setTypeToGenerate] = useState('collective_daily');
  const [populatedDates, setPopulatedDates] = useState([]);

  const horoscopeTypes = [
    { value: 'collective_daily', label: 'Daily Collective' },
    { value: 'collective_weekly', label: 'Weekly Collective' },
    { value: 'collective_monthly', label: 'Monthly Collective' },
    { value: 'politics', label: 'Politics' },
    { value: 'warnings', label: 'Warnings' },
    { value: 'opportunities', label: 'Opportunities' },
    { value: 'travel', label: 'Travel' }
  ];

  // Load horoscopes when component mounts
  useEffect(() => {
    fetchHoroscopesForDate(selectedDate);
    fetchPopulatedDates();
  }, []);

  // Format horoscope type for display
  const formatHoroscopeType = (type) => {
    switch (type) {
      case 'collective_daily':
        return 'Daily';
      case 'collective_weekly':
        return 'Weekly Collective';
      case 'collective_monthly':
        return 'Monthly Collective';
      case 'politics':
        return 'Politics';
      case 'warnings':
        return 'Warnings';
      case 'opportunities':
        return 'Opportunities';
      case 'travel':
        return 'Travel';
      default:
        return type.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
    }
  };

  // Fetch dates with generated horoscopes
  const fetchPopulatedDates = async (month = new Date()) => {
    try {
      const startDate = new Date(month.getFullYear(), month.getMonth(), 1);
      const endDate = new Date(month.getFullYear(), month.getMonth() + 1, 0);
      
      // Ensure we're using UTC dates
      const utcStartDate = new Date(Date.UTC(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        0, 0, 0, 0
      ));
      
      const utcEndDate = new Date(Date.UTC(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate(),
        23, 59, 59, 999
      ));

      // Use the populated-dates endpoint
      const response = await axios.get('/api/collective-horoscopes/populated-dates', {
        params: {
          startDate: utcStartDate.toISOString(),
          endDate: utcEndDate.toISOString()
        }
      });

      if (response.data && Array.isArray(response.data)) {
        setPopulatedDates(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch populated dates:', error);
      setPopulatedDates([]);
    }
  };

  // Function to check if a date has data and get its types
  const getDateData = (date) => {
    return populatedDates.find(pd => {
      const pdDate = new Date(pd.date);
      return pdDate.getDate() === date.getDate() &&
             pdDate.getMonth() === date.getMonth() &&
             pdDate.getFullYear() === date.getFullYear();
    });
  };

  // Get props for date picker to highlight dates with data
  const getDayProps = (date) => {
    const dateData = getDateData(date);
    if (!dateData) return {};

    const tooltipContent = (
      <div>
        <Text size="sm" weight={500} mb={5}>Available Horoscopes:</Text>
        {dateData.types.map((type, index) => (
          <Text key={type} size="sm" color="dimmed">
            • {formatHoroscopeType(type)}
          </Text>
        ))}
      </div>
    );

    return {
      style: {
        backgroundColor: '#e9ecef',
        color: '#1a1b1e',
        position: 'relative',
        cursor: 'pointer'
      },
      sx: (theme) => ({
        '&:hover': {
          backgroundColor: theme.fn.lighten('#e9ecef', 0.1),
        },
        '&::after': {
          content: '""',
          display: 'block',
          width: '4px',
          height: '4px',
          borderRadius: '50%',
          backgroundColor: theme.colors.violet[6],
          position: 'absolute',
          bottom: 4,
          left: '50%',
          transform: 'translateX(-50%)'
        }
      }),
      component: (props) => (
        <Tooltip
          label={tooltipContent}
          position="bottom"
          withArrow
          multiline
          width={200}
        >
          <div {...props} />
        </Tooltip>
      )
    };
  };

  // Handle date change
  const handleDateChange = (date) => {
    setSelectedDate(date);
    fetchHoroscopesForDate(date);
  };

  // Fetch horoscopes for a specific date
  const fetchHoroscopesForDate = async (date) => {
    try {
      setLoadingHoroscopes(true);
      
      // Ensure we're using UTC date
      const utcDate = new Date(Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        0, 0, 0, 0
      ));
      
      // Fetch horoscopes for each type
      const horoscopesByType = {};
      for (const type of horoscopeTypes) {
        try {
          const response = await axios.get('/api/collective-horoscopes', {
            params: {
              type: type.value,
              date: utcDate.toISOString()
            }
          });

          if (response.data && Array.isArray(response.data) && response.data.length > 0) {
            horoscopesByType[type.value] = response.data[0];
          }
        } catch (error) {
          console.error(`Failed to fetch horoscope for type ${type.value}:`, error);
        }
      }

      console.log('All horoscopes:', horoscopesByType);
      setGeneratedHoroscopes(horoscopesByType);
      
      const foundTypes = Object.keys(horoscopesByType);
      if (foundTypes.length > 0) {
        setActiveHoroscopeType(foundTypes[0]);
      } else {
        setActiveHoroscopeType(null);
      }
    } catch (error) {
      console.error('Failed to fetch horoscopes:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch horoscopes for selected date',
        color: 'red'
      });
    } finally {
      setLoadingHoroscopes(false);
    }
  };

  // Handle horoscope generation
  const handleGenerateHoroscope = async () => {
    if (!typeToGenerate) {
      notifications.show({
        title: 'Error',
        message: 'Please select a horoscope type to generate',
        color: 'red'
      });
      return;
    }
    
    setIsGenerating(true);
    try {
      const response = await axios.post('/api/collective-horoscopes/generate', {
        type: typeToGenerate,
        date: selectedDate.toISOString().split('T')[0]
      });
      
      if (response.data) {
        setGeneratedHoroscopes(prev => ({
          ...prev,
          [typeToGenerate]: response.data
        }));
        setActiveHoroscopeType(typeToGenerate);
        notifications.show({
          title: 'Success',
          message: 'Horoscope generated successfully',
          color: 'green'
        });
      }
    } catch (error) {
      console.error('Failed to generate horoscope:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to generate horoscope: ' + (error.response?.data?.message || error.message),
        color: 'red'
      });
    } finally {
      setIsGenerating(false);
    }
  };

  const formatChatbotResponse = (response) => {
    if (!response) return null;
    
    try {
      // Add note about weighted sorting if present
      let formattedSections = [];
      if (response.includes('aspects') || response.includes('fundamentals')) {
        formattedSections.push(
          <div key="weighted-note" style={{ marginBottom: '16px', fontStyle: 'italic', fontSize: '14px' }}>
            The following astrological elements are sorted by significance, with the most influential aspects and fundamentals listed first.
          </div>
        );
      }

      // Split into sections if present
      const sections = response.split(/\[.*?\]/).filter(Boolean);
      const headers = response.match(/\[.*?\]/g) || [];
      
      if (sections.length > 1) {
        // Format each section with its header
        sections.forEach((section, index) => {
          const header = headers[index];
          const content = section.trim();
          
          if (header) {
            // Remove square brackets and format as Title
            const cleanHeader = header.replace(/[\[\]]/g, '');
            formattedSections.push(
              <Title key={`header-${index}`} order={4} mt={24} mb={16} color="indigo">
                {cleanHeader}
              </Title>
            );
          }
          
          // Handle fundamentals sections with special formatting
          if (content.includes('Elements:') || content.includes('Modalities:') || content.includes('Energies:')) {
            const parts = content.split(/(?=Elements:|Modalities:|Energies:)/);
            parts.forEach((part, partIndex) => {
              if (part.trim()) {
                const [subHeader, ...details] = part.split(':');
                formattedSections.push(
                  <div key={`fundamental-${index}-${partIndex}`} style={{ marginBottom: '12px' }}>
                    <span style={{ fontWeight: 700, color: '#4c6ef5' }}>{subHeader}:</span>
                    <span>{details.join(':')}</span>
                  </div>
                );
              }
            });
          } else {
            // Handle regular content and aspects
            const lines = content.split('\n').filter(line => line.trim());
            lines.forEach((line, lineIndex) => {
              if (line.includes(':')) {
                const [key, ...valueParts] = line.split(':');
                const value = valueParts.join(':').trim();
                formattedSections.push(
                  <div key={`line-${index}-${lineIndex}`} style={{ marginBottom: '12px' }}>
                    <span style={{ fontWeight: 700, color: '#4c6ef5' }}>{key}:</span>
                    <span>{value}</span>
                  </div>
                );
              } else {
                formattedSections.push(
                  <div key={`line-${index}-${lineIndex}`} style={{ marginBottom: '12px' }}>
                    {line}
                  </div>
                );
              }
            });
          }
        });
        
        return formattedSections;
      }
      
      // If no sections, format as regular text
      return response.split('\n')
        .filter(p => {
          const trimmed = p.trim();
          return trimmed !== '' && !trimmed.match(/\d+%/);
        })
        .map((paragraph, index) => (
          <Text key={`para-${index}`} mb={12}>
            {paragraph}
          </Text>
        ));
    } catch (error) {
      console.error('Error formatting horoscope text:', error);
      return <div style={{ color: 'red' }}>Error formatting horoscope text</div>;
    }
  };

  return (
    <div className="horoscope-content">
      <Title className="horoscope-main-title" order={3}>
        Horoscope Generator
      </Title>
      <Text className="horoscope-date">
        {formatHoroscopeDate(new Date())}
      </Text>
      
      <Paper shadow="xs" p="md" mt="xl" withBorder>
        <Title order={3} mb="md">Horoscope Calendar</Title>
        <Text size="sm" color="dimmed" mb="lg">View available horoscopes by date</Text>
        
        <Paper shadow="sm" p="md" style={{ 
          backgroundColor: 'white',
          borderRadius: '8px',
          width: '100%'
        }}>
          <DatePicker
            value={selectedDate}
            onChange={handleDateChange}
            getDayProps={getDayProps}
            onMonthChange={(newMonth) => {
              fetchPopulatedDates(newMonth);
            }}
            size="xl"
            styles={{
              calendarBase: {
                width: '100%'
              },
              calendarHeader: {
                backgroundColor: 'transparent',
                padding: '10px',
                fontSize: '18px'
              },
              monthCell: {
                fontSize: '18px'
              },
              weekday: {
                fontSize: '14px',
                padding: '10px',
                color: '#666',
                textAlign: 'center',
                width: 'calc(100% / 7)'
              },
              weekdaysRow: {
                display: 'flex',
                width: '100%'
              },
              month: {
                width: '100%'
              },
              monthRow: {
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between'
              },
              monthCell: {
                width: 'calc(100% / 7)',
                aspectRatio: '1'
              },
              day: {
                width: '100%',
                height: '100%',
                fontSize: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '4px',
                margin: '2px',
                '&[data-selected]': {
                  backgroundColor: '#7950f2',
                  '&:hover': {
                    backgroundColor: '#6741d9'
                  }
                }
              }
            }}
          />
        </Paper>
        
        <Text size="sm" color="dimmed" mt="lg" style={{ display: 'flex', alignItems: 'center' }}>
          <Box component="span" 
            style={{ 
              width: '8px', 
              height: '8px', 
              borderRadius: '50%', 
              backgroundColor: '#7950f2', 
              display: 'inline-block',
              marginRight: '8px'
            }} 
          />
          Dates with available horoscopes
        </Text>
      </Paper>

      <Paper shadow="xs" p="md" mt="xl" withBorder>
        <Title order={3} mb="md">Generate Horoscope</Title>
        <Text size="sm" color="dimmed" mb="lg">Select a type and generate a new horoscope</Text>
        
        <Group position="apart" mb="xl">
          <Select
            label="Horoscope Type"
            placeholder="Select type"
            data={horoscopeTypes}
            value={typeToGenerate}
            onChange={setTypeToGenerate}
            style={{ flex: 1 }}
          />
          
          <Button 
            leftIcon={<IconRefresh size={16} />}
            onClick={handleGenerateHoroscope}
            loading={isGenerating}
            mt={24}
          >
            Generate
          </Button>
        </Group>

        {Object.keys(generatedHoroscopes).length > 0 ? (
          <Tabs value={activeHoroscopeType} onChange={setActiveHoroscopeType}>
            <Tabs.List>
              {Object.keys(generatedHoroscopes).map(type => (
                <Tabs.Tab 
                  key={type} 
                  value={type}
                  rightSection={
                    <Badge 
                      size="sm" 
                      variant="light"
                      style={{ marginLeft: 10 }}
                    >
                      {formatHoroscopeType(type)}
                    </Badge>
                  }
                >
                  {formatHoroscopeType(type)}
                </Tabs.Tab>
              ))}
            </Tabs.List>
            
            {Object.entries(generatedHoroscopes).map(([type, data]) => (
              <Tabs.Panel key={type} value={type} pt="xs">
                <Paper p="md" withBorder>
                  <Box>
                    <Text size="sm" color="dimmed" mb="md">
                      Generated on {formatHoroscopeDate(data.created_at)}
                    </Text>
                    {formatChatbotResponse(data.chatbot_response || data.content)}
                  </Box>
                </Paper>
              </Tabs.Panel>
            ))}
          </Tabs>
        ) : (
          <Text fw={700} ta="center" fz="lg" mt={40} mb={40}>
            No horoscopes found for {formatHoroscopeDate(selectedDate)}. Use the Generate button to create new horoscopes.
          </Text>
        )}
      </Paper>
    </div>
  );
}

export default HoroscopeGenerator; 