import React, { useState, useEffect } from 'react';
import {
  Box,
  Title,
  Table,
  Badge,
  Group,
  Text,
  Button,
  Modal,
  TextInput,
  Select,
  Textarea,
  Alert,
  Loader,
  ActionIcon,
  Paper,
} from '@mantine/core';
import { IconAlertCircle, IconEdit, IconCheck, IconX } from '@tabler/icons-react';
import axios from '../axiosConfig';
import '../styles/admin-dashboard.css';

function AdminWaitlist() {
  const [waitlistEntries, setWaitlistEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    status: '',
    notes: '',
  });

  useEffect(() => {
    fetchWaitlistEntries();
  }, []);

  const fetchWaitlistEntries = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/api/waitlist');
      setWaitlistEntries(response.data);
      setError(null);
    } catch (err) {
      setError('Failed to load waitlist entries');
      console.error('Error fetching waitlist:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (entry) => {
    setSelectedEntry(entry);
    setFormData({
      status: entry.status,
      notes: entry.notes || '',
    });
    setEditModalOpen(true);
  };

  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await axios.put(`/api/waitlist/${selectedEntry.id}`, formData);
      setEditModalOpen(false);
      fetchWaitlistEntries(); // Refresh the list
    } catch (err) {
      setError('Failed to update waitlist entry');
      console.error('Error updating waitlist entry:', err);
    } finally {
      setLoading(false);
    }
  };

  const getStatusBadge = (status) => {
    switch (status) {
      case 'pending':
        return <Badge color="yellow">Pending</Badge>;
      case 'approved':
        return <Badge color="green">Approved</Badge>;
      case 'rejected':
        return <Badge color="red">Rejected</Badge>;
      default:
        return <Badge color="gray">Unknown</Badge>;
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  if (loading && waitlistEntries.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
        <Loader size="lg" />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert icon={<IconAlertCircle size={16} />} title="Error" color="red">
        {error}
      </Alert>
    );
  }

  return (
    <Box className="admin-dashboard-container">
      <Title order={2} pl={10} mb={20}>Waitlist Management</Title>

      {waitlistEntries.length === 0 ? (
        <Text>No waitlist entries found.</Text>
      ) : (
        <div className="admin-table-container">
          <Table 
            className="admin-table"
            style={{ 
              width: '100%',
              tableLayout: 'auto',
              borderCollapse: 'collapse'
            }}
            sx={(theme) => ({
              'th, td': {
                padding: '12px 16px',
                borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]}`,
                fontSize: theme.fontSizes.sm,
                textAlign: 'left'
              },
              'th': {
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                fontWeight: 600,
                textAlign: 'left'
              }
            })}>
            <thead>
              <tr>
                <th style={{ width: '15%', textAlign: 'left' }}>Name</th>
                <th style={{ width: '25%', textAlign: 'left' }}>Email</th>
                <th style={{ width: '10%', textAlign: 'left' }}>Status</th>
                <th style={{ width: '20%', textAlign: 'left' }}>Date Added</th>
                <th style={{ width: '25%', textAlign: 'left' }}>Notes</th>
                <th style={{ width: '5%', textAlign: 'left' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {waitlistEntries.map((entry) => (
                <tr key={entry.id}>
                  <td>{entry.name || 'N/A'}</td>
                  <td>{entry.email}</td>
                  <td>{getStatusBadge(entry.status)}</td>
                  <td>{formatDate(entry.created_at)}</td>
                  <td>{entry.notes || 'N/A'}</td>
                  <td>
                    <Group spacing="xs">
                      <ActionIcon 
                        variant="subtle" 
                        
                        onClick={() => handleEditClick(entry)}
                        sx={(theme) => ({
                          '&:hover': {
                            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[1]
                          }
                        })}
                      >
                        <IconEdit size={16} />
                      </ActionIcon>
                    </Group>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      <Modal
        opened={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        title="Edit Waitlist Entry"
      >
        <Box>
          <Text weight={500} mb="xs">Email: {selectedEntry?.email}</Text>
          
          <Select
            label="Status"
            data={[
              { value: 'pending', label: 'Pending' },
              { value: 'approved', label: 'Approved' },
              { value: 'rejected', label: 'Rejected' },
            ]}
            value={formData.status}
            onChange={(value) => handleInputChange('status', value)}
            mb="md"
          />
          
          <Textarea
            label="Notes"
            placeholder="Add notes about this waitlist entry"
            value={formData.notes}
            onChange={(e) => handleInputChange('notes', e.target.value)}
            mb="md"
          />
          
          <Group position="right">
            <Button variant="outline" onClick={() => setEditModalOpen(false)}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} loading={loading}>
              Save Changes
            </Button>
          </Group>
        </Box>
      </Modal>
    </Box>
  );
}

export default AdminWaitlist; 