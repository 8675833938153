import React, { useState, useEffect, useRef, useCallback, forwardRef } from 'react';
import { 
  AppShell, 
  Navbar,
  Header, 
  Button, 
  Group, 
  Text,
  Menu,
  Stack,
  ThemeIcon,
  UnstyledButton,
  Burger,
  Drawer,
  Box,
  Divider,
  useMantineTheme,
  LoadingOverlay,
  ActionIcon,
  Avatar
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { 
  IconStar, 
  IconLogin, 
  IconSettings, 
  IconUser, 
  IconArticle,
  IconCrystalBall,
  IconChevronDown,
  IconChevronRight,
  IconMenu2,
  IconInfoCircle,
  IconSun,
  IconMoon,
  IconMoonStars,
  IconCalendarEvent,
  IconCalendarTime,
  IconCalendarStats,
  IconBuildingBank,
  IconBulb,
  IconAlertTriangle,
  IconPlane,
  IconChartBar,
  IconDashboard,
  IconArrowRight,
  IconLogout,
  IconBrain,
  IconAward,
  IconCoin,
  IconCurrencyDollar,
  IconHome,
  IconMailbox,
  IconSailboat,
  IconShoppingCart,
  IconNote,
  IconNotebook,
  IconCompass,
  IconZodiacAries,
  IconZodiacTaurus,
  IconZodiacGemini,
  IconZodiacCancer,
  IconZodiacLeo,
  IconZodiacVirgo,
  IconZodiacLibra,
  IconZodiacScorpio,
  IconZodiacSagittarius,
  IconZodiacCapricorn,
  IconZodiacAquarius,
  IconZodiacPisces
} from '@tabler/icons-react';
import { showNotification, updateNotification } from '@mantine/notifications';
import axios from '../axiosConfig';
import AuthenticationModal from './AuthenticationModal';
import UserSettings from './UserSettings';
import NewSearch from './NewSearch';
import YourChart from './YourChart';
import SavedCharts from './SavedCharts';
import CollectiveHoroscopes from './CollectiveHoroscopes';
import { useNavigate, useParams } from 'react-router-dom';
import ArticleList from './ArticleList';
import ArticleDetail from './ArticleDetail';
import ArticleManagement from './ArticleManagement';
import ArticleEditor from './ArticleEditor';
import WaitlistModal from './WaitlistModal';
import AdminWaitlist from './AdminWaitlist';
import AdminDashboard from './AdminDashboard';
import DailyNotes from './DailyNotes';
import '../mobile-submenu.css';
import '../styles/horoscope.css';

const COLLECTIVE_TYPES = [
  { value: 'collective_daily', label: 'Daily Collective' },
  { value: 'collective_weekly', label: 'Weekly Collective' },
  { value: 'collective_monthly', label: 'Monthly Collective' },
  { value: 'politics', label: 'Political Forecast' },
  { value: 'warnings', label: 'Astrological Warnings' },
  { value: 'opportunities', label: 'Opportunity Forecast' },
  { value: 'travel', label: 'Travel Forecast' },
  { value: 'zodiac', label: 'Zodiac' }
];

const getDateForHoroscopeType = (type) => {
  // Create a new date in UTC
  const now = new Date();
  const utcNow = new Date(Date.UTC(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds()
  ));
  
  switch (type) {
    case 'collective_weekly':
      // Set to Monday of current week using UTC
      const monday = new Date(Date.UTC(
        utcNow.getUTCFullYear(),
        utcNow.getUTCMonth(),
        utcNow.getUTCDate()
      ));
      const dayOfWeek = monday.getUTCDay();
      monday.setUTCDate(monday.getUTCDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));
      return monday;
      
    case 'collective_monthly':
      // Set to first day of current month using UTC
      return new Date(Date.UTC(
        utcNow.getUTCFullYear(),
        utcNow.getUTCMonth(),
        1
      ));
      
    default:
      // For daily and other types, use current date in UTC
      return new Date(Date.UTC(
        utcNow.getUTCFullYear(),
        utcNow.getUTCMonth(),
        utcNow.getUTCDate()
      ));
  }
};

function LandingTabs({ user, onLogin, onLogout, subscriptionStatus, colorScheme, toggleColorScheme, isAsdUser: isUserAsd }) {
  const [modalOpened, setModalOpened] = useState(false);
  const [activeView, setActiveView] = useState('articles');
  const [pendingAction, setPendingAction] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [generatingHoroscope, setGeneratingHoroscope] = useState(false);
  const [isViewChanging, setIsViewChanging] = useState(false);
  const navigate = useNavigate();
  const [articleSlug, setArticleSlug] = useState(null);
  const [articleId, setArticleId] = useState(null);
  const { slug } = useParams(); // This will be undefined unless we're in a route with a slug parameter
  const [sidebarOpened, setSidebarOpened] = useState(true);
  const theme = useMantineTheme();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [waitlistModalOpened, setWaitlistModalOpened] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  
  // Helper function to safely handle rgba calls
  const safeRgba = (color, alpha) => {
    if (!theme || !theme.fn || !theme.fn.rgba) {
      return `rgba(0, 0, 0, ${alpha})`; // Fallback rgba
    }
    return theme.fn.rgba(color, alpha);
  };
  
  // Create separate refs for each horoscope type
  const horoscopeRefs = {
    collective_daily: useRef(),
    collective_weekly: useRef(),
    collective_monthly: useRef(),
    politics: useRef(),
    warnings: useRef(),
    opportunities: useRef(),
    travel: useRef()
  };

  // If a slug is provided via URL params, set it in state
  useEffect(() => {
    if (slug) {
      setArticleSlug(slug);
      setActiveView('article_detail');
    }
  }, [slug]);

  const toggleSidebar = () => {
    // Toggle the sidebar state on both mobile and desktop
    // This allows collapsing the sidebar even after navigating to a page
    setSidebarOpened(prev => !prev);
  };
  
  const closeSidebar = () => {
    // Only call this on mobile devices to close the sidebar
    // This should not be called on desktop as it would collapse the always-visible sidebar
    // Note: This function will be called from viewChangeHandler only on mobile devices
    //       to automatically close the menu when menu items are clicked
    setSidebarOpened(false);
  };
  
  // We removed the click-outside handler to prevent the sidebar from closing automatically
  // Now the sidebar will only close when the hamburger icon is clicked

  const generateHoroscope = async (type) => {
    const targetDate = getDateForHoroscopeType(type);
    
    try {
      const response = await axios.post('/api/collective-horoscopes/generate', {
        type: type,
        date: targetDate.toISOString().split('T')[0]  // Format as YYYY-MM-DD
      });
      
      setActiveView(type);
      
      // We don't call ensureHoroscopesExist() here to avoid potential infinite loop

      return response.data;
    } catch (error) {
      // Handle rate limiting
      if (error.response && error.response.status === 429) {
        showNotification({
          title: 'Rate Limit Reached',
          message: 'Too many requests. Please try again later.',
          color: 'orange'
        });
      } else {
        showNotification({
        title: 'Error',
          message: `Failed to generate horoscope: ${error.response?.data?.message || error.message}`,
          color: 'red'
        });
      }
    }
  };

  const handleViewChange = async (viewName) => {
    // Start loading indicator
    setIsViewChanging(true);
    
    // Special case for the waitlist view - open the modal
    if (viewName === 'waitlist') {
      setWaitlistModalOpened(true);
      setIsViewChanging(false);
      return;
    }
    
    // Check if view requires authentication (for admin features only now)
    const requiresAuth = ['userSettings', 'savedCharts', 'dailyNotes', 'article_management', 'new_article', 'article_editor'].includes(viewName);
    const requiresAdmin = ['article_management', 'new_article', 'article_editor', 'admin_dashboard'].includes(viewName);
    
    // If trying to access protected view without being logged in
    if (requiresAuth && !user) {
      // Store the requested view for after login
      setPendingAction(viewName);
      // Open login modal
      setModalOpened(true);
      // End loading
      setIsViewChanging(false);
      return; // Don't change view yet
    }
    
    // If trying to access admin view without admin privileges
    if (requiresAdmin && !isAsdUser()) {
      // Redirect to user settings instead
      showNotification({
        title: 'Access Denied',
        message: 'You do not have permission to access this area.',
        color: 'red'
      });
      
      // Redirect to user settings if logged in, otherwise to login
      setActiveView(user ? 'userSettings' : 'articles');
      
      // End loading
      setIsViewChanging(false);
      return;
    }
    
    // For article detail, set the slug
    if (viewName === 'article_detail' && articleSlug) {
      navigate(`/articles/${articleSlug}`);
      setIsViewChanging(false);
    } else if (viewName === 'new_article') {
      // For new article, set the active view
      setActiveView(viewName);
      setArticleId(null); // Clear any existing article ID
      setIsViewChanging(false);
    } else if (viewName === 'article_editor' && articleId) {
      // For editing existing article
      setActiveView(viewName);
      setIsViewChanging(false);
    } else {
      // For all other views, set the active view
      setActiveView(viewName);
      
      // Update URL if it's a main view for bookmarking
      if (!['article_detail', 'article_editor', 'new_article'].includes(viewName)) {
        navigate(`/${viewName}`);
      }
      
      // End loading after a brief delay to avoid flickering
      setTimeout(() => {
        setIsViewChanging(false);
      }, 300);
    }
  };

  const handleLogin = async (userData) => {
    console.log('LandingTabs - handleLogin called with:', userData);
    
    if (onLogin) {
      console.log('LandingTabs - Calling parent onLogin handler');
      onLogin(userData);
    }
    
    // Check if there is a pending action (view to change to after login)
      if (pendingAction) {
      console.log('LandingTabs - Processing pending action:', pendingAction);
      const action = pendingAction;
      setPendingAction(null);
      setActiveView(action);
    }
    
    // Close login modal
    setModalOpened(false);
  };

  useEffect(() => {
    return () => {
      // Cleanup when component unmounts
      setMenuOpen(false);
      setModalOpened(false);
    };
  }, []);

  // Function to ensure horoscope exists for a specific type
  const ensureHoroscopeExists = async (type) => {
    if (!type || !COLLECTIVE_TYPES.some(item => item.value === type)) {
      return; // Skip if not a valid horoscope type
    }
    
    try {
      // Check if horoscope exists
      const response = await axios.get(`/api/collective-horoscopes/${type}/latest`);
      
      // If we don't have data, generate it
      if (!response.data || !response.data.chatbot_response) {
        await generateHoroscope(type);
      }
    } catch (error) {
      // If the API returns an error due to rate limiting, wait and retry
      if (error.response && error.response.status === 429) {
        // Don't retry to prevent more rate limiting
        showNotification({
          title: 'Rate Limit Reached',
          message: 'Too many requests. Please try again later.',
          color: 'orange'
        });
      }
      // If the horoscope doesn't exist (404), generate it
      else if (error.response && error.response.status === 404) {
        await generateHoroscope(type);
      }
    }
  };
  
  // Only check for the current active view horoscope
  const ensureHoroscopesExist = async () => {
    // Only ensure the currently active view exists
    await ensureHoroscopeExists(activeView);
  };

  // Helper to check if current user is the special 'asd' user
  const isAsdUser = () => {
    return isUserAsd || (user && user.id === "17");
  };

  // Function to view an article detail
  const handleViewArticle = (article) => {
    // Prevent default view from being set by isolating the state update
    setTimeout(() => {
      console.log('Setting view to article_detail for:', article.slug);
      setArticleSlug(article.slug);
      setActiveView('article_detail');
    }, 0);
  };

  // Function to edit an article
  const handleEditArticle = (article) => {
    if (isAsdUser()) {
      setArticleId(article.id);
      setActiveView('article_editor');
    }
  };

  // Navigation items for the sidebar
  const navItems = [
    {
      label: 'Articles',
      icon: <IconArticle size={16} />,
      value: 'articles'
    },
    {
      label: 'Collective',
      icon: <IconStar size={16} />,
      subItems: [
        { label: 'Daily Collective', value: 'collective_daily', icon: <IconCalendarEvent size={16} /> },
        { label: 'Weekly Collective', value: 'collective_weekly', icon: <IconCalendarTime size={16} /> },
        { label: 'Monthly Collective', value: 'collective_monthly', icon: <IconCalendarStats size={16} /> },
        { label: 'Political Forecast', value: 'politics', icon: <IconBuildingBank size={16} /> },
        { label: 'Opportunity Forecast', value: 'opportunities', icon: <IconBulb size={16} /> },
        { label: 'Astrological Warnings', value: 'warnings', icon: <IconAlertTriangle size={16} /> },
        { label: 'Travel Forecast', value: 'travel', icon: <IconPlane size={16} /> }
      ]
    },
    // {
    //   label: 'Zodiac',
    //   icon: <IconCompass size={16} />,
    //   subItems: [
    //     { label: 'Aries', value: 'zodiac?sign=aries', icon: <IconZodiacAries size={16} /> },
    //     { label: 'Taurus', value: 'zodiac?sign=taurus', icon: <IconZodiacTaurus size={16} /> },
    //     { label: 'Gemini', value: 'zodiac?sign=gemini', icon: <IconZodiacGemini size={16} /> },
    //     { label: 'Cancer', value: 'zodiac?sign=cancer', icon: <IconZodiacCancer size={16} /> },
    //     { label: 'Leo', value: 'zodiac?sign=leo', icon: <IconZodiacLeo size={16} /> },
    //     { label: 'Virgo', value: 'zodiac?sign=virgo', icon: <IconZodiacVirgo size={16} /> },
    //     { label: 'Libra', value: 'zodiac?sign=libra', icon: <IconZodiacLibra size={16} /> },
    //     { label: 'Scorpio', value: 'zodiac?sign=scorpio', icon: <IconZodiacScorpio size={16} /> },
    //     { label: 'Sagittarius', value: 'zodiac?sign=sagittarius', icon: <IconZodiacSagittarius size={16} /> },
    //     { label: 'Capricorn', value: 'zodiac?sign=capricorn', icon: <IconZodiacCapricorn size={16} /> },
    //     { label: 'Aquarius', value: 'zodiac?sign=aquarius', icon: <IconZodiacAquarius size={16} /> },
    //     { label: 'Pisces', value: 'zodiac?sign=pisces', icon: <IconZodiacPisces size={16} /> }
    //   ]
    // },
    {
      label: 'Personal',
      icon: <IconUser size={16} />,
      subItems: [
        { label: 'Your Saved Charts', value: 'savedCharts', requiresAuth: true, icon: <IconStar size={16} /> },
        { label: 'Daily Notes', value: 'dailyNotes', requiresAuth: true, icon: <IconNote size={16} /> },
        { label: 'Admin Dashboard', value: 'admin_dashboard', requiresAuth: true, requiresAdmin: true, icon: <IconDashboard size={16} /> },
      ]
    },
    {
      label: user ? 'User Settings' : 'Login',
      icon: user ? <IconSettings size={16} /> : <IconLogin size={16} />,
      value: user ? 'userSettings' : 'waitlist'
    },
    {
      label: 'Shop',
      icon: <IconShoppingCart size={16} />,
      value: 'shop'
    }
  ];

  // Change views without affecting the sidebar state on desktop, but always close on mobile
  const viewChangeHandler = (viewName) => {
    // Always close any open submenu when navigating
    setActiveSubmenu(null);
    
    // Change the view
    handleViewChange(viewName);
    
    // Only close the sidebar on mobile devices
    if (isMobile) {
      // Close immediately
      closeSidebar();
    }
    // On desktop, keep the sidebar open
  };
    
  // SubNavItem component for items with children
  const SubNavItem = ({ item, activeView, viewChangeHandler }) => {
    const hasActiveSubItem = item.subItems.some(subItem => subItem.value === activeView);
    // Auto-expand the submenu if any of its children are active on initial render
    const [expanded, setExpanded] = useState(hasActiveSubItem);
    const [userCollapsed, setUserCollapsed] = useState(false);
    const [hover, setHover] = useState(false);
    
    // We don't need a local mobileMenuOpen state since we're using the global activeSubmenu state
    // This was causing the need for double-clicks
    const mobileMenuOpen = activeSubmenu === item.label;
    
    // Auto-expand if a subitem is active, but respect manual collapse
    useEffect(() => {
      // Only auto-expand if user hasn't manually collapsed and a subitem is active
      if (hasActiveSubItem && !expanded && !userCollapsed) {
        setExpanded(true);
      }
    }, [activeView, hasActiveSubItem, expanded, userCollapsed]);
    
    // Function to check if an item should be filtered due to permissions
    const shouldShowSubItem = (subItem) => {
      // Show admin items if either the prop is true or the function returns true
      if (subItem.requiresAdmin) {
        return user && (isUserAsd || isAsdUser());
      }
      
      return true;
    };
    
    // Handle mouse enter for desktop hover
    const handleMouseEnter = () => {
      if (!isMobile) {
        setHover(true);
        // Only set expanded if it's not already expanded
        if (!expanded) {
          setExpanded(true);
        }
      }
    };
    
    // Handle mouse leave for desktop hover
    const handleMouseLeave = () => {
      if (!isMobile) {
        setHover(false);
        // Don't collapse the submenu when mouse leaves
        // This ensures the submenu stays open until explicitly closed
      }
    };
    
    // Handle toggle expansion with explicit event handling
    const handleToggleExpand = (e) => {
      if (isMobile) {
        e.preventDefault();
        e.stopPropagation();
        
        console.log("Mobile toggle clicked, current submenu:", activeSubmenu, "this item:", item.label);
        
        // If this submenu is already open, close it
        // If it's closed or another submenu is open, open this one
        if (activeSubmenu === item.label) {
          setActiveSubmenu(null);
        } else {
          // Close any other open submenu first
          setActiveSubmenu(null);
          // Then open this submenu after a small delay to ensure clean transition
          setTimeout(() => {
            setActiveSubmenu(item.label);
          }, 10);
        }
      } else {
        e.preventDefault();
        e.stopPropagation();
        
        console.log("Desktop toggle clicked, current state:", expanded);
        
        // Toggle expanded state
        const newExpandedState = !expanded;
        setExpanded(newExpandedState);
        
        // If collapsing an active submenu, remember this choice
        if (!newExpandedState && hasActiveSubItem) {
          setUserCollapsed(true);
        } else {
          setUserCollapsed(false);
        }
      }
    };
    
    // Handle clicks on submenu items - should navigate and close sidebar on mobile
    const handleSubItemClick = (subItemValue) => {
      // On mobile, close the submenu before navigating
      if (isMobile) {
        setActiveSubmenu(null); // Reset active submenu when an item is clicked
        
        // Small delay to ensure submenu is closed before navigation
        setTimeout(() => {
          // Navigate to the selected item
          viewChangeHandler(subItemValue);
        }, 10);
      } else {
        // On desktop, keep submenu expanded after clicking
        setUserCollapsed(false);
        setExpanded(true);
        
        // Navigate to the selected item
        viewChangeHandler(subItemValue);
      }
    };

    return (
      <Box 
        key={item.label} 
        mb={0} 
        mt={0} 
        ml={2}
        className="accordion-menu-item"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <UnstyledButton 
          p="xs"
          className={`${hasActiveSubItem ? 'active' : ''} nav-item`}
          onClick={handleToggleExpand}
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            padding: `6px 10px`,
            borderRadius: theme.radius.md,
            fontWeight: 800,
            fontSize: theme.fontSizes.sm,
            color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
            '&:hover': {
              backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : safeRgba(theme.colors.indigo[0], 0.7),
              color: theme.colors.indigo[7],
            },
            transition: 'all 150ms ease',
            marginBottom: expanded ? 2 : 0,
            marginTop: 0,
            ...(hasActiveSubItem && {
              backgroundColor: safeRgba(theme.colors.indigo[0], 0.3),
            }),
            // Mobile styles
            [`@media (max-width: 768px)`]: {
              flexDirection: 'column',
              padding: '5px 2px',
              justifyContent: 'center',
              textAlign: 'center',
              '&:hover': {
                backgroundColor: 'transparent',
              },
              '&:active': {
                backgroundColor: 'transparent',
              }
            },
          })}
        >
          <ThemeIcon 
            variant="subtle" 
            size={isMobile ? 46 : 30}
            radius="xl" 
            color={hasActiveSubItem ? 'indigo' : 'gray'}
            mr={isMobile ? 0 : 8}
            mb={isMobile ? 4 : 0}
            sx={(theme) => ({
              backgroundColor: hasActiveSubItem
                ? isMobile 
                  ? safeRgba(theme.colors.indigo[0], 0.15)
                  : safeRgba(theme.colors.indigo[0], 0.7)
                : isMobile
                  ? safeRgba(theme.colors.indigo[0], 0.08)
                  : safeRgba(theme.colors.gray[1], 0.7),
              border: hasActiveSubItem
                ? `1px solid ${safeRgba(theme.colors.indigo[4], hasActiveSubItem ? 0.4 : 0.2)}`
                : `1px solid ${safeRgba(theme.colors.gray[3], 0.5)}`,
              boxShadow: hasActiveSubItem && isMobile
                ? `0 2px 4px ${safeRgba(theme.colors.indigo[4], 0.1)}`
                : `0 1px 2px ${safeRgba(theme.colors.gray[6], 0.1)}`,
              width: isMobile ? '46px' : '30px',
              height: isMobile ? '46px' : '30px',
              minWidth: isMobile ? '46px' : '30px',
              minHeight: isMobile ? '46px' : '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              transition: 'all 0.2s ease',
              ...(isMobile ? {
                '&:hover': {
                  transform: 'scale(1.1)',
                  boxShadow: `0 2px 8px ${safeRgba(theme.colors.indigo[5], 0.3)}`,
                },
                '&:active': {
                  transform: 'scale(0.95)',
                }
              } : {})
            })}
          >
            {React.cloneElement(item.icon, { 
              size: isMobile ? 34 : 20,
              color: hasActiveSubItem ? theme.colors.indigo[6] : undefined
            })}
          </ThemeIcon>
          {!isMobile && (
            <Text 
              size={isMobile ? "xs" : "sm"}
              fw={hasActiveSubItem ? 800 : 600}
              style={{ flex: isMobile ? 'none' : 1 }}
              sx={(theme) => ({
                [`@media (max-width: 768px)`]: {
                  display: 'none !important',
                  visibility: 'hidden !important',
                  height: 0,
                  width: 0,
                  overflow: 'hidden',
                  margin: 0,
                  padding: 0
                }
              })}
            >
              {item.label}
            </Text>
          )}
        </UnstyledButton>
        
        {/* Desktop submenu - shown when expanded or hovered */}
        {expanded && !isMobile && (
          <Box mb={5} className="accordion-menu-subitem" sx={{ 
            width: '100%', 
            display: 'block', 
            opacity: 1,
            visibility: 'visible',
            height: 'auto',
            overflow: 'visible',
            transition: 'opacity 0.2s ease, visibility 0.2s ease, height 0.2s ease',
            marginLeft: '8px'
          }}>
            {item.subItems
              .filter(shouldShowSubItem)
              .map(subItem => {
                const isSubItemActive = subItem.value === activeView;
                
                return (
                  <UnstyledButton
                    key={subItem.value}
                    onClick={() => handleSubItemClick(subItem.value)}
                    disabled={false}
                    className={isSubItemActive ? 'active' : ''}
                    sx={(theme) => ({
                      display: 'block',
                      width: '100%',
                      padding: '6px 8px 6px 20px',
                      fontWeight: isSubItemActive ? 600 : 500,
                      fontSize: theme.fontSizes.sm,
                      marginBottom: 4,
                      backgroundColor: isSubItemActive ? safeRgba(theme.colors.indigo[0], 0.3) : 'transparent',
                      color: isSubItemActive ? theme.colors.indigo[7] : theme.colorScheme === 'dark' ? theme.colors.gray[3] : theme.colors.gray[7],
                      '&:hover': {
                        backgroundColor: theme.colorScheme === 'dark' ? 
                          theme.colors.indigo[9] : 
                          theme.colors.gray[1],
                        color: theme.colorScheme === 'dark' ? 
                          '#ffffff' : 
                          theme.colors.indigo[7],
                        fontWeight: theme.colorScheme === 'dark' ? 600 : undefined,
                        textShadow: theme.colorScheme === 'dark' ? '0 0 1px rgba(255,255,255,0.5)' : 'none',
                      },
                      '&[data-disabled]': {
                        opacity: 0.5,
                        color: theme.colors.gray[6],
                        '&:hover': {
                          backgroundColor: 'transparent',
                        }
                      }
                    })}
                  >
                    <Box>
                      <Text size="sm">{subItem.label}</Text>
                      {subItem.requiresAuth && !user && (
                        <Text size="xs" color="dimmed" mt={2}>(Access required)</Text>
                      )}
                      {subItem.requiresWaitlist && (
                        <Text size="xs" color="dimmed" mt={2}>(Early access)</Text>
                      )}
                    </Box>
                  </UnstyledButton>
                );
              })
            }
          </Box>
        )}
        
        {/* Mobile submenu - shown above the navbar when clicked */}
        {mobileMenuOpen && isMobile && (
          <>
            {/* 
              Backdrop - This is the semi-transparent overlay that appears behind the submenu
              It covers the entire screen and closes the submenu when clicked
            */}
            <div 
              className="mobile-submenu-backdrop"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setActiveSubmenu(null); // Reset active submenu when backdrop is clicked
              }}
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: '70px',
                zIndex: 9970,
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                backdropFilter: 'blur(4px)',
                animation: 'fadeIn 0.3s ease-out',
              }}
            />
            
            {/* 
              Submenu Container - This is the main container for the submenu
              It's positioned at the bottom of the screen, above the navbar
              We use a plain div instead of Mantine's Box component to avoid any styling conflicts
            */}
            <div className="mobile-submenu">
              {/* 
                Pull Handle - This is the small bar at the top of the submenu
                It's purely decorative and helps users understand they can drag the submenu
              */}
              <div className="mobile-submenu-handle"></div>
              
              {/* 
                Title - This displays the current submenu section title
                It's centered at the top of the submenu
              */}
              <div className="options-title">
                {item.label} OPTIONS
              </div>
              
              {/* 
                Submenu Grid - This contains all the submenu items
                It's a simple container that stacks its children vertically
              */}
              <div className="submenu-grid">
                {/* Map through submenu items */}
                {item.subItems
                  .filter(shouldShowSubItem)
                  .map((subItem) => {
                    const isActive = 
                      activeView === subItem.value || 
                      (subItem.value === 'daily_collective' && activeView === 'home');
                    
                    // Render each submenu item as a button
                    return (
                      <button
                        key={subItem.value}
                        onClick={() => {
                          handleSubItemClick(subItem.value);
                        }}
                        className={`submenu-item ${isActive ? 'active' : ''}`}
                      >
                        {/* Icon Container */}
                        <div className="submenu-item-icon">
                          {subItem.icon ? (
                            React.cloneElement(subItem.icon, { 
                              size: 24,
                              color: isActive ? theme.colors.indigo[6] : undefined
                            })
                          ) : (
                            <div style={{ width: '24px', height: '24px' }}></div>
                          )}
                        </div>
                        
                        {/* Label */}
                        <div className="submenu-label">
                          {subItem.label}
                          {subItem.requiresAuth && !user && (
                            <span style={{ fontSize: '12px', opacity: 0.7, marginLeft: '4px' }}>(Login required)</span>
                          )}
                          {subItem.requiresWaitlist && (
                            <span style={{ fontSize: '12px', opacity: 0.7, marginLeft: '4px' }}>(Early access)</span>
                          )}
                        </div>
                      </button>
                    );
                  })
                }
              </div>
            </div>
          </>
        )}
      </Box>
    );
  };

  // Navigation item renderer with enhanced styling
  const renderNavItem = (item) => {
    const isActive = item.value === activeView;
    
    // If the item has subitems, render as an accordion
    if (item.subItems) {
      return <SubNavItem item={item} activeView={activeView} viewChangeHandler={viewChangeHandler} />;
    }
    
    // Otherwise render as a regular item without a chevron
    return (
      <UnstyledButton
        key={item.value}
        onClick={() => {
          // Close any open submenu first
          setActiveSubmenu(null);
          // Then navigate to the view
          viewChangeHandler(item.value);
        }}
        className={`nav-item ${isActive ? 'active' : ''} ${item.label === 'Login' ? 'login-nav-item' : ''}`}
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          padding: `6px 12px`,
          borderRadius: theme.radius.md,
          fontWeight: 600,
          marginBottom: 0,
          marginTop: 0,
          backgroundColor: isActive 
            ? safeRgba(theme.colors.indigo[0], 0.7)
            : 'transparent',
          color: isActive 
            ? theme.colors.indigo[7]
            : theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
          '&:hover': {
            backgroundColor: isActive 
              ? safeRgba(theme.colors.indigo[0], 0.9)
              : safeRgba(theme.colors.indigo[0], 0.5),
            color: theme.colors.indigo[7],
          },
          transition: 'all 150ms ease',
          // Mobile styles
          [`@media (max-width: 768px)`]: {
            flexDirection: 'column',
            padding: '5px 2px',
            justifyContent: 'center',
            textAlign: 'center',
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '&:active': {
              backgroundColor: 'transparent',
            }
          }
        })}
      >
        <ThemeIcon 
          variant="light" 
          size={isMobile ? 56 : 30}
          radius="xl" 
          color={isActive ? 'indigo' : 'gray'}
          mr={isMobile ? 2 : 8}
          mb={isMobile ? 4 : 2}
          sx={(theme) => ({
            backgroundColor: isActive
              ? isMobile 
                ? safeRgba(theme.colors.indigo[0], 0.15)
                : safeRgba(theme.colors.indigo[0], 0.7)
              : isMobile
                ? safeRgba(theme.colors.indigo[0], 0.08)
                : safeRgba(theme.colors.gray[1], 0.7),
            border: isActive
              ? `1px solid ${safeRgba(theme.colors.indigo[4], isActive ? 0.4 : 0.2)}`
              : `1px solid ${safeRgba(theme.colors.gray[3], 0.5)}`,
            boxShadow: isActive && isMobile
              ? `0 2px 4px ${safeRgba(theme.colors.indigo[4], 0.1)}`
              : `0 1px 2px ${safeRgba(theme.colors.gray[6], 0.1)}`,
            width: isMobile ? '46px' : '30px',
            height: isMobile ? '46px' : '30px',
            minWidth: isMobile ? '46px' : '30px',
            minHeight: isMobile ? '46px' : '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%'
          })}
        >
          {React.cloneElement(item.icon, { 
            size: isMobile ? 28 : 20,
            color: isActive ? theme.colors.indigo[6] : undefined
          })}
        </ThemeIcon>
        {!isMobile && (
          <Text 
            size="sm"
            fw={isActive ? 600 : 500}
          >
            {item.label}
          </Text>
        )}
      </UnstyledButton>
    );
  };

  // This function renders the main content based on the active view
  const renderContent = () => {
    // If activeView is 'articles', render the ArticleList component
    if (activeView === 'articles') {
      return (
        <div style={{ 
          maxWidth: '1800px', 
          width: '100%', 
          margin: '0 auto', 
          paddingRight: '40px',
          marginLeft: sidebarOpened ? '20px' : '40px'
        }}>
          <ArticleList 
            onViewArticle={handleViewArticle} 
            onCreateNew={() => viewChangeHandler('new_article')}
            onLoadingChange={setIsViewChanging}
          />
       </div>
      );
    }
    
    switch(activeView) {
      case 'collective_daily':
        return (
          <CollectiveHoroscopes 
            ref={horoscopeRefs.collective_daily}
            defaultType="collective_daily" 
            onLoadingChange={setIsViewChanging}
            user={user}
            subscriptionStatus={subscriptionStatus}
            onRequestAccess={showWaitlistModal}
            className="horoscope-content"
          />
        );
      case 'collective_weekly':
        return (
          <CollectiveHoroscopes 
            ref={horoscopeRefs.collective_weekly}
            defaultType="collective_weekly" 
            onLoadingChange={setIsViewChanging}
            user={user}
            subscriptionStatus={subscriptionStatus}
            onRequestAccess={showWaitlistModal}
            className="horoscope-content"
          />
        );
      case 'collective_monthly':
        return (
          <CollectiveHoroscopes 
            ref={horoscopeRefs.collective_monthly}
            defaultType="collective_monthly" 
            onLoadingChange={setIsViewChanging}
            user={user}
            subscriptionStatus={subscriptionStatus}
            onRequestAccess={showWaitlistModal}
            className="horoscope-content"
          />
        );
      case 'politics':
        return (
          <CollectiveHoroscopes 
            ref={horoscopeRefs.politics}
            defaultType="politics" 
            onLoadingChange={setIsViewChanging}
            user={user}
            subscriptionStatus={subscriptionStatus}
            onRequestAccess={showWaitlistModal}
            className="horoscope-content"
          />
        );
      case 'opportunities':
        return (
          <CollectiveHoroscopes 
            ref={horoscopeRefs.opportunities}
            defaultType="opportunities" 
            onLoadingChange={setIsViewChanging}
            user={user}
            subscriptionStatus={subscriptionStatus}
            onRequestAccess={showWaitlistModal}
            className="horoscope-content"
          />
        );
      case 'warnings':
        return (
          <CollectiveHoroscopes 
            ref={horoscopeRefs.warnings}
            defaultType="warnings" 
            onLoadingChange={setIsViewChanging}
            user={user}
            subscriptionStatus={subscriptionStatus}
            onRequestAccess={showWaitlistModal}
            className="horoscope-content"
          />
        );
      case 'travel':
        return (
          <CollectiveHoroscopes 
            ref={horoscopeRefs.travel}
            defaultType="travel" 
            onLoadingChange={setIsViewChanging}
            user={user}
            subscriptionStatus={subscriptionStatus}
            onRequestAccess={showWaitlistModal}
            className="horoscope-content"
          />
        );
      case 'savedCharts':
        return <SavedCharts user={user} />;
      case 'dailyNotes':
        return user ? (
          <DailyNotes user={user} />
        ) : (
          <Box p="lg" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
            <Box mb={20}>
              <IconNote size={48} color="#e9ecef" />
            </Box>
            <Text align="center" mb={20}>Please log in to access your notes</Text>
            <Button onClick={showAuthModal}>Log In</Button>
          </Box>
        );
      case 'userSettings':
        return (
          user ? (
            <UserSettings 
              user={user} 
              onLogout={onLogout} 
              onUserUpdate={(updatedUser) => {
                console.log('User updated:', updatedUser);
                onLogin({ user: updatedUser, token: localStorage.getItem('token') });
              }}
              subscriptionStatus={subscriptionStatus} 
            />
          ) : (
            <div>Please log in to access user settings.</div>
          )
        );
      case 'article_detail':
        return (
          articleSlug ? (
            <ArticleDetail 
              slug={articleSlug} 
              onBack={() => viewChangeHandler('articles')}
              onEdit={isAsdUser() ? handleEditArticle : undefined}
            />
          ) : (
            <div>Article not found. <Button onClick={() => viewChangeHandler('articles')}>Return to Articles</Button></div>
          )
        );
      case 'article_management':
        return (
          isAsdUser() && (
            <ArticleManagement 
              onEdit={handleEditArticle}
              onCreateNew={() => viewChangeHandler('new_article')}
            />
          )
        );
      case 'article_editor':
        return (
          isAsdUser() && articleId && (
            <ArticleEditor 
              articleId={articleId}
              onSaved={() => viewChangeHandler('articles')}
              onCancel={() => viewChangeHandler('article_management')}
            />
          )
        );
      case 'new_article':
        return (
          isAsdUser() && (
            <ArticleEditor 
              onSaved={() => viewChangeHandler('articles')}
              onCancel={() => viewChangeHandler('article_management')}
            />
          )
        );
      case 'admin_waitlist':
        return isAsdUser() ? (
          <AdminWaitlist />
        ) : (
          <Box p="lg">
            <Text color="red">You don't have permission to access this area.</Text>
          </Box>
        );
      case 'admin_dashboard':
        console.log('Checking admin access:', { 
          user, 
          isUserAsd,
          isAsdCheck: isAsdUser(),
          userId: user?.id
        });
        return (
          isAsdUser() ? (
            <AdminDashboard user={user} />
          ) : (
            <Box p="lg">
              <Text color="red">You don't have permission to access this area.</Text>
            </Box>
          )
        );
      case 'shop':
        return (
          <Box p="lg">
            <Text align="center" size="xl" weight={700} mb="xl">
              Shop
            </Text>
            <Text align="center" color="dimmed">
            Shop's a hot mess in the making—drop back soon for stuff
            </Text>
          </Box>
        );
      default:
        return <div>View not found</div>;
    }
  };

  // Helper function to get the display name from user object
  const getDisplayName = (user) => {
    if (!user) return 'User';
    
    // Try different properties that might contain the name
    return user.username || user.userName || user.name || user.displayName || user.email || 
      (user.id ? `User ${user.id}` : 'User');
  };

  // Add a new useEffect to monitor activeView changes
  useEffect(() => {
    // Only call ensureHoroscopeExists when we're on a horoscope view
    const isHoroscopeView = COLLECTIVE_TYPES.some(item => item.value === activeView);
    
    if (isHoroscopeView) {
      // Slight delay to prevent immediate API call
      const timer = setTimeout(() => {
        ensureHoroscopeExists(activeView);
      }, 500);
      
      return () => clearTimeout(timer);
    }
  }, [activeView]);

  // Helper function to get SEO metadata based on active view
  const getSeoMetadata = () => {
    // This function can remain, but we'll modify how we use its result
    let title = 'Fate Oracle - Personalized Horoscopes & Astrological Insights';
    let description = 'Discover personalized horoscopes and astrological insights tailored to your unique birth chart.';
    let canonicalUrl = 'https://www.fateoracle.com';
    
    // Set title based on active view
    if (activeView === 'collective_daily') {
      title = 'Daily Collective Horoscope | Fate Oracle';
      canonicalUrl = 'https://www.fateoracle.com/collective_daily';
    } else if (activeView === 'collective_weekly') {
      title = 'Weekly Collective Horoscope | Fate Oracle';
      canonicalUrl = 'https://www.fateoracle.com/collective_weekly';
    } else if (activeView === 'collective_monthly') {
      title = 'Monthly Collective Horoscope | Fate Oracle';
      canonicalUrl = 'https://www.fateoracle.com/collective_monthly';
    } else if (activeView === 'articles') {
      title = 'Astrological Articles | Fate Oracle';
      canonicalUrl = 'https://www.fateoracle.com/articles';
    }
    
    // Update document title manually instead of using Helmet
    document.title = title;
    
    return { title, description, canonicalUrl };
  };

  // Show waitlist modal when a user tries to access premium content
  const showWaitlistModal = () => {
    // Add a small delay to prevent immediate modal display when clicking menu items
    setTimeout(() => {
      setModalOpened(false); // Close the auth modal if open
      setWaitlistModalOpened(true);
    }, 100);
  };
  
  // Show authentication modal
  const showAuthModal = () => {
    setWaitlistModalOpened(false); // Close the waitlist modal if open
    setModalOpened(true);
  };

  // Add a useEffect to force the mobile navigation to the bottom of the screen
  useEffect(() => {
    if (isMobile) {
      // Force the mobile navigation to the bottom of the screen
      const styleElement = document.createElement('style');
      styleElement.textContent = `
        .mobile-bottom-nav, .mantine-AppShell-navbar.mobile-bottom-nav {
          position: fixed !important;
          bottom: 0 !important;
          top: auto !important;
          left: 0 !important;
          right: 0 !important;
          width: 100% !important;
          height: 70px !important;
          min-height: 70px !important;
          max-height: 70px !important;
          z-index: 9990 !important;
          overflow: visible !important;
          display: flex !important;
          flex-direction: row !important;
          justify-content: space-evenly !important;
          align-items: center !important;
          transform: none !important;
          opacity: 1 !important;
          visibility: visible !important;
        }
        
        /* Ensure the mobile nav is always visible regardless of AppShell collapsed state */
        .mantine-AppShell-navbar[data-collapsed="true"].mobile-bottom-nav {
          display: flex !important;
          transform: none !important;
          width: 100% !important;
        }
      `;
      document.head.appendChild(styleElement);
      
      return () => {
        document.head.removeChild(styleElement);
      };
    }
  }, [isMobile]);

  // Add a useEffect to ensure the mobile navigation icons are properly displayed
  useEffect(() => {
    if (isMobile) {
      // Add styles for the mobile navigation icons
      const iconStyleElement = document.createElement('style');
      iconStyleElement.textContent = `
        /* Hide burger menu in mobile view */
        @media (max-width: 768px) {
          .mantine-Burger-root {
            display: none !important;
            visibility: hidden !important;
            width: 0 !important;
            height: 0 !important;
            opacity: 0 !important;
            pointer-events: none !important;
          }
        }
        
        /* Desktop burger icon styling */
        @media (min-width: 769px) {
          .mantine-Burger-root {
            background: transparent !important;
            border: none !important;
            box-shadow: none !important;
            width: 24px !important;
            height: 24px !important;
            padding: 0 !important;
            margin: 0 8px 0 0 !important;
          }
          
          .mantine-Burger-burger {
            height: 3px !important;
            width: 18px !important;
          }
          
          .mantine-Burger-burger::before,
          .mantine-Burger-burger::after {
            height: 3px !important;
            width: 18px !important;
          }
        }
        
        .mobile-bottom-nav .mantine-ThemeIcon-root {
          width: 42px !important;
          height: 42px !important;
          min-width: 42px !important;
          min-height: 42px !important;
          max-width: 42px !important;
          max-height: 42px !important;
          border-radius: 50% !important;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          margin: 0 auto !important;
          padding: 0 !important;
          box-sizing: border-box !important;
          overflow: visible !important;
          position: relative !important;
          transform-origin: center !important;
          z-index: 1 !important;
        }
        
        .mobile-bottom-nav .mantine-ThemeIcon-root svg {
          width: 22px !important;
          height: 22px !important;
          display: block !important;
          margin: 0 auto !important;
          padding: 0 !important;
          position: absolute !important;
          top: 50% !important;
          left: 50% !important;
          transform: translate(-50%, -50%) !important;
          z-index: 2 !important;
        }
        
        .mobile-bottom-nav .nav-item,
        .mobile-bottom-nav .accordion-menu-item > .mantine-UnstyledButton-root {
          flex: 1 !important;
          display: flex !important;
          flex-direction: column !important;
          align-items: center !important;
          justify-content: center !important;
          padding: 0 !important;
          margin: 0 !important;
          max-width: 25% !important;
          position: relative !important;
          height: 100% !important;
          box-sizing: border-box !important;
          overflow: visible !important;
        }
        
        .mobile-bottom-nav .nav-items-container {
          display: flex !important;
          flex-direction: row !important;
          justify-content: space-evenly !important;
          align-items: center !important;
          width: 100% !important;
          height: 100% !important;
          padding: 0 !important;
          margin: 0 !important;
        }
      `;
      document.head.appendChild(iconStyleElement);
      
      return () => {
        document.head.removeChild(iconStyleElement);
      };
    }
  }, [isMobile]);

  // Add a useEffect to handle sidebar collapse and content expansion
  useEffect(() => {
    // Only apply this on desktop
    if (!isMobile) {
      // Add CSS to handle sidebar collapse and content expansion
      const styleElement = document.createElement('style');
      styleElement.textContent = `
        /* Only adjust z-index for desktop navbar */
        .desktop-navbar {
          z-index: 9999 !important;
        }
      `;
      document.head.appendChild(styleElement);
      
      return () => {
        document.head.removeChild(styleElement);
      };
    }
  }, [isMobile, theme]);

  return (
    <AppShell
      layout="default"
      navbar={{ 
        width: { base: isMobile ? '100%' : 200 },
        breakpoint: 'sm',
        collapsed: { mobile: true, desktop: !sidebarOpened },
      }}
      header={{ height: 60 }}
      data-navbar-collapsed={!sidebarOpened}
      styles={{
        main: {
          paddingLeft: 0,
          paddingRight: 0,
          paddingBottom: isMobile ? '90px !important' : '20px !important',
          paddingTop: '70px !important', // Increased padding to ensure content is below header
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
          margin: 0,
          width: '100%'
        },
        navbar: {
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : 'white',
          borderRight: isMobile ? 'none' : `1px solid ${
            theme.colorScheme === 'dark' 
              ? safeRgba(theme.colors.dark[5], 0.5) 
              : safeRgba(theme.colors.gray[3], 0.5)
          }`,
          ...(isMobile ? {
            position: 'fixed',
            bottom: 0,
            top: 'auto',
            left: 0,
            right: 0,
            width: '100%',
            height: '70px',
            minHeight: '70px',
            maxHeight: '70px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center'
          } : {
            top: 60,
            height: 'calc(100vh - 60px)',
            padding: '12px 0',
          })
        },
        header: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          width: '100%',
          zIndex: 1001,
        }
      }}
    >
      <AppShell.Header p={0} height={60} sx={(theme) => ({
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : 'white',
        borderBottom: isMobile ? 'none' : `1px solid ${theme.colorScheme === 'dark' ? 
          safeRgba(theme.colors.gray[7], 0.5) : 
          safeRgba(theme.colors.gray[3], 0.5)
        }`,
        boxShadow: isMobile ? 'none' : theme.colorScheme === 'dark' ? 
          '0 2px 6px rgba(0, 0, 0, 0.2)' : 
          '0 2px 6px rgba(0, 0, 0, 0.08)',
        zIndex: 1001, // Ensure the header is above the navbar on mobile
        height: '60px !important',
        minHeight: '60px !important',
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
        // Apply the same background color as the theme toggle button for mobile view
        '@media (max-width: 750px)': {
          backgroundColor: theme.colorScheme === 'dark' ? 
            '#3E4047 !important' : 
            '#f0f2f5 !important',
        },
      })}>
        <Group position="apart" align="center" h="100%" w="100%" noWrap className="header-group">
          <Group spacing={isMobile ? 4 : 8} className="app-logo-container logo-group" noWrap sx={{
            paddingLeft: isMobile ? '6px' : '8px',
            justifyContent: 'flex-start',
            flex: isMobile ? '0 1 auto' : '1 0 auto'
          }}>
            <Burger
              opened={sidebarOpened}
              onClick={toggleSidebar}
              size={18}
              lineSize={3}
              color={sidebarOpened ? theme.colors.indigo[5] : theme.colors.gray[6]}
              aria-label="Toggle navigation"
              className="mantine-Burger-root"
              sx={{ 
                zIndex: 1100,
                position: 'relative',
                color: sidebarOpened ? theme.colors.indigo[5] : theme.colors.gray[6],
                boxShadow: 'none',
                background: 'transparent',
                border: 'none',
                padding: '8px',
                marginRight: '8px',
                display: isMobile ? 'none' : 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'all 0.2s ease',
                '&:hover': {
                  color: theme.colors.indigo[4],
                  transform: 'scale(1.05)'
                },
                '@media (max-width: 768px)': {
                  display: 'none !important'
                }
              }}
            />
            <Group spacing={isMobile ? 4 : 8} sx={{
              cursor: 'pointer',
              '&:hover .app-logo-text': {
                color: '#4c6ef5',
              }
            }} noWrap>
              {/* <ThemeIcon 
                size={isMobile ? 32 : 42} 
                radius={isMobile ? "md" : "lg"} 
                variant="gradient" 
                gradient={{ from: '#6e56cf', to: '#4c6ef5', deg: 45 }}
                className="app-logo-icon"
                sx={{ 
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                  overflow: 'hidden'
                }}
              >
                <IconCrystalBall 
                  size={isMobile ? 18 : 22} 
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                  }}
                />
              </ThemeIcon> */}
              <Text 
                size={isMobile ? "md" : "xl"} 
                fw={700} 
                className="app-logo-text" 
                sx={{ 
                  background: 'linear-gradient(45deg, #6e56cf, #4c6ef5)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  transition: 'all 0.2s ease',
                  letterSpacing: isMobile ? '-0.5px' : '-0.5px',
                  fontSize: isMobile ? '1.1rem' : '1.7rem',
                }}
              >
                FateOracle
              </Text>
            </Group>
          </Group>

          <Group spacing={isMobile ? 8 : 16} className="header-actions" noWrap>
            {/* Theme toggle button */}
            <ActionIcon
              variant="subtle"
              color={colorScheme === 'dark' ? 'yellow' : 'indigo'}
              onClick={() => toggleColorScheme()}
              title={colorScheme === 'dark' ? 'Switch to light mode' : 'Switch to dark mode'}
              size={isMobile ? "lg" : "xl"}
              className="theme-toggle-button"
              sx={{
                backgroundColor: theme.colorScheme === 'dark' ? 
                  '#3E4047' : 
                  '#f0f2f5',
                color: theme.colorScheme === 'dark' ? 
                  theme.colors.dark[0] : 
                  theme.colors.gray[7],
                border: 'none',
                borderRadius: '50% !important',
                width: isMobile ? '32px' : '36px',
                height: isMobile ? '32px' : '36px',
                minWidth: isMobile ? '32px' : '36px',
                minHeight: isMobile ? '32px' : '36px',
                maxWidth: isMobile ? '32px' : '36px',
                maxHeight: isMobile ? '32px' : '36px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden !important',
                aspectRatio: '1/1',
                padding: 0,
                '&:hover': {
                  backgroundColor: theme.colorScheme === 'dark' ? 
                    '#4A4C55' : 
                    '#e4e6eb',
                  transform: 'translateY(-1px)',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                }
              }}
            >
              {colorScheme === 'dark' ? <IconSun size="1.2rem" /> : <IconMoon size="1.2rem" />}
            </ActionIcon>

            {user && (
              <UnstyledButton
                className={`user-greeting-button ${subscriptionStatus === 'active' ? 'user-greeting-premium' : ''}`}
                onClick={() => viewChangeHandler('userSettings')}
                sx={{
                  padding: isMobile ? '6px 10px' : '8px 16px',
                  borderRadius: isMobile ? '8px !important' : '10px',
                  backgroundColor: theme.colorScheme === 'dark' ? 
                    '#3E4047' : 
                    '#f0f2f5',
                  color: theme.colorScheme === 'dark' ? 
                    theme.colors.dark[0] : 
                    theme.colors.gray[7],
                  border: 'none',
                  transition: 'all 0.2s ease',
                  overflow: 'hidden',
                  '&:hover': {
                    backgroundColor: theme.colorScheme === 'dark' ? 
                      '#4A4C55' : 
                      '#e4e6eb',
                    transform: 'translateY(-1px)',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  }
                }}
              >
                <Group spacing={8} noWrap>
                  <ThemeIcon 
                    size="md" 
                    radius="xl" 
                    color="indigo" 
                    variant="light" 
                    className="user-greeting-icon"
                    sx={{ position: 'relative', flexShrink: 0, borderRadius: '50% !important', overflow: 'hidden' }}
                  >
                    <IconUser size={14} />
                  </ThemeIcon>
                  {!isMobile && (
                    <Box>
                      <Text size="xs" color="dimmed" fw={500} lineClamp={1}>Welcome,</Text>
                      <Text size="sm" fw={600} lineClamp={1}>
                        {getDisplayName(user)}
                      </Text>
                    </Box>
                  )}
                </Group>
              </UnstyledButton>
            )}
          </Group>
        </Group>
      </AppShell.Header>

      <AppShell.Navbar className={isMobile ? "mobile-bottom-nav" : "desktop-navbar"}>
        <Box pl={isMobile ? 0 : 5} pt={isMobile ? 0 : 5} pb={isMobile ? 0 : 5} component={Stack} spacing={isMobile ? 0 : 8} style={{ flexGrow: 1, display: 'flex', justifyContent: isMobile ? 'space-evenly' : 'flex-start' }} className="nav-items-container">
          {navItems.map(renderNavItem)}
        </Box>
        
        {/* Only show divider and footer for logged-in users */}
        {user && (
          <>
            <Divider my="sm" className="nav-divider" />
            <Box p="md" className="nav-footer">
              <UnstyledButton
                sx={(theme) => ({
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  padding: '10px 12px',
                  borderRadius: theme.radius.md,
                  color: theme.colors.red[6],
                  backgroundColor: theme.colorScheme === 'dark' ? 
                    safeRgba(theme.colors.red[9], 0.15) : 
                    safeRgba(theme.colors.red[0], 0.5),
                  border: `1px solid ${theme.colorScheme === 'dark' ? 
                    safeRgba(theme.colors.red[9], 0.2) : 
                    safeRgba(theme.colors.red[3], 0.3)}`,
                  '&:hover': {
                    backgroundColor: theme.colorScheme === 'dark' ? 
                      safeRgba(theme.colors.red[9], 0.25) : 
                      safeRgba(theme.colors.red[0], 0.7),
                    color: theme.colors.red[7],
                    transform: 'translateY(-1px)',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  },
                  transition: 'all 150ms ease',
                })}
                onClick={onLogout}
              >
                <ThemeIcon 
                  color="red" 
                  variant="light" 
                  radius="xl" 
                  size="md" 
                  mr={12}
                  sx={{
                    backgroundColor: theme.colorScheme === 'dark' ? 
                      safeRgba(theme.colors.red[9], 0.2) : 
                      safeRgba(theme.colors.red[0], 0.7),
                    border: `1px solid ${theme.colorScheme === 'dark' ? 
                      safeRgba(theme.colors.red[9], 0.3) : 
                      safeRgba(theme.colors.red[3], 0.4)}`,
                  }}
                >
                  <IconLogin size={16} />
                </ThemeIcon>
                <Text size="sm" fw={600}>Log Out</Text>
              </UnstyledButton>
            </Box>
          </>
        )}
      </AppShell.Navbar>

      <AppShell.Main>
        {/* Single global loading overlay for the entire app */}
        <LoadingOverlay 
          visible={isViewChanging} 
          overlayBlur={2}
          overlayOpacity={0.6}
          overlayColor={theme.colorScheme === 'dark' ? '#1A1B1E' : '#f8f9fa'}
          zIndex={9995} // High z-index but below modals
          loaderProps={{ size: 'lg', color: 'indigo', variant: 'bars' }}
          transitionDuration={300}
        />
        <div className={activeView === 'articles' ? '' : 'content-container'} style={{
          maxWidth: activeView === 'articles' ? '100%' : '800px',
          width: '100%',
          margin: '0 auto',
          paddingTop: '16px',
        }}>
          {renderContent()}
        </div>
      </AppShell.Main>

      <AuthenticationModal 
        opened={modalOpened} 
        onClose={() => {
          setModalOpened(false);
          setPendingAction(null);
          setMenuOpen(false);
        }} 
        onLogin={handleLogin}
        onSwitchToWaitlist={showWaitlistModal}
        zIndex={10000}
      />

      <WaitlistModal
        opened={waitlistModalOpened}
        onClose={() => setWaitlistModalOpened(false)}
        onSwitchToLogin={showAuthModal}
        zIndex={10000}
      />
    </AppShell>
  );
}

export default LandingTabs;
